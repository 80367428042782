import { Download } from "@mui/icons-material";
import { Box, CardMedia, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserProvider";
import api from "../services/api";

import CustomImageHandler from "../components/CustomImageHandler";
export default function InfomacoesPedagogicas() {

    const [data, setData] = useState([{}]);

    const { idUser } = useContext(UserContext);

    useEffect(() => {
        api.get('/ws/informacoes-pedagogicas.php?user_id=' + idUser)
            .then(res => {
                if (res.data.success) {
                    setData(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const style = {
        boxOutside:{
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        typography:{ mt: '1.5em', mb: '0.5em' },
        imageStyle:{
            width: {
                xs:'15rem',
                md:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src:"https://gotheextramile.com.br/img/informacoes-pedagogicas.png",
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }} />
            <Typography textAlign={'center'}>
                Acompanhe aqui suas informações pedagógicas:
            </Typography>

            <Typography variant="h2" sx={style.typography}>
                FOCO
            </Typography>


            {
                data.foco ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        TÍTULO
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        TURMA
                                    </Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    data.foco.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography>
                                                    {row.foco_abordagem}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                <Typography>
                                                    {row.turma}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography textAlign={'center'} p='1em'>
                        Não foram encontrados registros de foco.
                    </Typography>
            }

            <Typography variant="h2" sx={style.typography}>
                NÍVEL
            </Typography>

            {
                data.nivel ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        NÍVEL
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        TURMA
                                    </Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    data.nivel.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography>
                                                    {row.nivel}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                <Typography>
                                                    {row.turma}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography textAlign={'center'} p='1em'>
                        Não foram encontrados registros de nível.
                    </Typography>
            }

            <Typography variant="h2" sx={style.typography}>
                MATERIAL
            </Typography>

            {
                data.material ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        MATERIAL
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        TURMA
                                    </Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    data.material.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography>
                                                    {row.materiais}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                <Typography>
                                                    {row.turma}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography textAlign={'center'} p='1em'>
                        Não foram encontrados registros de materiais.
                    </Typography>
            }

            <Typography variant="h2" sx={style.typography}>
                CERTIFICADO
            </Typography>

            {
                data.certificado ?
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        IDIOMA / PROGRAMA
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        NÍVEL
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    <Typography fontWeight={'bold'} textAlign="left">
                                        AÇÕES
                                    </Typography>
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    data.certificado.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row" align="center">
                                                <Typography>
                                                    {row.idioma}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ width: 160 }} align="center">
                                                <Typography>
                                                    {row.nivel}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton target="_blank" href={row.download} >
                                                    <Download />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography textAlign={'center'} p='1em'>
                        Não foram encontrados registros de certificado.
                    </Typography>
            }
        </Box>
    )
}