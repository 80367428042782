const questions = [
    {
        question: "1. Wer ist das hier auf dem Foto? Das ist __________ kleiner Bruder",
        options: [
            { id: "alemao1_1", value: "mein", label: "mein" },
            { id: "alemao1_2", value: "meinen", label: "meinen" },
            { id: "alemao1_3", value: "meine", label: "meine" }
        ]
    },
    {
        question: "2. Was willst du mal werden? Ich weiss noch nicht genau, Fotograf __________ Arzt.",
        options: [
            { id: "alemao2_1", value: "und", label: "und" },
            { id: "alemao2_2", value: "oder", label: "oder" },
            { id: "alemao2_3", value: "aber", label: "aber" }
        ]
    },
    {
        question: "3. Holst du die Kinder von der Schule __________ ? Nein, ich kann heute nicht.",
        options: [
            { id: "alemao3_1", value: "zu", label: "zu" },
            { id: "alemao3_2", value: "ab", label: "ab" },
            { id: "alemao3_3", value: "auf", label: "auf" }
        ]
    },
    {
        question: "4. Wo ist der Mülleimer? Der steht unter __________ in __________",
        options: [
            { id: "alemao4_1", value: "die Spüle - die Küche", label: "die Spüle - die Küche" },
            { id: "alemao4_2", value: "der Spüle - der Küche", label: "der Spüle - der Küche" },
            { id: "alemao4_3", value: "das Spüle - das Küche", label: "das Spüle - das Küche" }
        ]
    },
    {
        question: "5. Wohin gehst du? __________ Klavierstunde.",
        options: [
            { id: "alemao5_1", value: "in der", label: "in der" },
            { id: "alemao5_2", value: "in das", label: "in das" },
            { id: "alemao5_3", value: "in die", label: "in die" }
        ]
    },
    {
        question: "6. Magst du keine Pommes Frites? __________, aber ich mache Diät.",
        options: [
            { id: "alemao6_1", value: "Doch", label: "Doch" },
            { id: "alemao6_2", value: "Ja", label: "Ja" },
            { id: "alemao6_3", value: "Nein", label: "Nein" }
        ]

    },
    {
        question: "7. Wie geffällt es dir in deinem neuen Haus? Ganz gut, __________ es sehr klein ist.",
        options: [
            { id: "alemao7_1", value: "nachdem", label: "nachdem" },
            { id: "alemao7_2", value: "denn", label: "denn" },
            { id: "alemao7_3", value: "obwohl", label: "obwohl" }
        ]

    },
    {
        question: "8. Hast du __________ Tiere zu Hause?",
        options: [
            { id: "alemao8_1", value: "viel", label: "viel" },
            { id: "alemao8_2", value: "viele", label: "viele" },
            { id: "alemao8_3", value: "voller", label: "voller" }
        ]
    },
    {
        question: "9. __________ du schon im Kino warst?",
        options: [
            { id: "alemao9_1", value: "Hattest", label: "Hattest" },
            { id: "alemao9_2", value: "Warst", label: "Warst" },
            { id: "alemao9_3", value: "Bist", label: "Bist" }
        ]
    },
    {
        question: "10. __________ ihr heute ins Schwimmbad gehen?",
        options: [
            { id: "alemao10_1", value: "Möchten", label: "Möchten" },
            { id: "alemao10_2", value: "Könnt", label: "Könnt" },
            { id: "alemao10_3", value: "Wollt", label: "Wollt" }
        ]
    }
];


const partes = [
    {
        title:'',
        questions:questions
    }
]

export default partes