import { Search } from "@mui/icons-material";
import { Box, CardMedia, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function IdiomPhrasalVerb() {

    const [data, setData] = useState([{
        id: '',
        titulo: '',
        data: '',
    }]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        api.get('/ws/idiom-phrasal-verb.php')
            .then(res => {
                if (res.data.success) {
                    setData(res.data.idiom.slice(0, 100));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const style ={
        boxOutside:{
            p: '2rem 1rem 4rem 1rem',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
            maxWidth: '100vw'
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        textField:{ mt: '1em' },
        table:{ mt: '1.5em' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{src:"https://gotheextramile.com.br/img/idiom-phrasal-verb-of-the-day.png" , imageStyle:style.imageStyle, skeletonStyle:style.skeletonStyle }} />
            <Typography align="center">
                Digite no campo abaixo o termo que você deseja encontrar:
            </Typography>

            <TextField value={query} onChange={e => setQuery(e.target.value)} label="Idiom & Phrasal Verb of the Day..." fullWidth sx={style.textField} />

            <TableContainer component={Paper} sx={style.table}>
                <Table>
                    <TableHead>
                        <TableCell component="th" scope="row" align="center">
                            <strong>DATA</strong>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            <strong>Idiom & Phrasal <br /> Verb of the Day</strong>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                            <strong>AÇÕES</strong>
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {data.filter((data) => (data.titulo).toLowerCase().includes(query.toLowerCase())).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="center">
                                    {row.data}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography textAlign='left'>
                                        {row.titulo}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton component={Link} to={'/IdiomPhrasalVerbDetails/' + row.id}>
                                        <Search color="secondary" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}