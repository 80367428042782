import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import ReactHtmlParser from 'html-react-parser';
import { useTheme } from "@emotion/react";
import { useState } from "react";

const QuestionProgressHandler = ({ handleAnswer, index, question, handleSubmit, userAnswer }) => {
    const theme = useTheme();

    const [radioValue, setRadioValue] = useState(userAnswer);

    const letters = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
    ];

    const verifyAnswer = () => {
        return userAnswer == question.c
    };

    const changeValue = (event, index) => {
        const value = event.target.value
        handleAnswer(value, index)
        setRadioValue(value)
    };

    const styles = {
        divider: {
            m: '2rem 0',
            borderWidth: '1.5px'
        },
        boxRadio: {
            borderRadius: 2,
            border: `2px solid ${verifyAnswer() ? "green" : "red"}`,
            p: '1rem'
        },
    };

    return (
        <Box key={`${index}---question--${index}`}>
            <Typography key={`${index}-descricao`} color={'primary'}>{ReactHtmlParser(`${question.descricao}`)}</Typography>
            <RadioGroup
                key={`${index}-option`}
                onChange={(event) => { changeValue(event, index) }}
                value={radioValue}>
                {question.options.map((opt, i) => {
                    if (opt)
                        return (
                            <FormControlLabel
                                key={`${i}-descr-option-${index}`}
                                disabled={userAnswer}
                                control={<Radio />}
                                label={`${letters[i]} - ${opt}`}
                                value={i + 1} />
                        )
                })}
            </RadioGroup>
            {userAnswer ?
                <Box sx={styles.boxRadio}>
                    <Typography fontWeight={600} fontSize={'1.5rem'} color={verifyAnswer() ? "green" : "red"}>
                        Você {verifyAnswer() ? "acertou!" : "errou!"}
                    </Typography>
                    <Typography>
                        Resposta correta: <strong>{question.options[question.c - 1]}</strong>
                    </Typography>
                </Box>
                :
                <Button
                    size="large" fullWidth variant="contained" type="submit" onClick={() => handleSubmit(index)}>
                    Enviar resposta
                </Button>
            }
            <Divider color={theme.palette.secondary.main} sx={styles.divider} />
        </Box>
    )
}

export default QuestionProgressHandler