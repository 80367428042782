const questions1 = [
    {
        "question": "1. SIX + SIX = _____",
        "options": [
            { "id": "1_1", "value": "Thirteen", "label": "Thirteen" },
            { "id": "1_2", "value": "Twelve", "label": "Twelve" },
            { "id": "1_3", "value": "Twenty", "label": "Twenty" }
        ]
    },
    {
        "question": "2. We _______ happy today!",
        "options": [
            { "id": "2_1", "value": "Am", "label": "Am" },
            { "id": "2_2", "value": "Are", "label": "Are" },
            { "id": "2_3", "value": "Is", "label": "Is" }
        ]
    },
    {
        "question": "3. That´s Marcos.",
        "options": [
            { "id": "3_1", "value": "She´s my brother.", "label": "She´s my brother." },
            { "id": "3_2", "value": "He´s my brother.", "label": "He´s my brother." },
            { "id": "3_3", "value": "I´m fifteen.", "label": "I´m fifteen." }
        ]
    },
    {
        "question": "4. _________ are my brown shoes.",
        "options": [
            { "id": "4_1", "value": "This", "label": "This" },
            { "id": "4_2", "value": "That", "label": "That" },
            { "id": "4_3", "value": "Those", "label": "Those" }
        ]
    },
    {
        "question": "5. What´s the weather like?",
        "options": [
            { "id": "5_1", "value": "I like basketball.", "label": "I like basketball." },
            { "id": "5_2", "value": "I think it´s red.", "label": "I think it´s red." },
            { "id": "5_3", "value": "It´s windy.", "label": "It´s windy." }
        ]
    },
    {
        "question": "6. What are you doing?",
        "options": [
            { "id": "6_1", "value": "She´s a tennis champion.", "label": "She´s a tennis champion." },
            { "id": "6_2", "value": "I´m taking a selfie.", "label": "I´m taking a selfie." },
            { "id": "6_3", "value": "She´s going to her house.", "label": "She´s going to her house." }
        ]
    },
    {
        "question": "7. I speak to everyone.",
        "options": [
            { "id": "7_1", "value": "I´m funny.", "label": "I´m funny." },
            { "id": "7_2", "value": "I´m helpful.", "label": "I´m helpful." },
            { "id": "7_3", "value": "I´m friendly.", "label": "I´m friendly." }
        ]
    },
    {
        "question": "8. Do you like cookies? I usually bake _____ on weekends.",
        "options": [
            { "id": "8_1", "value": "Him", "label": "Him" },
            { "id": "8_2", "value": "Them", "label": "Them" },
            { "id": "8_3", "value": "It", "label": "It" }
        ]
    },
    {
        "question": "9. Jackie and Jason ____ want a parrot.",
        "options": [
            { "id": "9_1", "value": "Don´t", "label": "Don´t" },
            { "id": "9_2", "value": "Doesn´t", "label": "Doesn´t" },
            { "id": "9_3", "value": "Isn´t", "label": "Isn´t" }
        ]
    },
    {
        "question": "10. Which animal lives in a pond?",
        "options": [
            { "id": "10_1", "value": "a giraffe", "label": "a giraffe" },
            { "id": "10_2", "value": "a whale", "label": "a whale" },
            { "id": "10_3", "value": "a fish", "label": "a fish" }
        ]
    }
]

const questions2 = [
    {
        "question": "1. Marta and Livia _____ friends.",
        "options": [
            {"id": "teens1_1_1", "label": "Are", "value": "Are"},
            {"id": "teens1_1_2", "label": "Is", "value": "Is"},
            {"id": "teens1_1_3", "label": "Be", "value": "Be"}
        ]
    },
    {
        "question": "2. Can you dance?",
        "options": [
            {"id": "teens1_2_1", "label": "No, I can't.", "value": "No, I can't."},
            {"id": "teens1_2_2", "label": "Yes, I do.", "value": "Yes, I do."},
            {"id": "teens1_2_3", "label": "No, I don't.", "value": "No, I don't."}
        ]
    },
    {
        "question": "3. My mom exercises with my brother.",
        "options": [
            {"id": "teens1_3_1", "label": "She exercises with me.", "value": "She exercises with me."},
            {"id": "teens1_3_2", "label": "He exercises with it.", "value": "He exercises with it."},
            {"id": "teens1_3_3", "label": "She exercises with him.", "value": "She exercises with him."}
        ]
    },
    {
        "question": "4. There aren't ____ burger bars.",
        "options": [
            {"id": "teens1_4_1", "label": "Some", "value": "Some"},
            {"id": "teens1_4_2", "label": "Any", "value": "Any"},
            {"id": "teens1_4_3", "label": "No", "value": "No"}
        ]
    },
    {
        "question": "5. I ____ wake up without an alarm. My mom needs to help me every morning.",
        "options": [
            {"id": "teens1_5_1", "label": "Always", "value": "Always"},
            {"id": "teens1_5_2", "label": "Never", "value": "Never"},
            {"id": "teens1_5_3", "label": "Often", "value": "Often"}
        ]
    },
    {
        "question": "6. _____ it run fast?",
        "options": [
            {"id": "teens1_6_1", "label": "Do", "value": "Do"},
            {"id": "teens1_6_2", "label": "Have", "value": "Have"},
            {"id": "teens1_6_3", "label": "Does", "value": "Does"}
        ]
    },
    {
        "question": "7. To get the blues means ...",
        "options": [
            {"id": "teens1_7_1", "label": "Cold and tired", "value": "Cold and tired"},
            {"id": "teens1_7_2", "label": "Sad and sick", "value": "Sad and sick"},
            {"id": "teens1_7_3", "label": "Tired and sad", "value": "Tired and sad"}
        ]
    },
    {
        "question": "8. What sport was it?",
        "options": [
            {"id": "teens1_8_1", "label": "There was great.", "value": "There was great."},
            {"id": "teens1_8_2", "label": "It was volleyball.", "value": "It was volleyball."},
            {"id": "teens1_8_3", "label": "There were 4 teams.", "value": "There were 4 teams."}
        ]
    },
    {
        "question": "9. Val _____ a shower this morning.",
        "options": [
            {"id": "teens1_9_1", "label": "Bathed", "value": "Bathed"},
            {"id": "teens1_9_2", "label": "Did", "value": "Did"},
            {"id": "teens1_9_3", "label": "Took", "value": "Took"}
        ]
    },
    {
        "question": "10. She is ______ intelligent than Alice.",
        "options": [
            {"id": "teens1_10_1", "label": "Better", "value": "Better"},
            {"id": "teens1_10_2", "label": "More", "value": "More"},
            {"id": "teens1_10_3", "label": "Most", "value": "Most"}
        ]
    }
]

const questions3 = [
    {
        "question": "1. I really like ______ pictures.",
        "options": [
        {"id": "teens2_1_1", "label": "Playing", "value": "Playing"},
        {"id": "teens2_1_2", "label": "Taking", "value": "Taking"},
        {"id": "teens2_1_3", "label": "Acting", "value": "Acting"}
        ]
    },
    {
        "question": "2. Grandpa always loses his glasses. He is very ______",
        "options": [
        {"id": "teens2_2_1", "label": "Forgetful", "value": "Forgetful"},
        {"id": "teens2_2_2", "label": "Lovable", "value": "Lovable"},
        {"id": "teens2_2_3", "label": "Aggressive", "value": "Aggressive"}
        ]
    },
    {
        "question": "3. Tortoises are fun to play _____.",
        "options": [
            {"id": "teens2_3_1", "label": "For", "value": "For"},
            {"id": "teens2_3_2", "label": "With", "value": "With"},
            {"id": "teens2_3_3", "label": "Of", "value": "Of"}
        ]
    },
    {
        "question": "4. The person _____ I admire the most is my aunt. She helped me when I was a child.",
        "options": [
            {"id": "teens2_4_1", "label": "Which", "value": "Which"},
            {"id": "teens2_4_2", "label": "Where", "value": "Where"},
            {"id": "teens2_4_3", "label": "Who", "value": "Who"}
        ]
    },
    {
        "question": "5. There are some drinks ______ the kitchen table.",
        "options": [
            {"id": "teens2_5_1", "label": "In", "value": "In"},
            {"id": "teens2_5_2", "label": "At", "value": "At"},
            {"id": "teens2_5_3", "label": "On", "value": "On"}
        ]
    },
    {
        "question": "6. He is slow. He does things ______.",
        "options": [
            {"id": "teens2_6_1", "label": "Noisily", "value": "Noisily"},
            {"id": "teens2_6_2", "label": "Slowly", "value": "Slowly"},
            {"id": "teens2_6_3", "label": "Really", "value": "Really"}
        ]
    },
    {
        "question": "7. It´s important for me to _____ fit.",
        "options": [
            {"id": "teens2_7_1", "label": "Keep", "value": "Keep"},
            {"id": "teens2_7_2", "label": "Do", "value": "Do"},
            {"id": "teens2_7_3", "label": "Play", "value": "Play"}
        ]
    },
    {
        "question": "8. I have hay fever. I am _______.",
        "options": [
            {"id": "teens2_8_1", "label": "Shouting", "value": "Shouting"},
            {"id": "teens2_8_2", "label": "Sneezing", "value": "Sneezing"},
            {"id": "teens2_8_3", "label": "Dreaming", "value": "Dreaming"}
        ]
    },
    {
        "question": "9. I bought a _______ of flowers for her birthday.",
        "options": [
            {"id": "teens2_9_1", "label": "Loaf", "value": "Loaf"},
            {"id": "teens2_9_2", "label": "Can", "value": "Can"},
            {"id": "teens2_9_3", "label": "Bunch", "value": "Bunch"}
        ]
    },
    {
        "question": "10. Hello, can I help you?",
        "options": [
            {"id": "teens2_10_1", "label": "I´m looking for a t-shirt.", "value": "I´m looking for a t-shirt."},
            {"id": "teens2_10_2", "label": "I´m going to the music store.", "value": "I´m going to the music store."},
            {"id": "teens2_10_3", "label": "Good idea.", "value": "Good idea."}
        ]
    }
]

const questions4  = [ 
    {
        question: "1. My brother loves his _________. His cell phone is his favorite.",
        options: [    
            { id: "1_1", value: "Books", label: "Books" },     
            { id: "1_2", value: "Gadgets", label: "Gadgets" },     
            { id: "1_3", value: "Clothes", label: "Clothes" }   
        ]
    },
    {
        question: "2. I prefer ______ my own posters.",
        options: [
            { id: "2_1", value: "Making", label: "Making" },
            { id: "2_2", value: "To make", label: "To make" },
            { id: "2_3", value: "Make", label: "Make" }
        ]
    },
    {
        question: "3. ________ the house is small, there´s a porch, so I have the perfect view of the sea.",
        options: [
            { id: "3_1", value: "Although", label: "Although" },
            { id: "3_2", value: "But", label: "But" },
            { id: "3_3", value: "As well as", label: "As well as" }
        ]
    },
    {
        question: "4. __________ pizza with banana?",
        options: [
            { id: "4_1", value: "Do you try", label: "Do you try" },
            { id: "4_2", value: "Did you try", label: "Did you try" },
            { id: "4_3", value: "Have you tried", label: "Have you tried" }
        ]
    },
    {
        question: "5. Wow! The volcano is ________ amazing!",
        options: [
            { id: "5_1", value: "Very", label: "Very" },
            { id: "5_2", value: "Much", label: "Much" },
            { id: "5_3", value: "Absolutely", label: "Absolutely" }
        ]
    },
    {
        question: "6. Let´s _______ a gym in the garage and we can train here.",
        options: [
            { id: "6_1", value: "End up", label: "End up" },
            { id: "6_2", value: "Clean up", label: "Clean up" },
            { id: "6_3", value: "Set up", label: "Set up" }
        ]
    },
    {
        question: "7. What sport will you try if you ______ to summer sports camp?",
        options: [
            { id: "7_1", value: "Go", label: "Go" },
            { id: "7_2", value: "Go to", label: "Go to" },
            { id: "7_3", value: "Went", label: "Went" }
        ]
    },
    {
        question: "8. You ______ visit us in Miami. It´s a lovely place!",
        options: [
            { id: "8_1", value: "Shouldn´t", label: "Shouldn´t" },
            { id: "8_2", value: "Must", label: "Must" },
            { id: "8_3", value: "Don´t have", label: "Don´t have" }
        ]
    },
    {
        question: "9. If I were you, I _______ go to the wedding.",
        options: [
            { id: "9_1", value: "Would", label: "Would" },
            { id: "9_2", value: "Can", label: "Can" },
            { id: "9_3", value: "Will", label: "Will" }
        ]
    },
    {
        question: "10. A criminal is someone who ______ a crime.",
        options: [
            { id: "10_1", value: "Breaks into", label: "Breaks into" },
            { id: "10_2", value: "Robs", label: "Robs" },
            { id: "10_3", value: "Commits", label: "Commits" }
        ]
    }
]

const partes = [
    {
        title:'TEENS STARTER',
        questions: questions1,
    },
    {
        title:'TEENS 1',
        questions: questions2
    },
    {
        title: 'TEENS 2',
        questions: questions3
    },
    {
        title: 'TEENS 3',
        questions: questions4
    }
]

export default partes