import React, { useState } from "react";
import * as yup from 'yup';
import { useFormik } from "formik";
import api from "../services/api";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../context/UserProvider";
import { Box, Button, Card, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";
import AppVersion from "../components/AppVersion";

const validationSchema = yup.object({
  email: yup
    .string('Insira seu email ou user')
    .required('Email é um campo necessário'),
  password: yup
    .string('Insira sua senha')
    .min(8, 'Sua senha deve conter ao menos 8 caracteres')
    .required('Senha é um campo necessário'),
});

export default function Login() {

  const [errorMessage, setErrorMessage] = useState("");

  const [check, setCheck] = useState(false);
  const [userEmail, setEmail] = useState('');
  const [userPassword, setPassword] = useState('');
  const [privacy, setPrivacy] = useState(false);
  const [privMessage, setPrivMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  async function storageUser(data) {
    await localStorage.setItem('localUser', JSON.stringify(data))
  };

  const { signIn } = useContext(UserContext);

  async function loadUser() {
    const response = JSON.parse(await localStorage.getItem('localUser'))
    if (response != null || '') {
      setEmail(response.email);
      setPassword(response.password);
    }
  };

  async function loadCheck() {
    var checkData = localStorage.getItem('appCheck')
    if (checkData != null || '') {
      setCheck(JSON.parse(true));
      loadUser();
    }
  };

  async function storageCheck() {
    await localStorage.setItem('appCheck', JSON.stringify(check))
  };

  useEffect(() => {
    async function refresh() {
      loadCheck();
    };
    refresh();

  }, []);

  const formik = useFormik({
    initialValues: {
      email: userEmail,
      password: userPassword
    },
    validationSchema: validationSchema,
    onSubmit: (e) => { executeLogin(e.email, e.password) }
  });

  async function executeLogin(email, password) {
    if (privacy) {
      setIsLoading(true);
      setPrivMessage('');
      await api.get('ws/login-dev?user=' + email + '&senha=' + password)
        .then((response) => {
          if (response.data.error) {
            setErrorMessage(response.data.msg);
          }
          else {
            if (check) {
              storageUser({ email: email, password: password });
              storageCheck();
            }
            else {
              localStorage.removeItem('appCheck');
            }
            const user = response.data;
            signIn(user);
            setErrorMessage('');
            navigate('/');
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setErrorMessage('Erro na conexão, tente novamente mais tarde!');
          setIsLoading(false);
        });
    } else {
      setPrivMessage('Para acessar a plataforma, você deve concordar com a Política de Privacidade');
    };
  };

  const styles = {
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      p: '2rem',
      m: '1rem'
    },
    image: {
      width: {
        xs: '17rem',
        md: '22rem'
      },
      height: 'auto',
    },
    skeleton: {
      width: '90%',
      height: '6rem'
    }
  };

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <CustomImageHandler props={{ src: 'https://gotheextramile.com.br/img/logo2.png', imageStyle: styles.image, skeletonStyle: styles.skeleton }} />
        <Box sx={{ width: '100%' }} component="form" onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email ou Usuário"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: true }}
            sx={{
              mt: '2rem'
            }}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Senha"
            type="password"
            value={formik.values.password || ''}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{ shrink: true }}
            sx={{
              mt: '1rem'
            }}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            flexDirection: 'row',
            mt: '1rem'
          }}>
            <Checkbox checked={privacy} onChange={() => { setPrivacy(!privacy) }} />
            <Typography>
              Concordo com as <Link to='/PrivacyPolicies' style={{ textDecoration: 'none' }}><Typography component={'span'} color={'primary'} fontWeight={700}>Políticas de Privacidade</Typography></Link>
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            flexDirection: 'row',
            mt: '1rem'
          }}>
            <Checkbox value={check} onChange={e => setCheck(e.target.value)} />
            <Typography>
              Lembrar usuário
            </Typography>
          </Box>
          {
            isLoading ?
              <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' }}>
                <CircularProgress size={'2em'} color="primary" sx={{ m: 'auto' }} />
              </Box>
              :
              <Button color="secondary" variant="contained" fullWidth type="submit" sx={{
                mt: '1rem',
              }}>
                Login
              </Button>
          }
          {
            privMessage &&
            <Typography textAlign='center' color='red' mt='1rem'>
              Para acessar a plataforma, você deve concordar com a Política de Privacidade
            </Typography>
          }{
            errorMessage &&
            <Typography textAlign='center' color='red' mt='1rem'>
              {errorMessage}
            </Typography>

          }
        </Box>
        <Button component={Link} to="/reset-password" variant="text" fullWidth sx={{
          mt: '1rem',
        }}>
          Redefinir senha
        </Button>
        <Typography mt={'1rem'} fontWeight={600} color={'primary'}>
          {<AppVersion />}
        </Typography>
      </Card>
    </Box>
  );
};