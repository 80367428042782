import { Box, Card, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import api from "../services/api";
import { useParams } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
import HTMLReactParser from "html-react-parser";
import { NotificationContext } from "../context/NotificationsProvider";

export default function NotificationDetails() {
    const [data, setData] = useState([]);

    const { id } = useParams();

    const { idUser, tipoConta } = useContext(UserContext);
    const { refreshNotifications } = useContext(NotificationContext);

    useEffect(() => {
        api.post(`ws/notificacoes-detalhes.php?id=${id}&user_type=${tipoConta}&user_id=${idUser}`)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.detalhe);
                    refreshNotifications();
                } else {
                    alert('Houve um erro! Tente novamente ou contate o administrador!');
                }
            })
            .catch(err => {
                console.log(err);
                alert('Houve um erro! Tente novamente ou contate o administrador!');
            })
    }, [id]);

    const style = {
        boxOutside: {
            p: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '90vh',
        },
        card: { m: '1em', p: '1em', width: '100%', maxWidth: '90%' }
    };

    return (
        <Box sx={style.boxOutside}>
            <Typography variant="h1" color={'primary'}>
                {data?.titulo}
            </Typography>
            <Card sx={style.card}>
                <Typography fontStyle={'italic'} color={'grey'}>
                    Enviada em: {data?.data_registro}
                </Typography>
                <Typography variant="h2" mt='1em'>
                    Mensagem:
                </Typography>
                <Typography mt='1em'>
                    {HTMLReactParser(`${data?.texto}`)}
                </Typography>
            </Card>
        </Box>
    )

}