const questions1 = [
  {
    question: "1. Antonio __________ 25 años y __________ en Barcelona",
    options: [
      { id: "elemental1_1", label: "Está – vive", value: "Está – vive" },
      { id: "elemental1_2", label: "Tienes – vives", value: "Tienes – vives" },
      { id: "elemental1_3", label: "Tiene – vive", value: "Tiene – vive" }
    ]
  },
  {
    question: "2. Me __________ mucho salir de copas",
    options: [
      { id: "elemental2_1", label: "Gustan", value: "Gustan" },
      { id: "elemental2_2", label: "Gustar", value: "Gustar" },
      { id: "elemental2_3", label: "Gusta", value: "Gusta" }
    ]
  },
  {
    question: "3. __________ una biblioteca __________ centro de la ciudad",
    options: [
      { id: "elemental3_1", label: "Está – en", value: "Está – en" },
      { id: "elemental3_2", label: "Hay - en el", value: "Hay - en el" },
      { id: "elemental3_3", label: "Tiene – en el", value: "Tiene – en el" }
    ]
  },
  {
    question: "4. Pedro no quiere ir al cine porque dice que no tiene __________ dinero",
    options: [
      { id: "elemental4_1", label: "Muy", value: "Muy" },
      { id: "elemental4_2", label: "Mucho", value: "Mucho" },
      { id: "elemental4_3", label: "Mucha", value: "Mucha" }
    ]
  },
  {
    question: "5. Nosotros __________ estudiando español.",
    options: [
      { id: "elemental5_1", label: "Eres", value: "Eres" },
      { id: "elemental5_2", label: "Estamos", value: "Estamos" },
      { id: "elemental5_3", label: "Somos", value: "Somos" }
    ]
  },
  {
    question: "6. Tengo __________ años",
    options: [
      { id: "elemental6_1", label: "Vienticinco", value: "Vienticinco" },
      { id: "elemental6_2", label: "Veinticinco", value: "Veinticinco" },
      { id: "elemental6_3", label: "Venteicinco", value: "Venteicinco" }
    ]
  },
  {
    question: "7. ¿ A qué __________ dedicas? __________ ingeniero",
    options: [
      { id: "elemental7_1", label: "Te – soy", value: "Te – soy" },
      { id: "elemental7_2", label: "Se – soy", value: "Se – soy" },
      { id: "elemental7_3", label: "Se – estudio", value: "Se – estudio" }
    ]
  },
  {
    question: "8. ¿ __________ ? De Barcelona",
    options: [
      { id: "elemental8_1", label: "¿Dónde vives?", value: "¿Dónde vives?" },
      { id: "elemental8_2", label: "¿De dónde es tú?", value: "¿De dónde es tú?" },
      { id: "elemental8_3", label: "¿De dónde eres?", value: "¿De dónde eres?" }
    ]
  },
  {
    question: "9. Ella __________ temprano",
    options: [
      { id: "elemental9_1", label: "Se levanta", value: "Se levanta" },
      { id: "elemental9_2", label: "Levanta", value: "Levanta" },
      { id: "elemental9_3", label: "Levanto", value: "Levanto" }
    ]
  },
  {
    question: "10. __________ haces? Estudio español",
    options: [
      { id: "elemental10_1", label: "¿Qué", value: "¿Qué" },
      { id: "elemental10_2", label: "¿Cuándo", value: "¿Cuándo" },
      { id: "elemental10_3", label: "¿Quién", value: "¿Quién" }
    ]
  }
]

const questions2 = [
  {
    "question": "__________ era muy tarde, decidió ir a la fiesta",
    "options": [
      { "id": "1a", "label": "Porque", "value": "porque" },
      { "id": "1b", "label": "Para", "value": "para" },
      { "id": "1c", "label": "Aunque", "value": "aunque" }
    ]
  },
  {
    "question": "Si quieres aprobar el examen, __________",
    "options": [
      { "id": "a2", "label": "Estudiar", "value": "estudiar" },
      { "id": "b2", "label": "Estudie", "value": "estudie" },
      { "id": "c2", "label": "Estudia", "value": "estudia" }
    ]
  },
  {
    "question": "Este año __________ a Europa dos veces",
    "options": [
      { "id": "3a", "label": "He viajado", "value": "he viajado" },
      { "id": "3b", "label": "Viajaba", "value": "viajaba" },
      { "id": "3c", "label": "Viajar", "value": "viajar" }
    ]
  },
  {
    "question": "No fui a trabajar __________ tenía mucha fiebre",
    "options": [
      { "id": "4a", "label": "A causa de", "value": "a causa de" },
      { "id": "4b", "label": "Porque", "value": "porque" },
      { "id": "4c", "label": "Y por eso", "value": "y por eso" }
    ]
  },
  {
    "question": "Mañana __________ que ir al aeropuerto a buscar a María.",
    "options": [
      { "id": "5a", "label": "Tenía", "value": "tenía" },
      { "id": "5b", "label": "Tenio", "value": "tenio" },
      { "id": "5c", "label": "Tengo", "value": "tengo" }
    ]
  },
  {
    "question": "Viajamos __________ tren __________ toda España",
    "options": [
      { "id": "6a", "label": "De / por", "value": "de / por" },
      { "id": "6b", "label": "De / para", "value": "de / para" },
      { "id": "6c", "label": "En / por", "value": "en / por" }
    ]
  },
  {
    "question": "Me encanta esta película. A mí __________",
    "options": [
      { "id": "7a", "label": "Sí", "value": "sí" },
      { "id": "7b", "label": "También", "value": "también" },
      { "id": "7c", "label": "Tampoco", "value": "tampoco" }
    ]
  },
  {
    "question": "__________ día conoceré España",
    "options": [
      { "id": "8a", "label": "Algún", "value": "algún" },
      { "id": "8b", "label": "Alguna", "value": "alguna" },
      { "id": "8c", "label": "Alguno", "value": "alguno" }
    ]
  },
  {
    "question": "No quiero comer más. Estaba todo __________ rico pero ya comí __________",
    "options": [
      { "id": "9a", "label": "Mucho / mucho", "value": "mucho / mucho" },
      { "id": "9b", "label": "Muy / mucho", "value": "muy / mucho" },
      { "id": "9c", "label": "Mucho / muy", "value": "mucho / muy" }
    ]
  },
  {
    "question": "__________ muy guapa con ese vestido rojo.",
    "options": [
      { "id": "10a", "label": "Eres", "value": "eres" },
      { "id": "10b", "label": "Estáis", "value": "estáis" },
      { "id": "10c", "label": "Estás", "value": "estás" }
    ]
  }
]

const questions3  = [
  {
    "question": "Si yo __________ tú, le diría todo lo que pienso.",
    "options": [
      { "id": "1a", "label": "Sea", "value": "sea" },
      { "id": "1b", "label": "Fuera", "value": "fuera" },
      { "id": "1c", "label": "Fui", "value": "fui" }
    ]
  },
  {
    "question": "Cuando no quieras olvidar algo, __________",
    "options": [
      { "id": "2a", "label": "Anotarlo", "value": "anotarlo" },
      { "id": "2b", "label": "Anótelo", "value": "anótelo" },
      { "id": "2c", "label": "Anótalo", "value": "anótalo" }
    ]
  },
  {
    "question": "Arturo es un poco antipático, pero aún así __________ cuando no lo veo.",
    "options": [
      { "id": "3a", "label": "No lo soporto", "value": "no lo soporto" },
      { "id": "3b", "label": "Lo echo de menos", "value": "lo echo de menos" },
      { "id": "3c", "label": "No consigo recordarlo", "value": "no consigo recordarlo" }
    ]
  },
  {
    "question": "No te perdonaré __________ me lo pidas mil veces.",
    "options": [
      { "id": "4a", "label": "Así que", "value": "así que" },
      { "id": "4b", "label": "Si", "value": "si" },
      { "id": "4c", "label": "Aunque", "value": "aunque" }
    ]
  },
  {
    "question": "Te __________ el libro, pero mañana sin falta me lo devuelves.",
    "options": [
      { "id": "5a", "label": "Quedo", "value": "quedo" },
      { "id": "5b", "label": "Quito", "value": "quito" },
      { "id": "5c", "label": "Presto", "value": "presto" }
    ]
  },
  {
    "question": "¿ __________ a las 7:00 en el cine?.",
    "options": [
      { "id": "6a", "label": "Quitamos", "value": "quitamos" },
      { "id": "6b", "label": "Quedamos", "value": "quedamos" },
      { "id": "6c", "label": "Citamos", "value": "citamos" }
    ]
  },
  {
    "question": "Mañana __________ salir con mi novio. Vamos __________ al parque y __________ centro comercial.",
    "options": [
      { "id": "7a", "label": "Voy / ir / a el", "value": "voy / ir / a el" },
      { "id": "7b", "label": "Voy / ir / al", "value": "voy / ir / al" },
      { "id": "7c", "label": "Voy a / a ir / al", "value": "voy a / a ir / al" }
    ]
  },
  {
    "question": "Te compraria un regalo si __________ dinero.",
    "options": [
      { "id": "8a", "label": "Tuviera", "value": "tuviera" },
      { "id": "8b", "label": "Tendría", "value": "tendría" },
      { "id": "8c", "label": "Tenemos", "value": "tenemos" }
    ]
  },
  {
    "question": "__________ ver a Marcos en la parada del autobús; estaba un poco triste.",
    "options": [
      { "id": "9a", "label": "Tengo de", "value": "tengo de" },
      { "id": "9b", "label": "Quiero que", "value": "quiero que" },
      { "id": "9c", "label": "Acabo de", "value": "acabo de" }
    ]
  },
  {
    "question": "¿ __________ va a viajar ? No, __________; __________ tenemos que estudiar",
    "options": [
      { "id": "10a", "label": "Alguien / ninguno / todos", "value": "alguien / ninguno / todos" },
      { "id": "10b", "label": "Alguno / ninguno / todos", "value": "alguno / ninguno / todos" },
      { "id": "10c", "label": "Alguien / nadie / todos", "value": "alguien / nadie / todos" }
    ]
  }
]


const partes = [
  {
    title: 'ELEMENTAL',
    questions: questions1
  },
  {
    title:'INTERMEDIO',
    questions:questions2
  },
  {
    title:'AVANZADO',
    questions:questions3
  }
]

export default partes