import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { UserContext } from "../context/UserProvider";
import api from "../services/api";
import { Box, Button, CircularProgress, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import QuestionNivelamentoHandler from "../components/QuestionNivelamentoHandler";
import QuestionProgressHandler from "../components/QuestionProgressHandler";

const ProgressTest = () => {
    const { id } = useParams();
    const { idUser, tipoConta, user } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [progressTest, setProgressTest] = useState({})
    const [answers, setAnswers] = useState([])

    const loadData = () => {
        setIsLoading(true)
        api.get(`/ws/progress-test.php?id_test=${id}&id_user=${user['idalunos']}`)
            .then((res) => {
                if (res.data.success) {
                    setIsLoading(false)
                    setQuestions(res.data.questions)
                    setProgressTest(res.data.progress)
                    setAnswers(res.data.userAnswers)
                } else alert('Houve um erro! Tente novamente ou contate o administrador!');
            })
    }

    useEffect(() => {
        loadData()
    }, [])

    const handleAnswer = (value, index) => {
        setAnswers((previusValue) => {
            previusValue[index] = value
            return previusValue
        })
    }

    const handleSubmit = (index) => {
        var id_question = questions[index]['id']
        const obj_post = {
            id_user: idUser,
            id_question,
            user_type: tipoConta,
            answer: answers[index],
        }
        setIsLoading(true)
        api.post('ws/progress-test-answer.php', obj_post).then((res) => {
            loadData()
        })
    }

    const styles = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        boxInside: { width: '100%', pt: '1.5em' },
        imageStyle: { width: '60%', mb: '1.5rem' },
        skeletonStyle: {
            width: '60%',
            mb: '1.5em',
            height: '11rem'
        },
        boxLoading: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '1rem'
        },
        boxSemAtividade: { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' },
        divider: {
            m: '2rem 0',
            borderWidth: '1.5px'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography fontWeight={600}>
                {isLoading ? '' :
                    <>
                        <Typography variant='h1'>{progressTest.titulo}</Typography>
                        <Typography>{progressTest.descricao}</Typography>
                    </>
                }
            </Typography>
            <Box sx={styles.boxInside}>
                {
                    isLoading ?
                        <Box sx={styles.boxLoading}>
                            <CircularProgress size={'3em'} color="primary" sx={{ m: 'auto' }} />
                        </Box>
                        :
                        questions.length ?
                            questions.map((q, index) => (
                                <QuestionProgressHandler
                                    handleAnswer={handleAnswer}
                                    index={index}
                                    handleSubmit={handleSubmit}
                                    userAnswer={answers[index]}
                                    question={q} />
                            ))
                            : (
                                <Typography>Nenhuma pergunta encontrada</Typography>
                            )
                }
            </Box>
        </Box>
    )
}


export default ProgressTest;