import { ArrowBack } from "@mui/icons-material";
import { Box, Card, IconButton, useTheme } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import OnlyToSignedUsers from "./OnlyToSignedUsers";

export default function BackNavigation() {

    const location = useLocation();

    const theme = useTheme();

    const { pathname } = location;

    const styles = {
        notificationCard: {
            position: 'sticky',
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
            top: 0,
            zIndex: 2000,
        },
        button: { borderRadius: '8px' }
    };

    const navigate = useNavigate();

    const onlyHome = (component) => {

        if (pathname !== '/' && pathname !== '/home') {
            return component;
        };
    };

    return (
        onlyHome(
            <OnlyToSignedUsers component={
                <Box sx={styles.notificationCard}>
                    <IconButton
                        size="large"
                        key={'Navigation'}
                        onClick={() => navigate(-1)}
                        sx={styles.button}>
                        <ArrowBack fontSize='2em' color='white' sx={{ color: 'white' }} />
                    </IconButton>
                </Box>
            } />
        )
    );
};