import { useState } from "react";
import { createContext } from "react";
import { oneSignalPush } from "../components/OneSignal";

export const UserContext = createContext({});

function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [tipoConta, setTipoConta] = useState(null);
    const [idUser, setIdUser] = useState(null);

    function signIn(data) {
        setUser(data.user);
        setTipoConta(data.tipo_conta);
        setIdUser(data.id_usuario);
        oneSignalPush(data.id_usuario, data.tipo_conta);
    };

    function signOut() {
        setUser(null);
        setTipoConta(null);
        setIdUser(null);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                idUser,
                tipoConta,
                signIn,
                signOut,
            }} >
            {children}
        </UserContext.Provider>
    )
};

export default UserProvider;