import { Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

export default function Wes() {
    const styles = {
        boxOutside:{
            p: '2em 1em 5em 1em ',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        typography:{ mt: '1.5em', textAlign: 'center' },
        button:{ mt: "1em", width: '85%' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' },
        imageButton:{
            width: {
                xs:'60%',
                sm:'25rem'
            },
            mb: '1.5em' 
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/wes.png',
                imageStyle:styles.imageStyle,
                skeletonStyle:styles.skeletonStyle
            }}/>
            {/* <CardMedia component="img" image="https://gotheextramile.com.br/img/wes.png" alt="" sx={{ width: '60%' }} /> */}
            <Typography sx={styles.typography}>A série <strong>ENJOY KNOWLEDGE</strong>  comporta várias áreas de conhecimento e entretenimento, onde você encontrará atividades e assuntos Boxersos para complementar o seu aprendizado e enriquecer suas aulas.</Typography>
            <Typography variant="h2" sx={styles.typography}>Curta a série ENJOY KNOWLEDGE.<br />Qual é o seu 'WE' favorito?<br />Clique e aprenda mais</Typography>
            <Button sx={styles.button} component={Link} to="/WeDescription/1">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-cook.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }}/>
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/2">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-green.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/3">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-movies.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/4">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-music.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/5">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-sports.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/6">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-travel.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/7">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-mix.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
            </Button>
            <Button sx={styles.button} component={Link} to="/WeDescription/8">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/we-kids&teens.png',
                    imageStyle:styles.imageButton,
                    skeletonStyle:styles.skeletonStyle
                }} />
                {/* <CardMedia component="img" image="https://gotheextramile.com.br/img/we-kids&teens.png" alt="" /> */}
            </Button>
        </Box>
    )
}

