import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const startApp = () => {
  try {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);

  } catch (e) {
    alert(e)
  }
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);

} else {
  startApp();
};
