import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";

export default function Team() {
    const [data, setData] = useState([]);
    const [image, setImage] = useState('');

    const { id } = useParams();

    function refreshTeam() {
        var team = parseInt(id)
        if (team === 1) {
            setImage('https://gotheextramile.com.br/img/go-on-time.png');
        } else if (team === 2) {
            setImage('https://gotheextramile.com.br/img/go-on-line.png');
        } else if (team === 3) {
            setImage('https://gotheextramile.com.br/img/go-kids-go-teens.png');
        } else {
            setImage('https://gotheextramile.com.br/img/our-team-title.png');
        }
    }

    useEffect(() => {
        refreshTeam();
        api.get('ws/ourteam.php?id=' + id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.team);
                }
            })
            .catch(err => {
                console.log(err);
            })
    });

    const styles = {
        boxOutside: {
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }, mb: '1.5em'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component='img' image={image} alt="" sx={styles.cardMedia} />
            <Grid container spacing={2}>
                {data.map((obj) => {
                    return (
                        <Grid item justifyContent={'center'} textAlign="center" xs={6} id={obj.id}>
                            <CardMedia component='img' image={`https://goaheadschool.com.br/img/our_team/${obj.id}_500x750.jpg`} />
                            <Typography variant="h2" mt='1em'>
                                {obj.nome}
                            </Typography>
                            <Typography fontWeight="bold">
                                {obj.idiomas}
                            </Typography>
                            <Typography color="#6c6c6c" mt='.5em'>
                                {obj.cargo}
                            </Typography>
                            <Button component={Link} to={'/Teacher/' + obj.id}>
                                Ver mais
                            </Button>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )

}