import { Box, Button, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CustomImageHandler from "../components/CustomImageHandler";
import api from "../services/api";
import { UserContext } from "../context/UserProvider";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

export default function YourMiles() {

    const {idUser, tipoConta} = useContext(UserContext);
    
    const [loading, setLoading] = useState(false)
    const [userMiles, setUserMiles] = useState([])

    useEffect(() => {
        setLoading(true)
        api.get(`/ws/your-miles.php?user_type=${tipoConta}&user_id=${idUser}`)
        .then((response)=>{
            if(response.data.success){
                setUserMiles(response.data.data)
            }
        }).catch((err)=>{
            alert("Tente novamente mais tarde")
        }) 
        .finally(()=>{
            setLoading(false)
        })
    }, [])

    const styles = {
        boxOutside:{
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={styles.boxOutside}>
            <CustomImageHandler props={{src:'https://gotheextramile.com.br/img/your-miles.png', imageStyle:styles.imageStyle, skeletonStyle:styles.skeletonStyle}} />
            <Typography variant="h2" textAlign='center'>
                Verifique seu Learning Route (Rota de Aprendizado) das turmas que você participa:
            </Typography>
            { loading && <CircularProgress></CircularProgress> }
            {!loading &&
                <Box
                sx={{p:'1em 1em 1em 1em'}}
                initial={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.25 }} >
                    { userMiles.map((mile, index)=>(
                        <Button
                        key={`${index}--button--learning-route`}
                        component={Link}
                        to={`/LearningRoute/${mile.idTurma}`}
                        variant="outlined"
                        fullWidth
                        endIcon={<ArrowForwardIos/>}>
                            {mile.descricao}
                        </Button>
                    )) }
                </Box>
            }
            
        </Box>
    )
}