import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import api from '../services/api';
import { UserContext } from '../context/UserProvider';
import QuestionProgressHandler from './QuestionProgressHandler';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
    position: 'absolute',
    width: '85vw',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: '8px',
    p: '1rem',
    overflow: 'auto',
    height: '90vh'
};

export default function TapModal() {

    const [open, setOpen] = React.useState(true);
    const [params, setParams] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [userAnswer, setUserAwnser] = React.useState('')
    const [userAnswerData, setUserAwnserData] = React.useState('')

    const handleClose = () => setOpen(false);

    const { tipoConta, idUser } = React.useContext(UserContext);

    const delayedHandleClose = () => {
        setTimeout(handleClose, 3000); // Executa handleClose após 3 segundos (3000 milissegundos)
    };

    const handleSubmit = (e) => {
        const objSend = {
            id_user: idUser,
            user_type: tipoConta,
            id_question: params.id,
            answer: userAnswer
        }
        api.post('ws/anwser-quizz.php', objSend)
            .then(res => {
                if (res.data.success) {
                    setUserAwnserData(userAnswer);
                    delayedHandleClose();
                } else {
                    alert('Houve um erro ao responder essa pergunta!');
                };
            })
            .catch(err => {
                alert('Erro: ' + err);
            });

    };

    const handleAnswer = (value, index) => {
        setUserAwnser(value);
    };

    React.useEffect(() => {
        api.post(`/ws/tap-and-learn.php?user_type=${tipoConta}&user_id=${idUser}`)
            .then((res) => {
                let result = res.data;
                if (result.length !== 0) {
                    setOpen(true);
                    setParams(result);
                    console.log('params', params)
                    setIsLoading(false);
                }
            })
            .catch(error => console.log(error));
    }, []);

    return (
        !isLoading &&
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                zIndex: 2150,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box sx={style}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography variant="h2" color={'primary'}>
                    Tap & Learn
                </Typography>
                <QuestionProgressHandler
                    index={0}
                    question={{
                        descricao: params.descricao,
                        options: params.answers,
                        c: params.c
                    }}
                    userAnswer={userAnswerData}
                    handleAnswer={handleAnswer}
                    handleSubmit={handleSubmit} />
            </Box>
        </Modal>
    );
}