import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

export default function TesteDeNivelamento() {

    const styles = {
        boxOutside:{
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={styles.boxOutside}>
            <CustomImageHandler props={{
                src:"https://gotheextramile.com.br/img/teste-de-nivelamento-title.png",
                imageStyle:styles.imageStyle,
                skeletonStyle:styles.skeletonStyle
            }}/>
            <Typography textAlign={'center'} mb='1em'>
                Os testes de nivelamento da <strong>Go Ahead</strong> foram planejados para que façamos uma primeira avaliação dos seus conhecimentos no idioma. A partir do resultado do teste, o professor poderá conduzir a primeira aula e definir o seu <strong>nível</strong>, assim como os <strong>materiais</strong> a serem utilizados no seu <strong>programa de estudos</strong>. <br /><br />

                A <strong>correção</strong> de seu teste será enviada a você por email dentro do prazo de um dia. <br /><br />

                A pontuação obtida no teste <i>é apenas uma apreciação de seus conhecimentos</i>. O teste é para <i>uso exclusivo no processo de ingresso na Go Ahead</i> e não tem validade oficial, não servindo como base para emissão de quaisquer certificados. <br /><br />

                <strong>Clique no idioma para fazer o teste:</strong>
            </Typography>

            <Grid container spacing={1} sx={{ width: '100%' }}>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoIngles" >Inglês</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoGoTeens" >Go Teens</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoAlemao" >Alemão</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoFrances" >Francês</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoEspanhol" >Espanhol</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/TesteDeNivelamentoPortugues" >Português</Button>
                </Grid>
            </Grid>
        </Box>
    )
}