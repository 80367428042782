import React, { useEffect, useState } from "react";
import homeData from '../data/homeData';
import { useContext } from "react";
import { UserContext } from "../context/UserProvider";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import TapModal from "../components/TapModal";
import HangModal from "../components/HangModal";
import { motion } from "framer-motion";
import HomeItemHandler from "../components/HomeItemHandler";
import CustomImageHandler from "../components/CustomImageHandler";
import api from "../services/api";
import IdiomPhrasalVerbModal from "../components/IdiomPhrasalVerbModal";
import { NotificationContext } from "../context/NotificationsProvider";

export default function Home() {

  const [listData, setListData] = useState([]);

  const theme = useTheme();

  const { tipoConta, user, idUser } = useContext(UserContext);
  const { refreshNotifications } = useContext(NotificationContext);

  const [showHangman, setShowHangman] = useState(false);
  const [idiomPhrasal, setIdiomPhrasal] = useState([])

  const name = (user?.nome.split(' '))[0];

  useEffect(() => {
    refreshNotifications();
    let list = [];
    homeData.map((obj) => {
      let type = obj.tipo;
      type.includes(parseInt(tipoConta)) && list.push(obj);
    });

    setListData(list);

    api.get(`/ws/hang-game-word.php/?id=${idUser}`).then((res) => {
      if (res.data.success) {
        setShowHangman(true)
      }
    });

    api.get(`/ws/get-Idiom.php?user_id=${idUser}&user_type=${tipoConta}`)
      .then((res) => {
        if (res.data.success) {
          setIdiomPhrasal(res.data.data)
        }
      })
      .catch((er) => {
        console.log(er)
      });

  }, []);

  const iconCategory = () => {
    const modalidade = user.formato_aula;

    if (modalidade === "Go Kids") {
      return "sunny";
    } else if (modalidade === "Go Teens") {
      return "go-teens";
    } else {
      return "ms-icon-tiny";
    };
  };

  const styles = {
    container: {
      maxWidth: '1280px',
      height: "100%",
      minHeight: '95vh',
      p: '8rem 1rem 4rem 1rem ',
    },
    header: {
      p: '1rem 0',
      width: '100%',
      top: 0,
      position: 'fixed',
      zIndex: 1900,
      bgcolor: theme.palette.secondary.main
    },
    headerContent: {
      m: 'auto',
      height: '4rem',
      maxWidth: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    image: {
      height: '3rem',
      width: '3rem',
      borderRadius: 1
    },
    skeleton: {
      height: '5rem',
      width: '6rem'
    },
    feedItem: {
      width: "100%",
      height: "100%",
      shadowColor: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: 'white',
      "&:hover": {
        bgcolor: 'white'
      }
    },
  };

  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.headerContent}>
          <CustomImageHandler props={{ src: `https://gotheextramile.com.br/img/${iconCategory()}.png`, imageStyle: styles.image, skeletonStyle: styles.skeleton }} />
          <Box ml={'1rem'}>
            <Typography fontWeight={600} color={'white'}>
              <Box component={'span'} color={theme.palette.primary.main}>{name}</Box>, bem-vindo ao novo Go The Extra Mile, o aplicativo da Go Ahead School!
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid component={motion.ul} container sx={styles.container} spacing={1}>
        {
          listData.map((obj, index) => {
            return <HomeItemHandler params={obj} key={index} />
          })
        }
      </Grid>
      <TapModal />
      {showHangman && <HangModal />}
      {idiomPhrasal.length && <IdiomPhrasalVerbModal content={idiomPhrasal[0]} />}
    </>
  );
};