import { Typography } from "@mui/material"
import QuestionNivelamentoHandler from "./QuestionNivelamentoHandler"


const TesteNivelamentoParte = ({
    title,
    message,
    questions,
    srcFile1,
    typeFile1,
    srcFile2,
    typeFile2,
    AnswerHandler,
})=>{
    const styles = {
        audio:{
            maxWidth:"40%"
        },
    }
    return (
        <>
            <Typography variant="h2">{title}</Typography>
            <Typography>{message}</Typography>
            <audio controls style={styles.audio}>
                <source src={srcFile1} type={typeFile1}/>
                <source src={srcFile2} type={typeFile2}/>
                Your browser does not support the audio element.
            </audio>
            <QuestionNivelamentoHandler
            questions={questions}
            answerHandler={AnswerHandler}/>
        </>
    )
}

export default TesteNivelamentoParte