const questions1 = [
  {
    question: '1. Portuguese is ___________ in Brazil, the largest country in South America.',
    options: [
      { id: '0_0', label: 'speaking', value: 'speaking' },
      { id: '0_1', label: 'spoke', value: 'spoke' },
      { id: '0_2', label: 'speak', value: 'speak' },
      { id: '0_3', label: 'spoken', value: 'spoken' }
    ]
  },
  {
    question: '2. Carol Bridwell has ___________ a senior partner in the law firm of Mason and Woodford.',
    options: [
      { id: '1_0', label: 'elected', value: 'elected' },
      { id: '1_1', label: 'electing', value: 'electing' },
      { id: '1_2', label: 'been elected', value: 'been elected' },
      { id: '1_3', label: 'being elected', value: 'being elected' }
    ]
  },
  {
    question: '3. Matthew ___________ the CPA exam last month.',
    options: [
      { id: '2_0', label: 'will pass', value: 'will pass' },
      { id: '2_1', label: 'passing', value: 'passing' },
      { id: '2_2', label: 'have passed', value: 'have passed' },
      { id: '2_3', label: 'passed', value: 'passed' }
    ]
  },
  {
    question: '4. Right now, day care is not provided at the factory, but a new day care center ___________ .',
    options: [
      { id: '3_0', label: 'is constructing', value: 'is constructing' },
      { id: '3_1', label: 'is being built', value: 'is being built' },
      { id: '3_2', label: 'has constructed', value: 'has constructed' },
      { id: '3_3', label: 'building', value: 'building' }
    ]
  },
  {
    question: '5. We must ___________ faster to keep up with the project schedule.',
    options: [
      { id: '4_0', label: 'to work', value: 'to work' },
      { id: '4_1', label: 'working', value: 'working' },
      { id: '4_2', label: 'work', value: 'work' },
      { id: '4_3', label: 'worked', value: 'worked' }
    ]
  },
  {
    question: '6. The accident victim __________ to the emergency room.',
    options: [
      { id: '5_0', label: 'was taken', value: 'was taken' },
      { id: '5_1', label: 'took', value: 'took' },
      { id: '5_2', label: 'was taking', value: 'was taking' },
      { id: '5_3', label: 'taken', value: 'taken' }
    ]
  },
  {
    question: '7. Boston´s Back Bay is a beautiful ____________ containing many fine old houses.',
    options: [
      { id: '6_0', label: 'neighborhood', value: 'neighborhood' },
      { id: '6_1', label: 'neighbor', value: 'neighbor' },
      { id: '6_2', label: 'neighboring', value: 'neighboring' },
      { id: '6_3', label: 'neighborly', value: 'neighborly' }
    ]
  },
  {
    question: '8. Gomarco Enterprises´ environmental problems were revealed by a team of _____________ journalists from a local television station.',
    options: [
      { id: '7_0', label: 'investigation', value: 'investigation' },
      { id: '7_1', label: 'investigate', value: 'investigate' },
      { id: '7_2', label: 'investigators', value: 'investigators' },
      { id: '7_3', label: 'investigative', value: 'investigative' }
    ]
  },
  {
    question: '9. The personnel manager read all the ___________ herself.',
    options: [
      { id: '8_0', label: 'applications', value: 'applications' },
      { id: '8_1', label: 'applies', value: 'applies' },
      { id: '8_2', label: 'applicants', value: 'applicants' },
      { id: '8_3', label: 'applicators', value: 'applicators' }
    ]
  },
  {
    question: '10. If a product is ___________ packaged, it will get consumer´s attention.',
    options: [
      { id: '9_0', label: 'attraction', value: 'attraction' },
      { id: '9_1', label: 'attractively', value: 'attractively' },
      { id: '9_2', label: 'attract', value: 'attract' },
      { id: '9_3', label: 'attractive', value: 'attractive' }
    ]
  },
  {
    question: '11. There has been a lot of ___________ about this new scheme.',
    options: [
      { id: '10_0', label: 'exciting', value: 'exciting' },
      { id: '10_1', label: 'excite', value: 'excite' },
      { id: '10_2', label: 'excitable', value: 'excitable' },
      { id: '10_3', label: 'excitement', value: 'excitement' }
    ]
  },
  {
    question: '12. The recent changes in the economy have led to greater __________ in our company´s products.',
    options: [
      { id: '11_0', label: 'interest', value: 'interest' },
      { id: '11_1', label: 'interesting', value: 'interesting' },
      { id: '11_2', label: 'interested', value: 'interested' },
      { id: '11_3', label: 'interestingly', value: 'interestingly' }
    ]
  },
  {
    question: '13. The director would like to express his appreciation for the __________ efforts made by all members of the staff during this time of crisis.',
    options: [
      { id: '12_0', label: 'admired', value: 'admired' },
      { id: '12_1', label: 'admirably', value: 'admirably' },
      { id: '12_2', label: 'admire', value: 'admire' },
      { id: '12_3', label: 'admirable', value: 'admirable' }
    ]
  },
  {
    question: '14. Fill out this form __________ before turning it in to your supervisor.',
    options: [
      { id: '13_0', label: 'completed', value: 'completed' },
      { id: '13_1', label: 'completely', value: 'completely' },
      { id: '13_2', label: 'completion', value: 'completion' },
      { id: '13_3', label: 'complete', value: 'complete' }
    ]
  },
  {
    question: '15. While some people enjoy receiving calls from telemarketers, other people find such calls ___________ .',
    options: [
      { id: '14_0', label: 'annoys', value: 'annoys' },
      { id: '14_1', label: 'annoyingly', value: 'annoyingly' },
      { id: '14_2', label: 'annoyed', value: 'annoyed' },
      { id: '14_3', label: 'annoying', value: 'annoying' }
    ]
  },
  {
    question: '16. Congress chartered the first Bank of the United States in 1791 to engage in general commercial banking and ___________ as a fiscal agent of the federal government.',
    options: [
      { id: '15_0', label: 'to act', value: 'to act' },
      { id: '15_1', label: 'acting', value: 'acting' },
      { id: '15_2', label: 'that has acted', value: 'that has acted' },
      { id: '15_3', label: 'having acted', value: 'having acted' }
    ]
  },
  {
    question: '17. He really deserved the award because he performed ___________ was expected of him.',
    options: [
      { id: '16_0', label: 'much better from', value: 'much better from' },
      { id: '16_1', label: 'more better than', value: 'more better than' },
      { id: '16_2', label: 'much better as', value: 'much better as' },
      { id: '16_3', label: 'much better than', value: 'much better than' }
    ]
  },
  {
    question: '18. Do you think that the labor bill will be passed?"\n"Oh, yes. It\'s ___________ that it will.',
    options: [
      { id: '17_0', label: 'certainty', value: 'certainty' },
      { id: '17_1', label: 'certainly', value: 'certainly' },
      { id: '17_2', label: 'certain', value: 'certain' },
      { id: '17_3', label: 'certained', value: 'certained' }
    ]
  },
  {
    question: '19. It would be difficult for a man of his political affiliation, ___________, to become a senator from the south.',
    options: [
      { id: '18_0', label: 'though charming and capable is he', value: 'though charming and capable is he' },
      { id: '18_1', label: 'even with charm and so capable', value: 'even with charm and so capable' },
      { id: '18_2', label: 'charming and having capability', value: 'charming and having capability' },
      { id: '18_3', label: 'however charming and capable', value: 'however charming and capable' }
    ]
  },
  {
    question: '20. A politician can make a legislative proposal more ___________ by giving specific examples of what its effect will be.',
    options: [
      { id: '19_0', label: 'to understand', value: 'to understand' },
      { id: '19_1', label: 'understandably', value: 'understandably' },
      { id: '19_2', label: 'understandable', value: 'understandable' },
      { id: '19_3', label: 'when understood', value: 'when understood' }
    ]
  },
  {
    question: '21. Before every presidential election in the United States, the statisticians try to guess the proportion of the population that ___________ for each candidate.',
    options: [
      { id: '20_0', label: 'are voted', value: 'are voted' },
      { id: '20_1', label: 'voting', value: 'voting' },
      { id: '20_2', label: 'to be voted', value: 'to be voted' },
      { id: '20_3', label: 'will vote', value: 'will vote' }
    ]
  },
  {
    question: '22. ___________ left before the deadline, it doesn\'t seem likely that John will accomplish the job.',
    options: [
      { id: '21_0', label: 'Although such a short time', value: 'Although such a short time' },
      { id: '21_1', label: 'It is such a short', value: 'It is such a short' },
      { id: '21_2', label: 'With so short time', value: 'With so short time' },
      { id: '21_3', label: 'With such a short time', value: 'With such a short time' }
    ]
  },
  {
    question: '23. Not only ___________ in the field of psychology but animal behavior is examined as well.',
    options: [
      { id: '22_0', label: 'human behavior is studied', value: 'human behavior is studied' },
      { id: '22_1', label: 'is human behavior studied', value: 'is human behavior studied' },
      { id: '22_2', label: 'is studied human behavior', value: 'is studied human behavior' },
      { id: '22_3', label: 'human behavior', value: 'human behavior' }
    ]
  },
  {
    question: '24. ___________ instruments such as personal checks may ordinarily be transferred to other people by endorsement.',
    options: [
      { id: '23_0', label: 'Negotiating', value: 'Negotiating' },
      { id: '23_1', label: 'Negotiable', value: 'Negotiable' },
      { id: '23_2', label: 'Negotiably', value: 'Negotiably' },
      { id: '23_3', label: 'Negotiation', value: 'Negotiation' }
    ]
  },
  {
    question: '25. The store ___________.',
    options: [
      { id: '24_0', label: 'that had the recently big sale went bankrupt', value: 'that had the recently big sale went bankrupt' },
      { id: '24_1', label: 'that recently had the big sale went bankrupt', value: 'that recently had the big sale went bankrupt' },
      { id: '24_2', label: 'that had the big recently sale went bankrupt', value: 'that had the big recently sale went bankrupt' },
      { id: '24_3', label: 'that had the big sale went recently bankrupt', value: 'that had the big sale went recently bankrupt' }
    ]
  },
   {
    question: '26. The store ___________.',
    options: [
      { id: '25_0', label: 'that had the recently big sale went bankrupt', value: 'that had the recently big sale went bankrupt' },
      { id: '25_1', label: 'that recently had the big sale went bankrupt', value: 'that recently had the big sale went bankrupt' },
      { id: '25_2', label: 'that had the big recently sale went bankrupt', value: 'that had the big recently sale went bankrupt' },
      { id: '25_3', label: 'that had the big sale went recently bankrupt', value: 'that had the big sale went recently bankrupt' }
    ]
  },
  {
    question: '27. Farmers grow popcorn in much the same way ___________ field corn, except that the rows are planted closer together.',
    options: [
      { id: '26_0', label: 'that they grow', value: 'that they grow' },
      { id: '26_1', label: 'that grow', value: 'that grow' },
      { id: '26_2', label: 'they grow it', value: 'they grow it' },
      { id: '26_3', label: 'do they grow', value: 'do they grow' }
    ]
  },
  {
    question: '28. A good exercise program helps teach people to avoid the habits that might shorten ___________ lives.',
    options: [
      { id: '27_0', label: 'the', value: 'the' },
      { id: '27_1', label: 'them', value: 'them' },
      { id: '27_2', label: 'then', value: 'then' },
      { id: '27_3', label: 'their', value: 'their' }
    ]
  },
  {
    question: '29. Texas is the only state whose constitution permits it ___________ into smaller states.',
    options: [
      { id: '28_0', label: 'divides', value: 'divides' },
      { id: '28_1', label: 'divide', value: 'divide' },
      { id: '28_2', label: 'be dividing', value: 'be dividing' },
      { id: '28_3', label: 'to be divided', value: 'to be divided' }
    ]
  },
  {
    question: '30. Until the ninth century, written words were not actually separated, ___________ in some literary writing, dots or points were used to indicate divisions.',
    options: [
      { id: '29_0', label: 'in spite of', value: 'in spite of' },
      { id: '29_1', label: 'contrary', value: 'contrary' },
      { id: '29_2', label: 'contrast to', value: 'contrast to' },
      { id: '29_3', label: 'but', value: 'but' }
    ]
  },
  {
    question: '31. One or the other of the secretaries ___________ attend the meeting. But both of them are reluctant to do so.',
    options: [
      { id: '30_0', label: 'have to', value: 'have to' },
      { id: '30_1', label: 'has to', value: 'has to' },
      { id: '30_2', label: 'had to', value: 'had to' },
      { id: '30_3', label: 'have had to', value: 'have had to' }
    ]
  }
];

const questions2 = [
  {
    question: '32. One of the essential (A) features of the modern skyscraper (B) is being (C) the elevator (D).',
    options: [
      { id: '31_0', label: 'A', value: 'A' },
      { id: '31_1', label: 'B', value: 'B' },
      { id: '31_2', label: 'C', value: 'C' },
      { id: '31_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '33. Slightly (A) over half (B) of the population (C) of El Paso, Texas, says (D) both English and Spanish.',
    options: [
      { id: '32_0', label: 'A', value: 'A' },
      { id: '32_1', label: 'B', value: 'B' },
      { id: '32_2', label: 'C', value: 'C' },
      { id: '32_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '34. Approximately (A) one-third of all persons (B) involved in adult education programs in 1970 were enrolled (C) in occupational education course (D).',
    options: [
      { id: '33_0', label: 'A', value: 'A' },
      { id: '33_1', label: 'B', value: 'B' },
      { id: '33_2', label: 'C', value: 'C' },
      { id: '33_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '35. American manufacturers depend on (A) ocean shipping (B) for most of trade (C) with other countries (D).',
    options: [
      { id: '34_0', label: 'A', value: 'A' },
      { id: '34_1', label: 'B', value: 'B' },
      { id: '34_2', label: 'C', value: 'C' },
      { id: '34_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '36. The doctor\'s records must be kept thorough (A) and neatly (B), so as to (C) insure good (D) book-keeping.',
    options: [
      { id: '35_0', label: 'A', value: 'A' },
      { id: '35_1', label: 'B', value: 'B' },
      { id: '35_2', label: 'C', value: 'C' },
      { id: '35_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '37. As (A) every other (B) nation, the United States used to define (C) its (D) unit of currency, the dollars, in terms of the gold standard.',
    options: [
      { id: '36_0', label: 'A', value: 'A' },
      { id: '36_1', label: 'B', value: 'B' },
      { id: '36_2', label: 'C', value: 'C' },
      { id: '36_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '38. The prime minister\'s conviction for improper campaign practices is likely to result in increasing pressure that she resigns.',
    options: [
      { id: '37_0', label: 'A', value: 'A' },
      { id: '37_1', label: 'B', value: 'B' },
      { id: '37_2', label: 'C', value: 'C' },
      { id: '37_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '39. In the United States among 60 percent of the space on the pages of newspapers is reserved for advertising.',
    options: [
      { id: '38_0', label: 'A', value: 'A' },
      { id: '38_1', label: 'B', value: 'B' },
      { id: '38_2', label: 'C', value: 'C' },
      { id: '38_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '40. Over forty years ago, Helen Hall\'s outstanding (A) contributions as a settlement organizer catch (B) the attention of president Franklin Roosevelt, who appointed her to his (C) advisory committee (D) on economic.',
    options: [
      { id: '39_0', label: 'A', value: 'A' },
      { id: '39_1', label: 'B', value: 'B' },
      { id: '39_2', label: 'C', value: 'C' },
      { id: '39_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '41. The office manager insists that his staff use (A) all of (B) its vacation time (C), but he hardly ever takes a (D) vacation himself.',
    options: [
      { id: '40_0', label: 'A', value: 'A' },
      { id: '40_1', label: 'B', value: 'B' },
      { id: '40_2', label: 'C', value: 'C' },
      { id: '40_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '42. To become (A) a skilled photograph (B), a person should have (C) both manual dexterity and a good eye for detail (D).',
    options: [
      { id: '41_0', label: 'A', value: 'A' },
      { id: '41_1', label: 'B', value: 'B' },
      { id: '41_2', label: 'C', value: 'C' },
      { id: '41_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '43. No other (A) beverage comes even (B) close to rivaling coffee as the more widely (C) drunk refreshment in the world (D).',
    options: [
      { id: '42_0', label: 'A', value: 'A' },
      { id: '42_1', label: 'B', value: 'B' },
      { id: '42_2', label: 'C', value: 'C' },
      { id: '42_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '44. Carrie Chapman Catt organized (A) the League of women voters after (B) successfully campaign (C) for the constitutional amendment that gave (D) women the right to vote.',
    options: [
      { id: '43_0', label: 'A', value: 'A' },
      { id: '43_1', label: 'B', value: 'B' },
      { id: '43_2', label: 'C', value: 'C' },
      { id: '43_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '45. It has been estimated (A) that only 21 percent of the world\'s land surface (B) are (C) cultivatable (D) and that only 7.6 percent is actually under cultivation.',
    options: [
      { id: '44_0', label: 'A', value: 'A' },
      { id: '44_1', label: 'B', value: 'B' },
      { id: '44_2', label: 'C', value: 'C' },
      { id: '44_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '46. The firemen were unable to determine (A) exactly what (B) caused the fire, when (C) they said that they would continue (D) the investigation.',
    options: [
      { id: '45_0', label: 'A', value: 'A' },
      { id: '45_1', label: 'B', value: 'B' },
      { id: '45_2', label: 'C', value: 'C' },
      { id: '45_3', label: 'D', value: 'D' }
    ]
  },
   {
    question: '47. Because of (A) its (B) strong record during earlier recessions, the mutual fund has and will (C) continue to be attractive (D) to small investors.',
    options: [
      { id: '46_0', label: 'A', value: 'A' },
      { id: '46_1', label: 'B', value: 'B' },
      { id: '46_2', label: 'C', value: 'C' },
      { id: '46_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '48. Both China (A), Russia, and the United States are (B) aware (C) of the role they (D) play in world affairs.',
    options: [
      { id: '47_0', label: 'A', value: 'A' },
      { id: '47_1', label: 'B', value: 'B' },
      { id: '47_2', label: 'C', value: 'C' },
      { id: '47_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '49. We had been in (A) the city not more than (B) two days until (C) we found that we needed (D) a guide.',
    options: [
      { id: '48_0', label: 'A', value: 'A' },
      { id: '48_1', label: 'B', value: 'B' },
      { id: '48_2', label: 'C', value: 'C' },
      { id: '48_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '50. Not until (A) twenty years ago (B) was there much (C) need for personal computer (D).',
    options: [
      { id: '49_0', label: 'A', value: 'A' },
      { id: '49_1', label: 'B', value: 'B' },
      { id: '49_2', label: 'C', value: 'C' },
      { id: '49_3', label: 'D', value: 'D' }
    ]
  },
  {
    question: '51. The railway network expanded rapidly until the railroad map of the United States looked like a spider\'s web, with the steel filaments connect all important sources of raw materials, their places of manufacture, and their centers of contribution.',
    options: [
      { id: '50_0', label: 'A', value: 'A' },
      { id: '50_1', label: 'B', value: 'B' },
      { id: '50_2', label: 'C', value: 'C' },
      { id: '50_3', label: 'D', value: 'D' }
    ]
  }
];

const TOEIC = [
    {
        title:'Answer the following questions from TOEIC tests:',
        questions:questions1
    },
    {
        title:'Find the incorrect part of the sentence (A, B, C or D):',
        questions:questions2
    }
]

export default TOEIC;