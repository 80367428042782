import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ProficiencyTests = () => {
    const styles = {
        boxOutside: {
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            height: '90vh',
        },
        grid: { width: '100%' },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }, mb: '1.5em'
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CardMedia
                component={'img'}
                image="https://gotheextramile.com.br/img/proficiency-tests.png"
                alt="" sx={styles.cardMedia} />
            <Typography textAlign={'center'} mb='1em'>
                <b>Clique aqui para testar seus conhecimentos nos três testes (TOEIC, TOEFL e IELTS).</b>
            </Typography>

            <Grid container spacing={1} sx={styles.grid} justifyContent="center">
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/ProficiencyQuizz/toeic" >TOEIC</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/ProficiencyQuizz/toefl" >TOEFL</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/ProficiencyQuizz/ielts" >IELTS</Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProficiencyTests;