import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserProvider";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function Balloons() {

    const [data, setData] = useState([{}]);

    const { idUser } = useContext(UserContext);

    useEffect(() => {
        api.get('ws/balloon.php?user_type=2&user_id=' + idUser)
            .then(res => {
                if (res.data.success) {
                    setData(res.data);
                } else {
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const style = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },

        cardMedia: { width: '60%', mb: '1.5em' },

        table: { mt: '1.5em' },

        typography: { mt: '1.5em' },
        imageStyle: {
            width: {
                xs: '10rem',
                sm: '16rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src: 'https://gotheextramile.com.br/img/balloon-bank.png',
                imageStyle: style.imageStyle,
                skeletonStyle: style.skeletonStyle
            }} />
            <Typography variant='h2'>
                Você possui {data.balloons || 0} Balloon(s)
            </Typography>
            <Typography mt='1em'>
                Confira abaixo seu extrato de Balloons. Você poderá trocar seus Balloons por produtos/serviços especiais da Go Ahead!
            </Typography>


            {data.registros ?
                <TableContainer component={Paper} sx={style.table}>
                    <Table>
                        <TableHead>
                            <TableCell component="th" scope="row" align="center">
                                <Typography fontWeight='Bold'>
                                    DATA
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <Typography fontWeight='Bold'>
                                    TIPO DE TRANSAÇÃO
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <Typography fontWeight='Bold'>
                                    BALLOONS
                                </Typography>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {data.registros.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        <Typography>
                                            {row.data}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {row.tipo_transacao}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {row.balloons}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography sx={style.typography} variant="h2" >
                    Você não possui balloons registrados.
                </Typography>
            }

            <Typography mt='1.5em'>
                A cada 50 balloons você poderá resgatar seu prêmio. Entre em contato com a escola!
            </Typography>
        </Box>
    )
}