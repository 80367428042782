import { Box, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import { UserContext } from "../context/UserProvider";
import { QuestionHandler } from "../components/QuestionHandler";
import WOW from 'wowjs';
import './TapAndLearn.css';

export default function Quiz() {

    const { idUser, tipoConta } = useContext(UserContext);
    const { id } = useParams();

    const [title, setTitle] = useState("");
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const refreshData = () => {
        api.post(`/ws/quiz.php`, { quiz_id: id, user_type: tipoConta, user_id: idUser })
            .then(res => {
                if (res.data.success) {
                    const { titulo, questions } = res.data.data;
                    setQuestions(questions);
                    setTitle(titulo);
                    setIsLoading(false);
                } else {
                    alert('Houve um erro na conexão, tente novamente mais tarde!');
                    setIsLoading(false);
                };
            })
            .catch(err => {
                console.log(err);
                alert('Houve um erro na conexão, tente novamente mais tarde!');
                setIsLoading(false);
            });
    };

    useEffect(() => {
        refreshData();
        new WOW.WOW({
            live: false
        }).init();
    }, []);

    const styles = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        boxInside: {
            pt: '1.5em',
            maxWidth: '85%'
        },
        imageStyle: { width: '60%', mb: '1.5rem' },
        skeletonStyle: {
            width: '60%',
            mb: '1.5em',
            height: '11rem'
        },
        boxLoading: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '1rem'
        },
        boxSemAtividade: { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' },
    }

    return (
        <Box sx={styles.boxOutside}>
            <div id="tap-and-learn-logo">
                <img src="https://gotheextramile.com.br/img/tap-and-learn/tap.png" alt="" class="tap wow bounceIn" data-wow-delay="0.3s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/and.png" alt="" class="and wow bounceIn" data-wow-delay="0.4s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/learn.png" alt="" class="learn wow bounceIn" data-wow-delay="0.5s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/splash.png" alt="" class="splash wow tada" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/mao.png" alt="" class="mao wow bounceIn" data-wow-delay="0.15s" />
            </div>
            <Typography fontWeight={600} mt={'2rem'} fontSize={'1.5rem'}>
                {title}
            </Typography>
            <Box sx={styles.boxInside}>
                {
                    isLoading ?
                        <Box sx={styles.boxLoading}>
                            <CircularProgress size={'3em'} color="primary" sx={{ m: 'auto' }} />
                        </Box>
                        :
                        questions.length > 0 ?
                            questions.map(obj => { return <QuestionHandler params={{ refreshData, obj }} /> })
                            :
                            <Box sx={styles.boxSemAtividade}>
                                <Typography>
                                    Ops, você ainda não tem atividades!
                                </Typography>
                            </Box>
                }
            </Box>
        </Box>
    )

}