import { Box, Button, CardMedia, FormControl, Typography, stepButtonClasses } from "@mui/material";
import {  useState } from "react";

import CustomImageHandler from "../../components/CustomImageHandler";
import QuestionNivelamentoHandler from "../../components/QuestionNivelamentoHandler";

import partes from '../../data/testeNivelamentoEspanhol'
import useTesteNivelamento from "../../hooks/useSendTesteNivelamento";

const TesteDeNivelamentoEspanhol = ()=>{
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento()
    const [answers, setAnswers] = useState([
        [0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0],
    ]);

    const handleSubmit = (event) => {
        event.preventDefault();
        sendTest(partes, answers, 'Espanhol')
    };

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        },
        formSent:{
            color: 'green',
        },
        skeleton:{
            width: '100%',
            height: '30vh'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h2">Espanhol</Typography>
            <Typography> Teste de nivelamento </Typography>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/espanhol-title.png',
                skeletonStyle: styles.skeleton
            }} />
            {/* <CardMedia
            component="img"
            image="https://gotheextramile.com.br/img/espanhol-title.png"
            alt=""
            sx={styles.cardMedia} /> */}
            <FormControl>
                {partes.map(({title, questions}, index)=>{
                    return (<>
                        <Typography key={`${index}-${title}`}>{title}</Typography>
                        <QuestionNivelamentoHandler
                        questions={questions}
                        key={`${title}-${index}`}
                        answerHandler={handleAnswer(index, setAnswers)}/>
                    </>)
                })}
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                variant="contained"
                size="large"
                type='submit'
                disabled={formSent}
                onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}

export default TesteDeNivelamentoEspanhol