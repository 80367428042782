import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function PlanosDeCursoIngles() {
    const styles = {
        boxOutside:{
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5rem'
        },
        divider:{ m: '1.5em 0' },
        button:{ width: '75%', mt: '1em', textTransform: 'uppercase' },
        accordion:{
            width:'100%',
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component={'img'} image="https://gotheextramile.com.br/img/planos-de-curso-title.png" alt="" sx={styles.cardMedia} />
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}> General/Standard English</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography mb='1em'>Além do material padrão, utilizamos, também, como alternativa, edições do <i>New American Inside Out</i> e <i>Topnotch</i></Typography>
                    <Typography fontWeight={'bold'}>
                        Basic <br />
                        Pre-Intermediate <br />
                        Intermediate <br />
                        Upper- Intermediate
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">BASIC - GENERAL ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  American English File 1 (Livro + CD) <br /> Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Apresentar-se e apresentar outras pessoas <br />
                        • Cumprimentar e despedir-se  <br />
                        • Perguntar como alguém está  <br />
                        • Falar sobre os dias da semana   <br />
                        • Utilizar números de 1 a 100 <br />
                        • Solicitar e fornecer endereço e números de telefone <br />
                        • Perguntar e responder de onde é utilizando países e nacionalidades <br />
                        • Soletrar palavras <br />
                        • Fazer check-in em um hotel <br />
                        • Identificar e nomear objetos  <br />
                        • Descrever cores  <br />
                        • Descrever pessoas <br />
                        • Dar instruções <br />
                        • Dar sugestões <br />
                        • Falar sobre atividades do dia-a-dia e sua frequência <br />
                        • Falar sobre profissões <br />
                        • Falar e perguntar sobre preferências <br />
                        • Dizer as horas <br />
                        • Pedir comida em um restaurante ou lanchonete <br />
                        • Falar sobre a família <br />
                        • Perguntar e responder a quem pertencem objetos <br />
                        • Expressar posse usando o ‘s <br />
                        • Pedir licença e desculpar-se <br />
                        • Falar sobre habilidades  <br />
                        • Descrever lugares e falar sobre tempo e clima <br />
                        • Falar e perguntar sobre o que está acontecendo ao seu redor  <br />
                        • Falar sobre roupas  <br />
                        • Dizer preços <br />
                        • Solicitar e compreender direções <br />
                        • Dar direções <br />
                        • Pedir algo educadamente <br />
                        • Falar e fazer perguntas sobre o passado <br />
                        • Fazer comparações <br />
                        • Falar e perguntar sobre o futuro <br />
                        • Pedir um taxi <br />
                        • Comprar passagens para o transporte público <br />
                        • Expressar opiniões <br />
                        • Falar sobre experiências de vida <br />
                        <br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong> <br />
                        <br />

                        • Verbo to be  <br />
                        • Pronomes pessoais (caso reto e oblíquos) <br />
                        • Adjetivos possessivos <br />
                        • Artigos definidos e indefinidos  <br />
                        • Plural <br />
                        • Pronomes demonstrativos <br />
                        • Adjetivos  <br />
                        • Imperativo e let’s <br />
                        • Presente Simples <br />
                        • Uso de WHOSE e de ‘S indicando posse <br />
                        • Preposições de tempo e lugar <br />
                        • Advérbios e expressões de frequência  <br />
                        • Uso do modal CAN para expressar habilidade <br />
                        • Presente Contínuo  <br />
                        • Uso de LIKE +ING <br />
                        • Passado Simples – verbos regulares e irregulares  <br />
                        • Passado do verbo to be  <br />
                        • There is / there are   <br />
                        • There was / there were <br />
                        • Substantivos contáveis e incontáveis  <br />
                        • Uso de SOME e ANY  <br />
                        • Uso de HOW MUCH e HOW MANY, A LOT OF, etc <br />
                        • Comparativo e superlativo <br />
                        • Uso de TO BE GOING TO para planos e previsões <br />
                        • Adjetivos com TOO  <br />
                        • Advérbios <br />
                        • Estrutura de verb + to + verb <br />
                        • Presente Perfeito  <br />
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">PRE-INTERMEDIATE - GENERAL ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  American English File 2 (Livro +CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre problemas <br />
                        • Oferecer-se para fazer algo <br />
                        • Cumprimentar um amigo que não vê há muito tempo <br />
                        • Fazer e responder perguntas sobre trabalho, estudos, família e lazer <br />
                        • Descrever a personalidade e a aparência de uma pessoa próxima <br />
                        • Descrever uma imagem, o que está acontecendo e o que as pessoas estão vestindo <br />
                        • Falar sobre férias recentes <br />
                        • Descrever uma foto favorita e explicar o que estava acontecendo no momento <br />
                        • Pedir comida em um restaurante <br />
                        • Explicar o problema caso haja um erro com a comida <br />
                        • Perguntar a alguém sobre o que vai fazer no dia <br />
                        • Falar sobre planos e previsões <br />
                        • Parafrasear palavras <br />
                        • Falar sobre coisas que já fez e que ainda não fez no dia <br />
                        • Perguntar e responder sobre experiências utilizando EVER <br />
                        • Sugerir o que fazer <br />
                        • Devolver à loja algo que se comprou <br />
                        • Combinar um horário para encontrar alguém <br />
                        • Comparar membros da família <br />
                        • Descrever a cidade usando superlativos <br />
                        • Falar sobre hábitos alimentares <br />
                        • Fazer previsões usando WILL / WON’T <br />
                        • Fazer uma promessa, oferecer algo ou tomar uma decisão usando WILL WON’T <br />
                        • Descrever sintomas quando está doente <br />
                        • Comprar remédios <br />
                        • Falar sobre posse usando HAVE GOT <br />
                        • Falar sobre algo que gostaria de aprender a fazer <br />
                        • Falar sobre alguém que seria interessante conhecer <br />
                        • Falar sobre o que gosta e o que detesta fazer <br />
                        • Falar sobre coisas que gosta, ama e detesta fazer <br />
                        • Falar sobre regras da escola <br />
                        • Dar conselhos sobre como aprender inglês <br />
                        • Dar e entender direções na rua <br />
                        • Dar e entender direções para utilizar transporte público <br />
                        • Pedir desculpas <br />
                        • Falar sobre o que faria em determinadas situações <br />
                        • Descrever suas experiências <br />
                        • Falar sobre a história de sua vida <br />
                        • Falar sobre coisas que costumava fazer quando criança <br />
                        • Falar sobre quando objetos foram inventados ou construídos <br />
                        • Falar sobre coisas que talvez faça no próximo final de semana <br />
                        • Ligar para alguém, identificar-se e dizer com quem quer falar <br />
                        • Deixar um recado <br />
                        • Reagir a uma notícia <br />
                        • Descrever os movimentos necessários para praticar determinado esporte <br />
                        • Concordar com frases positivas e negativas usando SO DO I ou NEITHER DO I <br />
                        • Reportar o que lhe foi dito no passado <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong><br /><br />

                        • Ordem das palavras em perguntas <br />
                        • Presente Simples <br />
                        • Presente Contínuo <br />
                        • Passado Simples: verbos regulares e irregulares <br />
                        • Passado Contínuo <br />
                        • Conetivos <br />
                        • Uso de TO BE GOING TO para planos e previsões <br />
                        • Presente Contínuo para combinações futuras <br />
                        • Orações relativas restritivas <br />
                        • Presente Perfeito com YET e ALREADY, FOR e SINCE <br />
                        • Uso de SOMETHING, ANYTHING, NOTHING, etc <br />
                        • Comparativo de adjetivos e advérbios, AS … AS <br />
                        • Superlativo <br />
                        • Uso de MUCH e MANY, TOO e NOT ENOUGH <br />
                        • Futuro com WILL e WON’T para previsões, decisões, ofertas e promessas <br />
                        • Uso do infinitivo (verbo + TO + verbo) <br />
                        • Uso do gerúndio  (verbo + VERBO + ING) <br />
                        • Modais have to, don't have to, must, must not, can't, should, might <br />
                        • First Conditional <br />
                        • Pronomes possessivos <br />
                        • Second Conditional  <br />
                        • Voz passiva <br />
                        • Uso de USED TO <br />
                        • Phrasal verbs <br />
                        • Uso de SO/NEITHER + verbos auxiliares <br />
                        • Passado Perfeito <br />
                        • Discurso indireto <br />
                        • Perguntas sem verbo auxiliar
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">INTERMEDIATE - GENERAL ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  American English File 3 (Livro +CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre hábitos alimentares <br />
                        • Falar sobre planos, previsões, combinações  <br />
                        • Reagir a boas e más notícias, notícias interessantes ou inesperadas <br />
                        • Apresentar-se e apresentar outras pessoas  <br />
                        • Usar frases para ganhar tempo para pensar (you know, I mean, etc.) <br />
                        • Falar sobre hábitos alimentares e como eles vem mudando <br />
                        • Descrever membros da família  <br />
                        • Falar sobre planos e previsões para o future  <br />
                        • Responder perguntas sobre suas experiências e seu passado <br />
                        • Entrevistar ou ser entrevistado  <br />
                        • Expressar sua opinião sobre algo <br />
                        • Concordar ou discordar com a opinião de outras pessoas  <br />
                        • Falar sobre habilidades <br />
                        • Falar sobre boas maneiras <br />
                        • Comparar diferentes opções de transporte público em sua cidade ou país  <br />
                        • Opinar sobre estereótipos de homens e mulheres  <br />
                        • Descrever algo que você gostaria de saber fazer mas nunca conseguiu <br />
                        • Usar expressões diversas para pedir permissão para fazer algo e para responder <br />
                        • Usar expressões diversas para pedir para alguém fazer algo e para responder  <br />
                        • Cumprimentar alguém que não vê há tempo  <br />
                        • Contar uma história sobre algo que lhe aconteceu usando passado simples, contínuo e perfeito  <br />
                        • Falar sobre seus hábitos passados e atuais  <br />
                        • Descrever um filme dizendo onde se passava, no que se baseava, por quem era dirigido e o que achou dele <br />
                        • Fazer deduções sobre pessoas famosas usando MIGHT BE, MUST BE e CAN’T BE <br />
                        • Usar diferentes maneiras para dar sugestões <br />
                        • Responder a sugestões  <br />
                        • Pedir desculpas  <br />
                        • Dar desculpas <br />
                        • Descrever escolas que frequentou e o que gostava e não gostava nelas <br />
                        • Dizer o que fará em determinadas circunstâncias  <br />
                        • Descrever a casa de férias ideal <br />
                        • Dizer o que faria em determinadas circunstâncias <br />
                        • Relatar algo que lhe foi perguntado e sua resposta <br />
                        • Dizer o que teria feito em determinadas circunstâncias <br />
                        • Fazer perguntas indiretas iniciando com “Você pode me dizer...?”  <br />
                        • Discutir um problema <br />
                        • Descrever algo que faz em excesso e algo que não faz o suficiente  <br />
                        • Falar sobre um aparelho que usa e porque é útil  <br />
                        • Descrever alguém que admira  <br />
                        • Confirmar informações  <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong><br /><br />

                        • Presente Simples e Presente Contínuo com verbos dinâmicos e estáticos <br />
                        • Futuro com Presente Contínuo, going to, will / won’t <br />
                        • Presente Perfeito e Passado Simples  <br />
                        • Presente Perfeito + FOR/ SINCE, Presente Perfeito Contínuo <br />
                        • Comparativos e superlativos <br />
                        • Uso (ou não) de artigos definidos e indefinidos  <br />
                        • Modais can, could, be able to, must, have to, should <br />
                        • Passado: simples, contínuo e perfeito  <br />
                        • Uso de usually e used to  <br />
                        • Voz Passiva <br />
                        • Modais: might, can't, must <br />
                        • First Conditional e orações temporais + WHEN, UNTIL, etc <br />
                        • Second Conditional  <br />
                        • Discurso indireto  <br />
                        • Gerúndios e infinitivos <br />
                        • Third Conditional  <br />
                        • Quantifiers: A LOT OF / A LOT / ENOUGH / TOO / A LITTLE / A FEW <br />
                        • Orações relativas restritivas e não restritivas <br />
                        • Tag questions
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">UPPER-INTERMEDIATE - GENERAL ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  American English File 4 (Livro +CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Entrevistar alguém, responder a perguntas de entrevistas <br />
                        • Compreender e contar histórias assustadoras <br />
                        • Compreender e descrever análises de assinaturas <br />
                        • Dar conselhos e sugestões <br />
                        • Falar das próprias experiências <br />
                        • Falar sobre primeiros socorros <br />
                        • Descrever condições de saúde <br />
                        • Descrever pessoas <br />
                        • Compreender e descrever estereótipos <br />
                        • Falar sobre roupas e moda <br />
                        • Compreender e descrever situações em voos e aeroportos <br />
                        • Contar histórias curtas <br />
                        • Falar sobre a infância <br />
                        • Falar sobre ecologia e sobre o futuro do planeta <br />
                        • Utilizar expressões com TAKE <br />
                        • Falar sobre situações de vida ou morte <br />
                        • Falar sobre planos, desejos e realizações <br />
                        • Falar sobre arrependimentos <br />
                        • Falar sobre hábitos de sono <br />
                        • Falar sobre desentendimentos <br />
                        • Descrever o corpo, expressões faciais e sentimentos <br />
                        • Falar sobre atuação <br />
                        • Falar sobre crime e punição <br />
                        • Falar sobre mandar fazer algo <br />
                        • Relatar notícias <br />
                        • Expressar opiniões <br />
                        • Discutir verdade e mentira na propaganda <br />
                        • Falar sobre megacidades <br />
                        • Discutir fatos científicos e mitos <br />
                        • Falar sobre figuras públicas e seus grandes discursos <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong><br /><br />

                        • Formação de perguntas <br />
                        • Verbos auxiliares <br />
                        • THE...THE... + comparativo <br />
                        • Presente Perfeito Simples e Contínuo <br />
                        • Uso de adjetivos como substantivos <br />
                        • Ordem dos adjetivos <br />
                        • Tempos verbais utilizados para falar do passado <br />
                        • Uso de SO/SUCH... THAT <br />
                        • Locuções adverbiais <br />
                        • Futuro Perfeito e Futuro Contínuo  <br />
                        • Zero e first conditionals <br />
                        • Orações temporais <br />
                        • Unreal conditional  <br />
                        • Uso de WISH + WOULD <br />
                        • WISH + Passado Simples ou Passado Perfeito <br />
                        • Gerúndios e Infinitivos <br />
                        • Falar sobre música e emoções <br />
                        • Uso de used to, be used to, get used to <br />
                        • Modais no passado <br />
                        • Uso de WOULD RATHER <br />
                        • Verbos dos sentidos <br />
                        • Voz passiva <br />
                        • Uso de IT’S SAID THAT, HE IS THOUGHT TO, etc <br />
                        • Uso de HAVE SOMETHING DONE <br />
                        • Discurso indireto <br />
                        • Orações subordinadas adverbiais concessivas  <br />
                        • Orações subordinadas adverbiais finais  <br />
                        • Uso de WHATEVER, WHENEVER, etc <br />
                        • Substantivos incontáveis e plurais <br />
                        • Uso de ALL, EVERY, BOTH, etc <br />
                        • Artigos
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>
                        Business English
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>A abordagem <i>business</i> é oferecida a partir do nível Pre-Intermediate (logo após o nível Básico), pois o aluno já deve ter adquirido os conhecimentos iniciais.</Typography>
                    <br />
                    <Typography> <strong>
                        Pre-Intermediate <br />
                        Intermediate <br />
                        Upper- Intermediate <br />
                        Advanced </strong>
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">PRE-INTERMEDIATE - BUSINESS ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  MARKET LEADER ELEMENTARY  (CD + CD ROM) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Apresentar-se / Apresentar outras pessoas    <br />
                        • Falar sobre o ambiente de trabalho e atividades de lazer   <br />
                        • Falar sobre problemas no ambiente de trabalho   <br />
                        • Aplicar expressões de conversas ao telefone   <br />
                        • Fazer reservas em hotéis e confirmar arranjos de viagem, acomodação, passagens aéreas   <br />
                        • Falar sobre comidas e ler cardápios   <br />
                        • Fazer o pedido em um restaurante   <br />
                        • Apresentar produtos   <br />
                        • Comparar lugares, produtos e situações   <br />
                        • Negociar e discutir sobre soluções de problemas   <br />
                        • Descrever pessoas   <br />
                        • Falar sobre diferentes tipos de mercados   <br />
                        • Descrever empresas   <br />
                        • Falar sobre culturas de empresas   <br />
                        • Falar sobre atividades no passado   <br />
                        • Falar sobre habilidades   <br />
                        • Falar sobre sua experiência profissional   <br />
                        • Fazer arranjos para o futuro   <br />
                        • Apresentar e discutir soluções de negócios para Case Studies <br /><br />

                        <strong>Conteúdos Gramaticais e Vocabulário a serem abordados:</strong><br /><br />

                        • Nacionalidades <br />
                        • Profissões  <br />
                        • Artigo A / AN  <br />
                        • Verbo TO BE  <br />
                        • Uso de wh-words: what, where, when, who, how, why <br />
                        • Preposições de tempo (in, on, at) <br />
                        • Dias da semana, meses do ano, atividades de lazer <br />
                        • Advérbios e expressões de freqüência  <br />
                        • Presente Simples (formas afirmativa, negativa e interrogativa)  <br />
                        • Adjetivos descrevendo problemas no trabalho <br />
                        • Uso dos verbos Like, Hate, Can’t stand + ing  <br />
                        • Enough / Too  <br />
                        • Have got  <br />
                        • Números, Horas  <br />
                        • Verbo can / can’t  <br />
                        • There is / There are  <br />
                        • Comidas  <br />
                        • Uso de some / any  <br />
                        • Uso de much / many  <br />
                        • Substantivos contáveis e incontáveis  <br />
                        • Passado Simples (formas afirmativa, negativa e interrogativa)  <br />
                        • Expressões e vocabulário sobre viagens, hotéis e aeroportos <br />
                        • Passado Simples  <br />
                        • Formas comparativa / superlativa dos adjetivos  <br />
                        • Uso do much / a lot / a little / a bit  <br />
                        • Presente Contínuo (temporary actions)  <br />
                        • Presente Contínuo x Presente Simples  <br />
                        • Termos da Internet  <br />
                        • Expressões de tempo  <br />
                        • Expressar planos futuros (Presente Contínuo, Going to )  <br />
                        • Uso de will  <br />
                        • Uso dos modais should / could / would  <br />
                        • Presente Perfeito
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">INTERMEDIATE - BUSINESS ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material: MARKET LEADER PRE-INTERMEDIATE (CD + CD ROM) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Usar expressões para comunicar-se por telefone   <br />
                        • Falar sobre sua carreira   <br />
                        • Falar sobre compra e venda de produtos   <br />
                        • Praticar negociações, apresentações e reuniões de negócios   <br />
                        • Falar sobre empresas e sobre sua empresa ( processos, estrutura, metas )   <br />
                        • Usar expressões para “Social English” ( Inglês em ambiente informal usado socialmente) <br />
                        • Trocar informações ao telefone   <br />
                        • Usar expressões para interromper e clarificar ideias   <br />
                        • Usar expressões para negociações e solução de conflitos   <br />
                        • Falar sobre resultados usando números   <br />
                        • Apresentar um produto   <br />
                        • Reportar o que uma pessoa falou   <br />
                        • Falar sobre o marketing de sua empresa   <br />
                        • Apresentar e discutir soluções de negócios para Case Studies   <br /><br />

                        <strong>Conteúdos Gramaticais e Vocabulário a serem abordados:</strong><br /><br />

                        • Colocações com a palavra career <br />
                        • Verbos modais e expressões similares ( can, could, should, would, must, may, might, have to, need to, to be supposed to, to be able to) <br />
                        • Presente Simples x Presente Contínuo <br />
                        • Passado Simples x Passado Contínuo <br />
                        • Verbos + substantivos ( collocations )<br />
                        • Verbos + preposições <br />
                        • Vocabulário sobre estresse no ambiente de trabalho <br />
                        • Presente Perfeito x Passado Simples <br />
                        • Multi-word verbs <br />
                        • Revisão de vocabulário sobre comidas <br />
                        • Collocations <br />
                        • Expressar planos futuros ( hope, plan, expect, would like, want, going to ) <br />
                        • Verbos + preposições <br />
                        • Reported Speech <br />
                        • Orações com o 1° e 2° condicionais <br />
                        • Termos sobre economia <br />
                        • Adjetivos para descrever produtos <br />
                        • Voz passiva <br />
                        • Prefixos / Sufixos <br />
                        • Uso de while, until, as soon as
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography>UPPER-INTERMEDIATE - BUSINESS ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  MARKET LEADER INTERMEDIATE (CD + CD ROM) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Discutir acerca de marcas e produtos   <br />
                        • Usar palavras para discutir sobre marcas / moda / falsificação   <br />
                        • Falar sobre suas experiências de viagem   <br />
                        • Usar expressões para participar de reuniões de negócios   <br />
                        • Liderar e estruturar uma reunião de negócios   <br />
                        • Fazer combinações, agendamentos e obter informações por telefone   <br />
                        • Descrever sua empresa, descrever seu cargo na empresa   <br />
                        • Discutir acerca do tópico publicidade   <br />
                        • Apresentar números e resultados da empresa   <br />
                        • Discutir acerca do tópico dinheiro   <br />
                        • Discutir sobre a importância do conhecimento de diferentes culturas no cenário corporativo  <br />
                        • Entrevistas de emprego   <br />
                        • Discutir sobre o desenvolvimento de mercados internacionais   <br />
                        • Discutir ética no ambiente de trabalho   <br />
                        • Discutir liderança e qualidades de um líder   <br />
                        • Apresentar e discutir soluções de negócios para Case Studies  <br /><br />

                        <strong>Conteúdos Gramaticais e Vocabulário a serem abordados:</strong><br /><br />

                        • Presente Simples x Presente Contínuo  <br />
                        • Colocações com as palavras brand, product e Market <br />
                        • Formas de expressar futuro (will, to be going to, Presente Simplese)  <br />
                        • Passado Simples x Presente Perfeito  <br />
                        • Artigos (the, an, a)  <br />
                        • Modais ( para dar conselhos, expressar obrigação e necessidade) <br />
                        • Formas ing / infinitive dos verbos  <br />
                        • If + condicional  <br />
                        • Voz passiva  <br />
                        • Pronomes relativos  <br />
                        • Expressões idiomáticas
                    </Typography>

                    <Divider sx={styles.divider} />

                    <Typography variant="h2">ADVANCED - BUSINESS ENGLISH</Typography>
                    <br />
                    <Typography><strong>Material:  MARKET LEADER UPPER-INTERMEDIATE ( CD + CD ROM) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre qualidades de um bom comunicador   <br />
                        • Lidar com situações onde haja falha de comunicação   <br />
                        • Falar sobre marcas internacionais   <br />
                        • Falar sobre relações de trabalho, networking   <br />
                        • Discutir o que faz alguém / empresa ser bem-sucedido   <br />
                        • Discutir motivação   <br />
                        • Discutir fatores de risco e oportunidades de negócio   <br />
                        • Discutir acerca de estilos de gerência, trabalho em equipe, resolução de conflito   <br />
                        • Usar termos financeiros   <br />
                        • Discutir fatores e importância de atendimento ao cliente   <br />
                        • Definir e discutir incorporações e aquisições  <br /><br />

                        <strong>Conteúdos Gramaticais e Vocabulário a serem abordados:</strong><br /><br />

                        • Expressões Idiomáticas  <br />
                        • Frases nominais  <br />
                        • Multi-word verbs  <br />
                        • Prefixos  <br />
                        • Tempos verbais no presente e passado  <br />
                        • Sinônimos  <br />
                        • Advérbios de grau  <br />
                        • Modal perfect <br />
                        • Verbos + preposições  <br />
                        • Gerunds  <br />
                        • Modais (previsão, probabilidade)
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/PlanosDeCurso'} sx={styles.button}>
                Voltar
            </Button>
        </Box>
    )
}