import { Box } from "@mui/material";
import HangmanGame from "../components/HangmanGame";

export default function HangManGame() {
    const style = {
        box: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '90vh'
        }
    }
    return (
        <Box sx={style.box}>
            <HangmanGame openFunction={() => { }} />
        </Box>
    )
}