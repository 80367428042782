import { Box, Button, CardMedia, CircularProgress, Divider, Link, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function SunnysReadersGang() {

    const [content, setContent] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [pdf, setPdf] = useState('');
    const [title, setTitle] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        api.get(`/ws/sunnys-readers-gang.php`)
            .then(res => {
                if (res.data.success) {
                    setContent(res.data.content);
                    setIsLoading(false);
                } else {
                    alert('Houve um erro na conexão, tente novamente mais tarde!');
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                alert('Houve um erro na conexão, tente novamente mais tarde!');
                setIsLoading(false);
            })
    }, [])

    const styles = {
        boxOutside : {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        cardMedia: { width: '60%', mb: '1.5em' },
        boxInside: { width: '100%', pt: '1.5em' },
        boxLoading:{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' },
        boxMap:{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' },
        divider:{ width: '100%', m: '2em 0' },
        modal:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        boxIframe:{
            position: 'absolute',
            bgcolor: 'background.paper',
            boxShadow: 24,
            width: '90%',
            height: '80vh',
            mb: '10vh',
            borderRadius: '8px'
        },
        circularProgress:{ m: 'auto' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <>
            <Box sx={styles.boxOutside}>
                {/* <CardMedia component='img' image={'https://gotheextramile.com.br/img/sunny-s-readers-gang.png'} alt="" sx={styles.cardMedia} /> */}
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/sunny-s-readers-gang.png',
                    imageStyle:styles.imageStyle,
                    skeletonStyle:styles.skeletonStyle
                }} />
                <Typography>
                    Descubra com Sunny um mundo de aventuras, fantasias e muitas histórias.
                </Typography>
                <Box sx={styles.boxInside}>
                    {
                        isLoading ?
                            <Box sx={styles.boxLoading}>
                                <CircularProgress size={'3em'} color="primary" sx={styles.circularProgress} />
                            </Box>
                            :
                            content.map(obj => {

                                return (
                                    <Box key={obj.id} sx={styles.boxMap} >
                                        <Typography variant="h2">
                                            {obj.titulo}
                                        </Typography>
                                        {obj.video !== '' &&
                                            <Link fontSize='1em' target='_blank' href={obj.video} variant="h2" mt='1em'>
                                                {obj.video}
                                            </Link>
                                        }
                                        {obj.pdf !== '' &&
                                            <Link fontSize='1em' onClick={() => { handleOpen(); setPdf(`https://goaheadschool.com.br/files/sunny-s-readers-gang/${obj.pdf}#view=fitH`); }} variant="h2" mt='1em'>
                                                {obj.pdf}
                                            </Link>
                                        }
                                        <Divider sx={styles.divider} />
                                    </Box>
                                )
                            })
                    }
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={styles.modal}
            >
                <Box sx={styles.boxIframe}>
                    <iframe src={pdf} width={'100%'} height={'100%'} frameBorder={0} />
                </Box>
            </Modal>
        </>
    )

}