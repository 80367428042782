const questions1 = [
  {
    question: "1. Where are people taking walking tours?",
    options: [
      { id: "part1_1_1", value: "In Phoenix", label: "In Phoenix" },
      { id: "part1_1_2", value: "In Vancouver", label: "In Vancouver" },
      { id: "part1_1_3", value: "In Calgary", label: "In Calgary" }
    ]
  },
  {
    question: "2. When are they offering special prices on vacations?",
    options: [
      { id: "part1_2_1", value: "Today only", label: "Today only" },
      { id: "part1_2_2", value: "This month", label: "This month" },
      { id: "part1_2_3", value: "Next week", label: "Next week" }
    ]
  },
  {
    question:
      "“3. Jackie is leaving the office at 4p.m. today. Please, remember to lock the doors. The keys are in your drawer”\nWhat must you do with the keys?",
    options: [
      { id: "part1_3_1", value: "give them to Jackie", label: "give them to Jackie" },
      { id: "part1_3_2", value: "remember to bring them", label: "remember to bring them" },
      { id: "part1_3_3", value: "put them in your drawer", label: "put them in your drawer" },
      { id: "part1_3_4", value: "lock the door", label: "lock the door" },
      { id: "part1_3_5", value: "take them home", label: "take them home" }
    ]
  },
  {
    question: "4. Simon __________ very tall. But __________ older brother is fairly short.",
    options: [
      { id: "part1_4_1", value: "is / his", label: "is / his" },
      { id: "part1_4_2", value: "are / your", label: "are / your" },
      { id: "part1_4_3", value: "has / he", label: "has / he" }
    ]
  },
  {
    question: "5. She __________ like football very much. Actually, she rarely __________ sports.",
    options: [
      { id: "part1_5_1", value: "don't / play", label: "don't / play" },
      { id: "part1_5_2", value: "doesn't / plays", label: "doesn't / plays" },
      { id: "part1_5_3", value: "hasn't / plays", label: "hasn't / plays" }
    ]
  },
  {
    question: "6. How __________ does a bottle of red wine cost? We need __________ for the party.",
    options: [
      { id: "part1_6_1", value: "many / a lot", label: "many / a lot" },
      { id: "part1_6_2", value: "much / some", label: "much / some" },
      { id: "part1_6_3", value: "is / any", label: "is / any" }
    ]
  },
  {
    question: "7. There __________ a big supermarket next to my house. It is the __________ one in the neighborhood.",
    options: [
      { id: "part1_7_1", value: "is / cheapest", label: "is / cheapest" },
      { id: "part1_7_2", value: "are / cheaper", label: "are / cheaper" },
      { id: "part1_7_3", value: "have / most cheap", label: "have / most cheap" }
    ]
  },
  {
    question: "8. Neil can't __________ tennis. He's broken his arm and it is __________ painful.",
    options: [
      { id: "part1_8_1", value: "to play / many", label: "to play / many" },
      { id: "part1_8_2", value: "playing / much", label: "playing / much" },
      { id: "part1_8_3", value: "play / too", label: "play / too" }
    ]
  },
  {
    question: "9. __________ some more tea?",
    options: [
      { id: "part1_9_1", value: "Would you like", label: "Would you like" },
      { id: "part1_9_2", value: "Do you like", label: "Do you like" },
      { id: "part1_9_3", value: "You'd like", label: "You'd like" }
    ]
  },
  {
    question: "10. The baby is __________ right now. I __________ to shop for groceries before the baby wakes up.",
    options: [
      { id: "part1_10_1", value: "sleep / go", label: "sleep / go" },
      { id: "part1_10_2", value: "sleeping / am going to", label: "sleeping / am going to" },
      { id: "part1_10_3", value: "slept / going", label: "slept / going" }
    ]
  }
];

const questions2 = [
  {
    question: "1. Which statement is true?",
    options: [
      { "id": "1_1", "label": "People usually react the same way when facing difficulties.", "value": "People usually react the same way when facing difficulties." },
      { "id": "1_2", "label": "Nobody likes challenges.", "value": "Nobody likes challenges." },
      { "id": "1_3", "label": "Sometimes progress seems hard to achieve.", "value": "Sometimes progress seems hard to achieve." },
      { "id": "1_4", "label": "Difficult decisions are not common in the business world.", "value": "Difficult decisions are not common in the business world." }
    ]
  },
  {
    question: "2. What happens to some people when upset by challenges?",
    options: [
      { "id": "2_1", "label": "They react positively.", "value": "They react positively." },
      { "id": "2_2", "label": "They decide to try something different.", "value": "They decide to try something different." },
      { "id": "2_3", "label": "They keep working.", "value": "They keep working." },
      { "id": "2_4", "label": "They make difficult decisions.", "value": "They make difficult decisions." }
    ]
  },
  {
    question: "3. Why learn a second language? On a survey, 1001 British teenagers have different reasons. Twenty-four percent think a second language could improve their job opportunities. Many students get jobs in the service industry, such as working in stores, restaurants, and banks. It´s helpful to speak more than one language. Speaking more than one language may also mean they get paid more.\nSpeaking another language __________ which idea is not expressed in the paragraph above?",
    options: [
      { "id": "3_1", "label": "Many students find it important", "value": "Many students find it important" },
      { "id": "3_2", "label": "More than 20% think chances for employment are better", "value": "More than 20% think chances for employment are better" },
      { "id": "3_3", "label": "Salaries and wages can be higher", "value": "Salaries and wages can be higher" },
      { "id": "3_4", "label": "It makes a difference if one works in the service industry", "value": "It makes a difference if one works in the service industry" },
      { "id": "3_5", "label": "All the respondents gave the same reasons", "value": "All the respondents gave the same reasons" }
    ]
  },
  {
    question: "4. I __________ watch the news last night. I __________ busy cleaning up the mess my kids made.",
    options: [
      { "id": "4_1", "label": "didn´t / was", "value": "didn´t / was" },
      { "id": "4_2", "label": "don´t / is", "value": "don´t / is" },
      { "id": "4_3", "label": "no / were", "value": "no / were" }
    ]
  },
 {
    question: "5. You really __________ stop drinking that __________ coffee. That´s why you __________ sleep at night.",
    options: [
      { "id": "5_1", "label": "can / enough / should", "value": "can / enough / should" },
      { "id": "5_2", "label": "must / much / can´t", "value": "must / much / can´t" },
      { "id": "5_3", "label": "don´t have to / too / might", "value": "don´t have to / too / might" }
    ]
  },
  {
    question: "6. My sister __________ to be so scared of everything. But now she is __________ adventurous than I am.",
    options: [
      { "id": "6_1", "label": "was / be", "value": "was / be" },
      { "id": "6_2", "label": "use / very", "value": "use / very" },
      { "id": "6_3", "label": "used / more", "value": "used / more" }
    ]
  },
  {
    question: "7. Where did you go last summer vacation?\n– I __________ on a terrific cruise along the Brazilian coast. I __________ the best time ever.",
    options: [
      { "id": "7_1", "label": "goed / has", "value": "goed / has" },
      { "id": "7_2", "label": "went / had", "value": "went / had" },
      { "id": "7_3", "label": "got / made", "value": "got / made" }
    ]
  },
  {
    question: "8. My mother __________ never been to a cricket match.",
    options: [
      { "id": "8_1", "label": "has", "value": "has" },
      { "id": "8_2", "label": "have", "value": "have" },
      { "id": "8_3", "label": "is", "value": "is" }
    ]
  },
  {
    question: "9. If she __________ Tom, she __________ give him your message. Don´t worry!",
    options: [
      { "id": "part2_9_1", "value": "sees / will", "label": "sees / will" },
      { "id": "part2_9_2", "value": "see / would", "label": "see / would" },
      { "id": "part2_9_3", "value": "can see / might", "label": "can see / might" }
    ]
  },
  {
    question: "10. Magda __________ in England for her last business conference.",
    options: [
      { "id": "part2_10_1", "value": "was", "label": "was" },
      { "id": "part2_10_2", "value": "went", "label": "went" },
      { "id": "part2_10_3", "value": "been", "label": "been" }
    ]
  }
]

const questions3 = [
  {
    question: "1. What is not mentioned in the audio text?",
    options: [
      { id: "1_1", label: "For a long time it was expected that you'd follow your parents' professions.", value: "For a long time it was expected that you'd follow your parents' professions." },
      { id: "1_2", label: "Education and technology bring new ways to think about your future.", value: "Education and technology bring new ways to think about your future." },
      { id: "1_3", label: "Nowadays people are more likely to comply with social traditions.", value: "Nowadays people are more likely to comply with social traditions." },
      { id: "1_4", label: "In the past, people had much fewer options to choose a career.", value: "In the past, people had much fewer options to choose a career." }
    ]
  },
  {
    question: "2. Which word mentioned in the text means the same as ‘accomplish’?",
    options: [
      { id: "2_1", label: "choose", value: "choose" },
      { id: "2_2", label: "expect", value: "expect" },
      { id: "2_3", label: "become", value: "become" },
      { id: "2_4", label: "achieve", value: "achieve" }
    ]
  },
  {
    question: "3. What was also true for other professions?",
    options: [
      { id: "3_1", label: "People had a lot of options to choose from.", value: "People had a lot of options to choose from." },
      { id: "3_2", label: "You were likely follow your parents’ footsteps.", value: "You were likely follow your parents’ footsteps." },
      { id: "3_3", label: "They could offer opportunities to travel around the world.", value: "They could offer opportunities to travel around the world." },
      { id: "3_4", label: "They were all dependent on education and technology.", value: "They were all dependent on education and technology." }
    ]
  },
  {
    question: "4. Your attention, please. Stopping momentarily in front of the airport terminal building is permitted only for the unloading of passengers and baggage. This applies to all passenger vehicles, and taxis. Airport buses are required to use the special loading zone on Level D. Short-term parking is available at the airport parking structure, and long-term parking is available at the facility on Jones Rd. Do not leave your vehicle unattended for any reason. Unattended vehicles will be ticketed and towed to the police lot downtown. Your cooperation is appreciated. Which of these is not permitted?",
    options: [
      { id: "4_1", label: "unloading suitcases", value: "unloading suitcases" },
      { id: "4_2", label: "parking on Jones Road", value: "parking on Jones Road" },
      { id: "4_3", label: "leaving a vehicle unattended", value: "leaving a vehicle unattended" },
      { id: "4_4", label: "stopping for a short period", value: "stopping for a short period" },
      { id: "4_5", label: "letting passengers out", value: "letting passengers out" }
    ]
  },
  {
    question: "5. Which word means the same as 'momentarily'?",
    options: [
      { id: "5_1", label: "quickly", value: "quickly" },
      { id: "5_2", label: "routinely", value: "routinely" },
      { id: "5_3", label: "constantly", value: "constantly" },
      { id: "5_4", label: "permanently", value: "permanently" }
    ],
  },
   {
    question: "6. Joanna __________ her new mobile phone. Poor her! She still has __________ installments left to pay.",
    options: [
      { id: "part3_6_1", label: "is losing / some", value: "is losing / some" },
      { id: "part3_6_2", label: "loses / few", value: "loses / few" },
      { id: "part3_6_3", label: "has lost / a few", value: "has lost / a few" }
    ]
  },
  {
    question: "7. If I __________ rich, I __________ buy a huge farm in Somerset.",
    options: [
      { id: "part3_7_1", label: "was / will", value: "was / will" },
      { id: "part3_7_2", label: "be / shall", value: "be / shall" },
      { id: "part3_7_3", label: "were / would", value: "were / would" }
    ]
  },
  {
    question: "8. She hopes __________ her Bar exam this time.",
    options: [
      { id: "part3_8_1", label: "to pass", value: "to pass" },
      { id: "part3_8_2", label: "pass", value: "pass" },
      { id: "part3_8_3", label: "passing", value: "passing" }
    ]
  },
  {
    question: "9. I wish I __________ play a musical instrument.",
    options: [
      { id: "part3_9_1", label: "can", value: "can" },
      { id: "part3_9_2", label: "could", value: "could" },
      { id: "part3_9_3", label: "should", value: "should" }
    ]
  },
  {
    question: "10. Claire didn´t come no class. I wonder what the matter is!",
    options: [
      { id: "part3_10_1", label: "She should be sick.", value: "She should be sick." },
      { id: "part3_10_2", label: "She had to be sick.", value: "She had to be sick." },
      { id: "part3_10_3", label: "She must be sick.", value: "She must be sick." }
    ]
  },
  {
    question: "11. I don´t really mind __________ on this couch. It actually seems __________ quite comfortable.",
    options: [
      { id: "part3_11_1", label: "to sleep / being", value: "to sleep / being" },
      { id: "part3_11_2", label: "sleeping / to be", value: "sleeping / to be" },
      { id: "part3_11_3", label: "sleep / be", value: "sleep / be" }
    ]
  }
];

const questions4 = [
    {
      question: "1. They are talking about a friend who bought __________",
      options: [
        { id: "1_1", label: "a new computer.", value: "a new computer." },
        { id: "1_2", label: "an excellent product.", value: "an excellent product." },
        { id: "1_3", label: "a glitchy gadget.", value: "a glitchy gadget." },
        { id: "1_4", label: "a box of doughnuts.", value: "a box of doughnuts." }
      ]
    },
    {
      question: "2. What do the two friends have in common?",
      options: [
        { id: "2_1", label: "They’re skillful.", value: "They’re skillful." },
        { id: "2_2", label: "They have a lot of money.", value: "They have a lot of money." },
        { id: "2_3", label: "They have a cough.", value: "They have a cough." },
        { id: "2_4", label: "They’re clumsy.", value: "They’re clumsy." }
      ]
    },
    {
      question: "3. One of the girls’ computer __________",
      options: [
        { id: "3_1", label: "is not working.", value: "is not working." },
        { id: "3_2", label: "is old.", value: "is old." },
        { id: "3_3", label: "fell apart.", value: "fell apart." },
        { id: "3_4", label: "doesn’t fit her needs.", value: "doesn’t fit her needs." }
      ]
    },
    {
      question: "4. __________ American Oprah Winfrey ran away from home at age fourteen. She eventually found work as a TV news reporter on the evening news, but was fired at age twenty-two and told she was 'unfit for TV.' She started working on a morning talk show and went on to dominate daytime TV for twenty-five years. This excerpt has several purposes, except for one. Check the option that does not apply:",
      options: [
        { id: "4_1", label: "to inform", value: "to inform" },
        { id: "4_2", label: "to entertain", value: "to entertain" },
        { id: "4_3", label: "to persuade", value: "to persuade" },
        { id: "4_4", label: "to describe", value: "to describe" },
        { id: "4_5", label: "to instruct", value: "to instruct" }
      ]
    },
    {
      question: "5. When Gregory arrived at the disco, Tania __________.",
      options: [
        { id: "5_1", label: "already left", value: "already left" },
        { id: "5_2", label: "has already left", value: "has already left" },
        { id: "5_3", label: "had already left", value: "had already left" }
      ]
    },
    {
      question: "6. If I __________ on holiday to Poland, I wouldn't have met Donata.",
      options: [
        { id: "6_1", label: "didn't go", value: "didn't go" },
        { id: "6_2", label: "haven't gone", value: "haven't gone" },
        { id: "6_3", label: "hadn't gone", value: "hadn't gone" }
      ]
    },
    {
      question: "7. By the time you get this letter I __________",
      options: [
        { id: "7_1", label: "will have left", value: "will have left" },
        { id: "7_2", label: "am going to leave", value: "am going to leave" },
        { id: "7_3", label: "would leave", value: "would leave" }
      ]
    },
    {
      question: "8. A: What are you doing tonight? B: I'm not sure, I __________ to the cinema.",
      options: [
        { id: "8_1", label: "will go", value: "will go" },
        { id: "8_2", label: "would go", value: "would go" },
        { id: "8_3", label: "might go", value: "might go" }
      ]
    },
    {
      question: "9. Simon forgot __________ the lights before he left.",
      options: [
        { id: "9_1", label: "turn off", value: "turn off" },
        { id: "9_2", label: "turning off", value: "turning off" },
        { id: "9_3", label: "to turn off", value: "to turn off" }
      ]
    },
    {
      question: "10. It's no use __________ to him. He doesn't listen.",
      options: [
        { id: "10_1", label: "to speak", value: "to speak" },
        { id: "10_2", label: "spoke", value: "spoke" },
        { id: "10_3", label: "speaking", value: "speaking" }
      ]
    },
    {
      question: "11. Karla was offered the job __________ having poor qualifications.",
      options: [
        { id: "11_1", label: "despite", value: "despite" },
        { id: "11_2", label: "although", value: "although" },
        { id: "11_3", label: "even though", value: "even though" }
      ]
    }
  ];

  const questions5 =[
    {
      question: "1. At which of these times may only official school groups attend the fair?",
      options: [
        { id: "part5_1_1", label: "Thursday at 3 P.M.", value: "Thursday at 3 P.M." },
        { id: "part5_1_2", label: "Friday at 11 A.M.", value: "Friday at 11 A.M." },
        { id: "part5_1_3", label: "Friday at 5 P.M.", value: "Friday at 5 P.M." },
        { id: "part5_1_4", label: "Saturday at 10 A.M", value: "Saturday at 10 A.M" }
      ]
    },
    {
      question: "2. Who may attend the fair May 21 to May 23?",
      options: [
        { id: "part5_2_1", label: "Only members of the European Book Foundation", value: "Only members of the European Book Foundation" },
        { id: "part5_2_2", label: "Only children and their parents", value: "Only children and their parents" },
        { id: "part5_2_3", label: "Anyone who wants to", value: "Anyone who wants to" },
        { id: "part5_2_4", label: "Only publishers and bookstore owners", value: "Only publishers and bookstore owners" }
      ]
    },
    {
      question: "3. What is taking place at the book fair for the first time this year?",
      options: [
        { id: "part5_3_1", label: "A magician’s act", value: "A magician’s act" },
        { id: "part5_3_2", label: "Computer workshops", value: "Computer workshops" },
        { id: "part5_3_3", label: "Sessions at which books are signed by the author", value: "Sessions at which books are signed by the author" },
        { id: "part5_3_4", label: "Puppet shows", value: "Puppet shows" }
      ]
    },
    {
      question: "4. According to the paragraph above, what happened towards the end of the eighteenth century?",
      options: [
        { id: "part5_4_1", label: "Lots of professors spent time in German universities", value: "Lots of professors spent time in German universities" },
        { id: "part5_4_2", label: "Most graduates went into politics.", value: "Most graduates went into politics." },
        { id: "part5_4_3", label: "American academia surpassed German universities in quality.", value: "American academia surpassed German universities in quality." },
        { id: "part5_4_4", label: "Fewer and fewer grads were ordained.", value: "Fewer and fewer grads were ordained." },
        { id: "part5_4_5", label: "Going into Law raised little or no interest.", value: "Going into Law raised little or no interest." }
      ]
    },
    {
      question: "5. Not only __________ to London but she also visited many other places in England.",
      options: [
        { id: "part5_5_1", label: "she went", value: "she went" },
        { id: "part5_5_2", label: "went she", value: "went she" },
        { id: "part5_5_3", label: "did she go", value: "did she go" }
      ]
    },
    {
    question: "6. My sister __________ regretted turning down the chance of studying at the Teacher Training College in Gorzów.",
    options: [
      { id: "part5_6_1", label: "entirely", value: "entirely" },
      { id: "part5_6_2", label: "bitterly", value: "bitterly" },
      { id: "part5_6_3", label: "absolutely", value: "absolutely" }
    ]
  },
  {
    question: "7. Now remember, you __________ the test until the teacher tells you to.",
    options: [
      { id: "part5_7_1", label: "are not starting", value: "are not starting" },
      { id: "part5_7_2", label: "are not to start", value: "are not to start" },
      { id: "part5_7_3", label: "haven't started", value: "haven't started" }
    ]
  },
  {
    question: "8. He was thought __________ the disease in Hong Kong.",
    options: [
      { id: "part5_8_1", label: "to catch", value: "to catch" },
      { id: "part5_8_2", label: "catching", value: "catching" },
      { id: "part5_8_3", label: "to have caught", value: "to have caught" }
    ]
  },
  {
    question: "9. My flat __________ as soon as possible. It's in an awful state.",
    options: [
      { id: "part5_9_1", label: "needs redecorating", value: "needs redecorating" },
      { id: "part5_9_2", label: "to redecorate", value: "to redecorate" },
      { id: "part5_9_3", label: "redecorated", value: "redecorated" }
    ]
  },
  {
    question: "10. There's no point __________ staying up all night if your exam is tomorrow.",
    options: [
      { id: "part5_10_1", label: "on", value: "on" },
      { id: "part5_10_2", label: "with", value: "with" },
      { id: "part5_10_3", label: "in", value: "in" }
    ]
  },
  {
    question: "11. Rarely __________ meat.",
    options: [
      { id: "part5_11_1", label: "I eat", value: "I eat" },
      { id: "part5_11_2", label: "do I eat", value: "do I eat" },
      { id: "part5_11_3", label: "I have eaten", value: "I have eaten" }
    ]
  }
] 

const partes = [
    {
        "title": "PART 1",
        "message": "Questions 1 and 2 refer to the following recording:",
        "part": "parte1",
        "typeFile1": "audio/ogg",
        "srcFile1": "https://gotheextramile.com.br/files/audio/part1.ogg",
        "typeFile2": "audio/mpeg",
        "srcFile2": "https://gotheextramile.com.br/files/audio/part1.mp3",
        "questions": questions1,
    },
    {
        "title": "PART 2",
        "message": "Questions 1 and 2 refer to the following talk:",
        "srcFile1": "https://gotheextramile.com.br/files/audio/part2.ogg",
        "srcFile2": "https://gotheextramile.com.br/files/audio/part2.mp3",
        "typeFile1": "audio/ogg",
        "typeFile2": "audio/mpeg",
        "part": "parte2",
        "questions": questions2,
    },
    {
        "title": "PART 3",
        "message": "Questions 1-3 refer to the following telephone conversation:",
        "srcFile1": "https://gotheextramile.com.br/files/audio/part3.ogg",
        "srcFile2": "https://gotheextramile.com.br/files/audio/part3.mp3",
        "typeFile1": "audio/ogg",
        "typeFile2": "audio/mpeg",
        "part": "parte3",
        "questions": questions3,
    },
    {
        "title": "PART 4",
        "message": "Questions 1-3 refer to the following telephone conversation:",
        "srcFile1": "https://gotheextramile.com.br/files/audio/part4.ogg",
        "srcFile2": "https://gotheextramile.com.br/files/audio/part4.mp3",
        "typeFile1": "audio/ogg",
        "typeFile2": "audio/mpeg",
        "part": "parte4",
        "questions": questions4,
    },

    {
        "title": "PART 5",
        "message": "Questions 1-3 refer to the following announcement:",
        "srcFile1": "https://gotheextramile.com.br/files/audio/part5.ogg",
        "srcFile2": "https://gotheextramile.com.br/files/audio/part5.mp3",
        "typeFile1": "audio/ogg",
        "typeFile2": "audio/mpeg",
        "part": "parte5",
        "questions": questions5,
    },
]
export default partes