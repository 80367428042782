import { CalendarToday } from "@mui/icons-material";
import ReactHtmlParser from 'html-react-parser';
import { Box, Button, CardMedia, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";


export default function Journal() {

    const [data, setData] = useState([{}]);
    const [message, setMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        api.get('/ws/journal.php')
            .then(res => {
                if (res.data.success) {
                    setIsLoading(false);
                    setData(res.data.journals);
                } else {
                    setMessage('Houve um erro na conexão, tente novamente mais tarde!');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Houve um erro na conexão, tente novamente mais tarde!');
            })
    }, [])
    
    const style = {
        boxOutside:{
            p: '2em 1em 5em 1em ',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        boxLoading:{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '1rem'
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        gridContainer:{width:'100%'},
        typography:{ mt: '.5em' },
        circularProgress:{ m: 'auto' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/journal.png',
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }} />
            {isLoading ?
                <Box sx={style.boxLoading}>
                    <CircularProgress size={'3em'} color="primary" sx={style.circularProgress} />
                </Box>
                :
                <>
                    <Grid container sx={style.gridContainer} spacing={2}>
                        {data.map(obj => {
                            return (
                                <Grid item xs={12} key={parseInt(obj.id)} >
                                    <CardMedia
                                    component={'img'}
                                    sx={style.image}
                                    image={`https://goaheadschool.com.br/img/gonews/${obj.id}.jpg`}
                                    alt={obj.titulo} />
                                    <Box mt='1.5em' textAlign="start" p='0 1.5em'>
                                        <Box display='flex' justifyContent='start' flexDirection='row' color='#999'>
                                            <CalendarToday fontSize="small" />
                                            <Typography color='#999' ml='0.25em'>
                                                {obj.data}
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" sx={style.typography}>
                                            {obj.titulo}
                                        </Typography>
                                        <Typography sx={style.typography}>
                                            {ReactHtmlParser(`${obj.txt}`)  }
                                        </Typography>
                                        <Button
                                        variant="text"
                                        component={Link}
                                        to={`/JournalPost/${obj.id}`}
                                        sx={style.typography}>
                                            Leia mais
                                        </Button>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            }

        </Box>
    )
}