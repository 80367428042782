import { Box, Button, CardMedia, FormControl, Typography } from "@mui/material"

import partes from "../../data/testeNivelamentoGoTeens"
import QuestionNivelamentoHandler from "../../components/QuestionNivelamentoHandler"

import CustomImageHandler from "../../components/CustomImageHandler";
import { useState } from "react"
import useTesteNivelamento from "../../hooks/useSendTesteNivelamento"

const TesteDeNivelamentoGoTeens = () =>{
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento()
    const [answers, setAnswers] = useState([
        [0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0],
    ])

    const handleSubmit = (event)=>{
        event.preventDefault()
        sendTest(partes, answers, 'Go Teens')
    }

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        formControl:{
            padding: '10px',
            marginBotton:'10px',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        },
        formSent:{
            color: 'green',
        },
        skeleton:{
            width: '100%',
            height: '30vh'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h2">
                INGLÊS
            </Typography>
            <Typography font>
                Teste de nivelamento
            </Typography>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/ingles-title.png',
                skeletonStyle:styles.skeleton,
                imageStyle:styles.cardMedia
            }} />
            <Typography variant="h2">Choose the correct alternative:</Typography>
            <FormControl sx={styles.formControl}>
                { partes.map(({title, questions}, index)=>{
                    return( <>
                        <Typography
                        variant="h2"
                        key={`${title}-${index}-title`}>{title}</Typography>
                        <QuestionNivelamentoHandler
                        key={`${title}-${index}-questionHandler`}
                        questions={questions}
                        answerHandler={handleAnswer(index, setAnswers)}/>
                    </>)
                }) }
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                variant="contained"
                size="large"
                type='submit'
                disabled={formSent}
                onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}

export default TesteDeNivelamentoGoTeens