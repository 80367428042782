import { ArrowBackIos, Done } from "@mui/icons-material";
import { Box, Button, CardMedia, Modal, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
import api from "../services/api";
import React from 'react'
import CustomImageHandler from "./CustomImageHandler";
import ReactHtmlParser from 'html-react-parser';

function HangmanGame({ openFunction }) {
    const { tipoConta: tipo_usuario, idUser: user } = useContext(UserContext);

    const navigate = useNavigate();

    const [hang, setHang] = useState(5);
    const [word, setWord] = useState([]);
    const [secret, setSecret] = useState([]);
    const [wrongLetter, setWrongLetter] = useState('');
    const [rightLetter, setRightLetter] = useState('');
    const [letter, setLetter] = useState('');
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [idWord, setIdword] = useState();
    const [gameOver, setGameOver] = useState(true);

    function handleVerify() {
        if (letter !== '') {
            if (rightLetter.includes(letter) || wrongLetter.includes(letter)) {
                alert('Você já inseriu essa letra!')
            } else {
                if (word.includes(letter)) {
                    setRightLetter((previousValue) => `${previousValue}${letter}, `)
                } else {
                    setWrongLetter((previousValue) => `${previousValue}${letter}, `)
                    setHang((previousValue) => --previousValue)
                }
            }

            setLetter('')
        } else alert('Por favor, insira uma letra!')
    };

    function hangWord(data) {
        let list = [];
        data.forEach((character) => {
            let result = '_';
            if (rightLetter.includes(character)) {
                result = character;
            }
            list.push(result);
            setSecret(list);
        })
    };

    function refreshSecret() {
        let list = [];
        word.forEach((character) => {
            let result = '_';
            if (rightLetter.includes(character)) {
                result = character;
            }
            list.push(result);
            setSecret(list);
        });
    };

    function setAnswer(answer) {
        const data = {
            word_id: idWord,
            user,
            tipo_usuario,
            answer
        };
        setGameOver(true)
        api.post('ws/hang-game-answer.php', data)
    }

    useEffect(() => {
        if (hang === 0) {
            setOpen(true);
            setTitle('Não foi dessa vez!');
            setMessage('Sabemos que deu o seu melhor, talvez da próxima você consiga!');
            setAnswer(0);
        }
    }, [hang]);

    useEffect(() => {
        api.get(`/ws/hang-game-word.php/?id=${user}`)
            .then(res => {
                if (res.data.success) {
                    let tempWord = res.data.palavra.toLowerCase().split('');
                    setWord(tempWord);
                    setIdword(res.data.id)
                    hangWord(tempWord);
                    openFunction(true)
                    setGameOver(false)
                    setHang(res.data.palavra.length)
                } else {
                    if (res.data.type === "realizado") {
                        setMessage('Atividade já realizada hoje!')
                    } else if (res.data.type === 'sem palavras') {
                        setTitle('Sem palavras para hoje!')
                    } else alert('Houve um erro! Tente novamente ou contate o administrador!');
                    setGameOver(true)
                    openFunction(false)
                };
            })
            .catch(e => {
                alert(e);
            });
    }, []);

    useEffect(() => {
        refreshSecret();
    }, [rightLetter]);

    useEffect(() => {
        if (secret.length !== 0 && secret.join('') === word.join('')) {
            // setOpen(true);
            setTitle('Parabéns, você acertou! 🏆');
            setMessage(`A palavra correta é <br></br><strong>${word.join('').toUpperCase()}</strong>!`);
            setAnswer(1);
        }
    }, [secret]);

    const styles = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        boxInside: {
            display: 'flex',
            jusifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row'
        },
        boxButton: {
            position: 'absolute',
            width: '75%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: '8px'
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        imageStyle: {
            width: {
                xs: '60%',
                sm: '40rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: { width: '680%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={styles.boxOutside}>

            <CustomImageHandler props={{
                src: "https://gotheextramile.com.br/img/Hangman-retangular.png",
                imageStyle: styles.imageStyle,
                skeletonStyle: styles.skeletonStyle
            }} />

            {wrongLetter !== '' && <Typography color={'error'}>Erradas: <strong>{wrongLetter.toLocaleUpperCase()}</strong></Typography>}

            {rightLetter !== '' && <Typography color={'green'}>Corretas: <strong>{rightLetter.toLocaleUpperCase()}</strong></Typography>}

            {!gameOver && <Typography mt='1em'> {`Você possui ${hang} chances restantes!`} </Typography>}

            <Box sx={styles.boxInside}>
                {(secret.length !== 0 && !gameOver) && secret.map((letter, index) => {
                    return (<Typography sx={{ m: '5px', fontWeight: 'bold', fontSize: '2.5em' }} key={index}> {letter.toUpperCase()} </Typography>)
                })}
            </Box>

            {!gameOver && <TextField value={letter} onChange={event => { setLetter(event.target.value.toLowerCase()) }} inputProps={{ maxLength: 1 }} sx={{ width: '4em' }} />}

            {!gameOver && <Button startIcon={<Done />} variant="contained" onClick={() => handleVerify()} color='secondary' sx={{ width: '75%', mt: '1em' }}>Tentar</Button>}

            {gameOver && <Typography variant="h2" fontSize={'2rem'} mt={'1rem'} textAlign={'center'}>{title}</Typography>}

            {gameOver && <Typography mt={'.5rem'} textAlign={'center'}>{ReactHtmlParser(`${message}`)}</Typography>}

            <Modal
                open={open}
                onClose={() => { navigate('/'); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={styles.modal}
            >
                <Box sx={styles.boxButton}>
                    <Typography id="modal-modal-title" variant="h2">
                        {title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ textAlign: 'center' }}>
                        {message}
                    </Typography>
                    <Box sx={{ mt: '1em' }}>
                        <Button startIcon={<ArrowBackIos />} onClick={() => { navigate('/'); }}>
                            Voltar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default HangmanGame