import { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HangmanGame from "./HangmanGame";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
const style = {
    position: 'absolute',
    width: '85vw',
    bgcolor: 'white',
    boxShadow: 24,
    borderRadius: '8px',
    height: '90vh',
    p: '1rem',
    overflow: 'auto',
};

export default function HangModal() {
    const [open, setOpen] = useState(true)
    const handleClose = () => setOpen(false);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                zIndex: 2130,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box sx={style}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography variant="h2" color={'primary'}>Hangman Game</Typography>
                <HangmanGame openFunction={setOpen} />
            </Box>
        </Modal>
    );
};
