import { ArrowBack } from "@mui/icons-material";
import { Box, Button, CardMedia, createTheme, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme/global";
import CustomImageHandler from "../components/CustomImageHandler";

export default function Development() {

    return (
        <Box sx={styles.container}>
            <CustomImageHandler props={{ src: 'https://gotheextramile.com.br/img/logo2.png', imageStyle: { width: '70%' }, skeletonStyle: { width: '70%', height: '9rem' } }} />
            <Typography sx={styles.text}>
                No momento, esta página está em desenvolvimento. Agradecemos sua compreensão
            </Typography>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/'} sx={{ width: '75%', mt: '1em', textTransform: 'uppercase' }}>
                Voltar
            </Button>
        </Box>
    )
}

const styles = createTheme({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '95vh',
        bgcolor: theme.palette.background
    },
    text: {
        width: '80%',
        textAlign: 'center',
        mt: '1em'
    }
})