import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import ReactHtmlParser from 'html-react-parser';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";

import CustomImageHandler from "../components/CustomImageHandler";
import LinkOpener from "../components/LinkOpener";

export default function IdiomPhrasalVerbDetails() {

    const [data, setData] = useState([{}]);

    const { id } = useParams();

    useEffect(() => {
        api.get('/ws/idiom-phrasal-verb-visualizar?id=' + id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.idiom[0]);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const style = {
        outsideBox: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
            p: '2em 1em 5em 1em ',
            maxWidth: "95vw",
            '& img': {
                maxWidth: '100%'
            }
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }, mb: '1.5em'
        },
        button: { width: '75%', mt: '1em', textTransform: 'uppercase' },
        imageStyle: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }, height: '18rem', mb: '1.5em'
        }
    };

    function removeClickableLinks(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Encontra todos os elementos âncora (links) na string HTML.
        const links = doc.querySelectorAll('a');

        // Remove todos os links.
        links.forEach(link => {
            link.parentNode.removeChild(link);
        });

        // Retorna o conteúdo modificado sem os links.
        return doc.body.innerHTML;
    };

    return (
        <Box sx={style.outsideBox}>
            <CustomImageHandler props={{
                src: 'https://gotheextramile.com.br/img/idiom-phrasal-verb-of-the-day.png',
                imageStyle: style.imageStyle,
                skeletonStyle: style.skeletonStyle
            }} />
            <Typography fontWeight='Bold'>
                {data.data}
            </Typography>
            <Typography variant="h2" mt="1em">
                {data.titulo}
            </Typography>
            <Box width={'100%'} mt="1em">
                <Typography>
                    {
                        window.cordova ?
                            <LinkOpener>
                                {ReactHtmlParser(`${data.texto}`)}
                            </LinkOpener>
                            :
                            ReactHtmlParser(`${data.texto}`)
                    }
                </Typography>
            </Box>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/IdiomPhrasalVerb'} sx={style.button}>
                Voltar
            </Button>
        </Box>
    )
}