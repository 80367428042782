import { useContext, useState } from "react";
import api from "../services/api";
import HTMLReactParser from 'html-react-parser';
import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { UserContext } from "../context/UserProvider";

export function QuestionHandler(params) {


    const theme = useTheme();

    const { idUser, tipoConta } = useContext(UserContext);

    const { refreshData = null, obj: { id, answered, answers, descricao, rigth_awnser, user_awnser, data } } = params.params;
    const letters = [
        "A",
        "B",
        "C",
        "D",
        "E"
    ];

    const [value, setValue] = useState(null);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        !value && alert('Para responder esta pergunta, você precisa marcar uma das opções!')

        value && api.post('ws/anwser-quizz.php', { id_user: idUser, user_type: tipoConta, id_question: id, answer: value })
            .then(res => {
                if (res.data.success) {
                    refreshData && refreshData();
                } else {
                    alert('Houve um erro ao responder essa pergunta!');
                    refreshData && refreshData();
                };
            })
            .catch(err => {
                alert('Erro: ' + err);
            });

    };

    const answerVerify = (right, wrong) => {
        if (answered && rigth_awnser == user_awnser) {
            return right;
        } else {
            return wrong;
        };
    };

    const styles = {
        radioGroup: { m: '1rem 0' },
        boxRadio: {
            borderRadius: 2,
            border: `2px solid ${answerVerify("green", "red")}`,
            p: '1rem'
        },
        divider: {
            m: '2rem 0',
            borderWidth: '1.5px'
        },
    }

    return (
        <Box m={'2rem 0'}>
            <Typography fontWeight={600}>
                {data}
            </Typography>
            <Typography>
                {HTMLReactParser(descricao)}
            </Typography>

            <form onSubmit={handleSubmit}>
                <FormControl variant="standard" fullWidth>
                    <RadioGroup
                        aria-labelledby="demo-error-radios"
                        name="quiz"
                        value={value}
                        sx={styles.radioGroup}
                        onChange={handleRadioChange}
                    >
                        {
                            answers.map((opt, index) => {
                                return (
                                    <FormControlLabel disabled={answered} value={index + 1} control={<Radio />} label={letters[index] + " - " + opt} key={index} />
                                );
                            })
                        }
                    </RadioGroup>
                    {
                        answered ?
                            <Box sx={styles.boxRadio}>
                                <Typography fontWeight={600} fontSize={'1.5rem'} color={answerVerify("green", "red")}>
                                    Você {answerVerify("acertou!", "errou!")}
                                </Typography>
                                <Typography>
                                    Resposta correta: <strong>{answers[rigth_awnser - 1]}</strong>
                                </Typography>
                            </Box>
                            :
                            <Button
                                size="large" fullWidth variant="contained" type="submit" onClick={() => handleSubmit()}>
                                Enviar resposta
                            </Button>
                    }
                </FormControl>
            </form>
            <Divider color={theme.palette.secondary.main} sx={styles.divider} />
        </Box>
    );
};