import { CardMedia, Skeleton } from "@mui/material";
import { useState } from "react";

export default function CustomImageHandler(props) {

    const { src, imageStyle, skeletonStyle = imageStyle } = props.props;

    const [image, setImage] = useState(false);
    const [visibility, setVisibility] = useState('none');

    const handleImageLoaded = () => {
        setImage(true);
        setVisibility('block');
    };

    return (
        <>
            {
                !image &&
                <Skeleton sx={{ ...skeletonStyle, }} />
            }
            <CardMedia
                onLoad={() => handleImageLoaded()}
                component="img"
                image={src}
                sx={{ ...imageStyle, display: visibility }}
            />
        </>
    )

}