const questions1 = [
  {
    question: "Onde você está?",
    options: [
      { id: "basico1_1", label: "sou", value: "sou" },
      { id: "basico1_2", label: "estou", value: "estou" },
      { id: "basico1_3", label: "está", value: "está" }
    ]
  },
  {
    question: "A senhora __________ aqui?",
    options: [
      { id: "basico2_1", label: "moro", value: "moro" },
      { id: "basico2_2", label: "moramos", value: "moramos" },
      { id: "basico2_3", label: "mora", value: "mora" }
    ]
  },
  {
    question: "Ele __________ inglês, alemão e espanhol.",
    options: [
      { id: "basico3_1", label: "fala", value: "fala" },
      { id: "basico3_2", label: "falam", value: "falam" },
      { id: "basico3_3", label: "falo", value: "falo" }
    ]
  },
  {
    question: "Eles ________ paulistas e moram _______ São Paulo.",
    options: [
      { id: "basico4_1", label: "estão, em", value: "estão, em" },
      { id: "basico4_2", label: "são, em", value: "são, em" },
      { id: "basico4_3", label: "são, no", value: "são, no" }
    ]
  },
  {
    question: "________ dia _______ noite, ________ reunião, _______ problema, _________ informação.",
    options: [
      { id: "basico5_1", label: "o, a, a, o, a", value: "o, a, a, o, a" },
      { id: "basico5_2", label: "o, o, a, a, a", value: "o, o, a, a, a" },
      { id: "basico5_3", label: "a, a, a, o, o", value: "a, a, a, o, o" }
    ]
  },
  {
    question: "Eu vou para o trabalho _______ carro. Ela vai _________ pé.",
    options: [
      { id: "basico6_1", label: "de, de", value: "de, de" },
      { id: "basico6_2", label: "de, a", value: "de, a" },
      { id: "basico6_3", label: "a, a", value: "a, a" }
    ]
  },
  {
    question: "Ele gosta ________ morar no centro.",
    options: [
      { id: "basico7_1", label: "(sem preposição)", value: "(sem preposição)" },
      { id: "basico7_2", label: "de", value: "de" },
      { id: "basico7_3", label: "no", value: "no" }
    ]
  },
  {
    question: "O Brasil _________ muitas cidades antigas.",
    options: [
      { id: "basico8_1", label: "tem", value: "tem" },
      { id: "basico8_2", label: "temos", value: "temos" },
      { id: "basico8_3", label: "é", value: "é" }
    ]
  },
  {
    question: "A: 'Muito Obrigada!'<br>B: '______________'.",
    options: [
      { id: "basico9_1", label: "muito prazer!", value: "muito prazer!" },
      { id: "basico9_2", label: "está bem!", value: "está bem!" },
      { id: "basico9_3", label: "de nada!", value: "de nada!" }
    ]
  },
  {
    question: "O filho dela ________ quatro anos.",
    options: [
      { id: "basico10_1", label: "tem", value: "tem" },
      { id: "basico10_2", label: "é", value: "é" },
      { id: "basico10_3", label: "está", value: "está" }
    ]
  }
];

const questions2 = [
  {
    question: "Estas casas são antigas. Gosto muito _________ .",
    options: [
      { id: "intermed1_1", label: "dela", value: "dela" },
      { id: "intermed1_2", label: "dele", value: "dele" },
      { id: "intermed1_3", label: "delas", value: "delas" }
    ]
  },
  {
    question: "Eu comprei um apartamento ________ bairro residencial.",
    options: [
      { id: "intermed2_1", label: "em", value: "em" },
      { id: "intermed2_2", label: "em um", value: "em um" },
      { id: "intermed2_3", label: "de um", value: "de um" }
    ]
  },
  {
    question: "Ela __________ ontem __________ 3 da tarde.",
    options: [
      { id: "intermed3_1", label: "chega, às", value: "chega, às" },
      { id: "intermed3_2", label: "chegou, de", value: "chegou, de" },
      { id: "intermed3_3", label: "chegou, às", value: "chegou, às" }
    ]
  },
  {
    question: "O filho dela _____________ na praça ___________ alguns amigos.",
    options: [
      { id: "intermed4_1", label: "estava, com", value: "estava, com" },
      { id: "intermed4_2", label: "estavas, com", value: "estavas, com" },
      { id: "intermed4_3", label: "estavam, com", value: "estavam, com" }
    ]
  },
  {
    question: "Quando ele ____________ criança, ele ____________ todos os dias.",
    options: [
      { id: "intermed5_1", label: "tinha, brincava", value: "tinha, brincava" },
      { id: "intermed5_2", label: "era, brincava", value: "era, brincava" },
      { id: "intermed5_3", label: "estava, brincava", value: "estava, brincava" }
    ]
  },
  {
    question: "Eu ___________ a informação ___________ livro __________ economia.",
    options: [
      { id: "intermed6_1", label: "encontrava, em um, da", value: "encontrava, em um, da" },
      { id: "intermed6_2", label: "encontrei, no, da", value: "encontrei, no, da" },
      { id: "intermed6_3", label: "encontrei, em um, de", value: "encontrei, em um, de" }
    ]
  },
  {
    question: "___________ aluno recebeu uma pasta.",
    options: [
      { id: "intermed7_1", label: "vários", value: "vários" },
      { id: "intermed7_2", label: "tudo", value: "tudo" },
      { id: "intermed7_3", label: "cada", value: "cada" }
    ]
  },
  {
    question: "Eu soube ___________ ele irá se mudar.",
    options: [
      { id: "intermed8_1", label: "de", value: "de" },
      { id: "intermed8_2", label: "que", value: "que" },
      { id: "intermed8_3", label: "para", value: "para" }
    ]
  },
  {
    question: "Nós ________ vimos na rua ontem. Ela estava com pressa.",
    options: [
      { id: "intermed9_1", label: "lhe", value: "lhe" },
      { id: "intermed9_2", label: "a", value: "a" },
      { id: "intermed9_3", label: "na", value: "na" }
    ]
  },
  {
    question: "Este hotel é ______________ !",
    options: [
      { id: "intermed10_1", label: "modernícimo", value: "modernícimo" },
      { id: "intermed10_2", label: "moderníssimo", value: "moderníssimo" },
      { id: "intermed10_3", label: "modernílimo", value: "modernílimo" }
    ]
  },
]

const questions3 = [
  {
    question: "Eu estou te pedindo. Não _________ barulho, por favor!",
    options: [
      { id: "avançado1_1", label: "faço", value: "faço" },
      { id: "avançado1_2", label: "faz", value: "faz" },
      { id: "avançado1_3", label: "faça", value: "faça" }
    ]
  },
  {
    question: "Ele te ____________ se ele ___________ .",
    options: [
      { id: "avançado2_1", label: "diria, soubesse", value: "diria, soubesse" },
        { id: "avançado2_2", label: "dizia, saberia", value: "dizia, saberia" },
      { id: "avançado2_3", label: "diria, saberia", value: "diria, saberia" }
    ]
  },
  {
    question: "Para que a sua mala _________ no armário, precisaremos tirar as caixas.",
    options: [
      { id: "avançado3_1", label: "cabe", value: "cabe" },
      { id: "avançado3_2", label: "cabia", value: "cabia" },
      { id: "avançado3_3", label: "caiba", value: "caiba" }
    ]
  },
  {
    question: "Vou te dar um mapa para que você não se ___________ .",
    options: [
      { id: "avançado4_1", label: "perca", value: "perca" },
      { id: "avançado4_2", label: "perda", value: "perda" },
      { id: "avançado4_3", label: "perde", value: "perde" }
    ]
  },
  {
    question: "Gosto do meu carro, embora ele não ___________ grande coisa.",
    options: [
      { id: "avançado5_1", label: "valhe", value: "valhe" },
      { id: "avançado5_2", label: "vale", value: "vale" },
      { id: "avançado5_3", label: "valha", value: "valha" }
    ]
  },
  {
    question: "É provável que ela ___________ .",
    options: [
      { id: "avançado6_1", label: "fica", value: "fica" },
      { id: "avançado6_2", label: "fique", value: "fique" },
      { id: "avançado6_3", label: "ficas", value: "ficas" }
    ]
  },
  {
    question: "Este jornal é ___________ por todo mundo.",
    options: [
      { id: "avançado7_1", label: "ledo", value: "ledo" },
      { id: "avançado7_2", label: "lido", value: "lido" },
      { id: "avançado7_3", label: "lê", value: "lê" }
    ]
  },
  {
    question: "O ladrão só foi ___________ ontem à noite.",
    options: [
      { id: "avançado8_1", label: "preso", value: "preso" },
      { id: "avançado8_2", label: "prendido", value: "prendido" },
      { id: "avançado8_3", label: "predendo", value: "predendo" }
    ]
  },
  {
    question: "Quando ele me pediu o relatório eu já tinha ___________ há tempos!",
    options: [
      { id: "avançado9_1", label: "entregue", value: "entregue" },
      { id: "avançado9_2", label: "entregado", value: "entregado" },
      { id: "avançado9_3", label: "entrego", value: "entrego" }
    ]
  },
  {
    question: "“Para bom entendedor, ________ palavra basta.”",
    options: [
      { id: "avançado10_1", label: "toda", value: "toda" },
      { id: "avançado10_2", label: "pouca", value: "pouca" },
      { id: "avançado10_3", label: "meia", value: "meia" }
    ]
  }
];

const partes = [
    {
        title: 'Basico',
        questions:questions1,
    },
    {
        title: 'Intermidiário',
        questions:questions2
    },
    {
        title:'Avançado',
        questions: questions3
    }
]


export default partes;