import { Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

export default function OurTeam() {

    const style = {
        boxOutside:{
            p: '2em 1em 5em 1em ',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        button:{ width: '80%' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' },
        imageButton:{
            width: {
                xs:'60%',
                sm:'25rem'
            },
            mb: '1.5em' 
        }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/our-team-title.png',
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }} />
            {/* <CardMedia component="img" image="https://gotheextramile.com.br/img/our-team-title.png" alt="" sx={style.cardMedia} /> */}
            <Typography textAlign={'center'} variant="h2">Conheça o grupo de professores<br></br>da Go Ahead</Typography>
            <Button sx={style.button} component={Link} to="/Team/1">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/go-on-time.png',
                    imageStyle:style.imageButton,
                    skeletonStyle:style.skeletonStyle
                }} />
            </Button>
            <Button sx={style.button} component={Link} to="/Team/2">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/go-on-line.png',
                    imageStyle:style.imageButton,
                    skeletonStyle:style.skeletonStyle
                }}/>
                {/* <CardMedia component="img" image="https://gotheextramile.com.br/img/go-on-line.png" alt="" /> */}
            </Button>
            <Button sx={style.button} component={Link} to="/Team/3">
                <CustomImageHandler props={{
                    src:'https://gotheextramile.com.br/img/go-kids-go-teens.png',
                    imageStyle:style.imageButton,
                    skeletonStyle:style.skeletonStyle
                }}/>
                {/* <CardMedia component="img" image="https://gotheextramile.com.br/img/go-kids-go-teens.png" alt="" /> */}
            </Button>
        </Box>
    )

}