import { AttachMoney, Extension, Home, Person, QuestionMark, Shield, TouchApp } from "@mui/icons-material";

const navData = [
    {
        id: 1,
        tipo: [1, 2, 3],
        path: '/EditProfile',
        name: 'Meu Cadastro',
        icon:
            <Home />
    },
    {
        id: 2,
        tipo: [1, 2, 3],
        path: '/MinhaAgenda',
        name: 'Minha Agenda',
        icon:
            <Person />
    },
    {
        id: 3,
        tipo: [1, 3],
        path: '/Financeiro',
        name: 'Financeiro',
        icon:
            <AttachMoney />

    },
    {
        id: 4,
        tipo: [1, 3],
        path: '/TapAndLearn',
        name: 'Tap and Learn',
        icon:
            <TouchApp />

    },
    {
        id: 5,
        tipo: [1, 2, 3],
        path: '/BibliotecaDeAtividades/English',
        name: 'Biblioteca de Atividades English',
        icon:
            <Extension />
    },
    {
        id: 6,
        tipo: [1, 2, 3],
        path: '/BibliotecaDeAtividades/Español ',
        name: 'Biblioteca de Atividades Espanõl',
        icon:
            <Extension />

    },
    {
        id: 7,
        tipo: [1, 2, 3],
        path: '/IdiomPhrasalVerb',
        name: 'Idiom & Phrasal Verb of the dDy',
        icon:
            <Extension />
    },
    {
        id: 8,
        tipo: [1, 2, 3],
        path: '/Faq',
        name: 'FAQ',
        icon:
            <QuestionMark />

    },
    {
        id: 9,
        tipo: [1, 2, 3],
        path: '/PrivacyPolicies',
        name: 'Política de Privacidade',
        icon:
            <Shield />

    }
];

export default navData;