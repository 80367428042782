import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserProvider";
import api from "../services/api";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import CustomImageHandler from "../components/CustomImageHandler";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { motion } from "framer-motion";

const ProgressTestList = ()=>{
    const {user } = useContext(UserContext)
    
    const [loading, setLoading] = useState(true)
    const [progressTestList, setProgressTestList] = useState([])

    useEffect(()=>{
        setLoading(true)
        api.get(`ws/progress-test-list.php?id=${user['idalunos']}` )
            .then((res)=>{
                if(res.data.success)
                    setProgressTestList(res.data.progressTests)
                else alert('Houve um erro! Tente novamente ou contate o administrador!');
            }).finally(()=>{
                setLoading(false)
            })
    }, [])

    const styles ={
        boxOutside:{
            p: '2em 1em',
            display: 'flex',
            justifyData: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            pb: '10vh'
        },
        boxLoading:{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' },
        button:{
            justifyContent: "space-between",
            mt: '1.5em',
            borderWidth: '2px',
        },
        boxButton:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexDirection: 'column',
        },
        circularProgress:{ m: 'auto' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={styles.boxOutside}>
            <CustomImageHandler props={{ src: 'https://gotheextramile.com.br/img/progress-tests.png', imageStyle: styles.imageStyle, skeletonStyle:styles.skeletonStyle }} />
            <Typography fontWeight='bold' textAlign='center'>
                Acompanhe a evolução dos seus conhecimentos em cada nível com os nossos Progress Tests!
                Ao final do teste, confira as respostas e a % de acertos. Good luck!
            </Typography>
            {
                loading ?
                    <Box sx={styles.boxLoading}>
                        <CircularProgress size={'3em'} color="primary" sx={styles.circularProgress} />
                    </Box>
                    :
                    <Box width='100%'>
                    {progressTestList.map((obj, index) => {
                            return (
                                <Box component={motion.div}
                                item xs={6} key={index}
                                initial={{ opacity: 0, scale: 0.9 }}
                                whileInView={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.25 }} >
                                    <Button
                                    component={Link}
                                    key={index}
                                    to={`/ProgressTest/${obj.id}`}
                                    variant="outlined"
                                    fullWidth
                                    endIcon={<ArrowForwardIos />}
                                    sx={styles.button}>
                                        <Box sx={styles.boxButton}>
                                            <Typography variant="h2">
                                                {obj.titulo}
                                            </Typography>
                                            <Typography>
                                                <strong>{obj.descricao} - {obj.tipo}</strong>
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Box>
                            )
                        })
                    }
                    </Box>
            }
        </Box>
    )
}


export default ProgressTestList;