import { Box, CircularProgress, Modal, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import api from "../services/api";
import { UserContext } from "../context/UserProvider";

import './LearningRoute.css'

const LearningRoute = ()=>{
    const {idTurma} = useParams();
    const { idUser, tipoConta } = useContext(UserContext)

    //controle
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen ] = useState(false)
    //dados learning route
    const [ idYourMiles, setIdYourMiles ] = useState('')
    const [ nivelTurma, setNivelTurma ] = useState('')
    const [ idIdiomaTurma, setIdIdiomaTurrma ] = useState('')
    const [ nivelAtual, setNivelAtual ] = useState('')
    const [ nivelAtualCor, setNivelAtualCor ] = useState('')
    const [ ordenacaoNivel, setOrdenacaoNivel ] = useState('')
    const [ totalHoras, setTotalHoras ] = useState(0)
    const [ nivelProximo, setNivelProximo ] = useState('')
    const [ nivelProximoCor, setNivelProximoCor ] = useState('')
    const [ porcentagem, setPorcentagem] = useState(0)
    const [ formatoAula, setFormatoAula ] = useState('')
    const [ msg, setMensagem ] = useState('')


    useEffect(()=>{
        const obj_send = {
            idAluno:idUser,
            userType:tipoConta
        }

        setIsLoading(true)
        api.post('/ws/learning-route.php', obj_send)
        .then((res)=>{
            if(res.data.success){
                const data = res.data.data
                setData(data)
            }
        }).catch((err)=>{
            alert('Houve um erro! Tente novamente ou contate o administrador!');
        }).finally(()=>{setIsLoading(false)})
    }, [])


    const setData = (data)=>{
        setIdYourMiles(data.idYourMiles)
        setNivelTurma(data.nivel_turma)
        setIdIdiomaTurrma(data.idioma_turma)
        setNivelAtual(data.nivel_atual)
        setNivelAtualCor(data.nivel_atual_cor)
        setOrdenacaoNivel(data.ordenacao_nivel_turma_atual)
        setTotalHoras(data.total_horas_realizadas)
        setNivelProximo(data.nivel_proximo)
        setNivelProximoCor(data.nivel_proximo_cor)
        // setPorcentagem(data.porcentagem)
        setPorcentagem(76.25)
        setFormatoAula(data.formatoAula)
        setMensagem(()=>{
            if(data.mnsg !== '')
                setOpen(true)
            setMensagem(data.mnsg)
        })
    }
    
    const handleClose = ()=>{setOpen(false)}

    const style = {
        modalMsg:{
            zIndex: 2100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        boxOutside:{
        },
        boxModal:{
            width: '40vw',
            // bgcolor: 'white',
            bgcolor: nivelAtualCor,
            boxShadow: 24,
            borderRadius: '8px',
            p: '1rem',
            overflow: 'auto',
            maxHeight: '75vh',
            alignItems:'center',
        },
        '.st0':{
            fill:'#FEFEFE'
        },
        '.st1':{
            fill:'#373435'
        },
        porcentagem:{
            color: "#FFF",
            fontWeight: '600',
            fontSize: '20px',
            fontFamily:'sans-serif'
        },
        divBaloon:{
            visibility: 'visible',
            animationDuration: '2s',
            animationName: 'slideInUp',
            height:'40px'
        },
        ballonMain:{
            fill:nivelAtualCor,
        },
    }


    return (
        <Box  sx={style.boxOutside} >
            {isLoading? <CircularProgress />:
             
            <div id='learning-route'>
                <div className='line1'>
                    <p style={{
                        color: "#FFF", 
                        fontWeight: '600',
                        right: '-60px', 
                        top: '5px', 
                        position: 'relative', 
                        fontSize: '20px', 
                        textAlign: 'right', 
                        fontFamily:'sans-serif'
                    }}>100%</p>
                    <p style={{color: '#FFF', fontWeight: '600', bottom: '0', left: '-30px', position: 'absolute', fontSize: '20px'}}>0%</p>
                </div>
                <div className="line2" style={{height: `${porcentagem}%`}}>
                </div>		
                {/* https://gotheextramile.com.br/img/appIcons/assessment-plus.png */}
                {formatoAula == 'GoKids'?
                    <img
                    src="https://gotheextramile.com.br/img/learning-route/sunny.png"
                    className="wow rotateIn sol"
                    data-wow-duration="3s"></img>
                :
                    <img
                    src="https://gotheextramile.com.br/img/learning-route/sol.png"
                    data-wow-duration="3s"
                    className="wow rotateIn sol"
                    style={{visibility: 'visible', animationDuration: '3s', animationName: 'rotateIn'}}></img>
                }
                <img
                src="https://gotheextramile.com.br/img/learning-route/nuvem1.png"
                data-wow-duration="2.8s"
                className="nuvem1"
                style={{visibility: 'visible', animationDuration: '2.8s', animationName: 'zoomIn' }}></img>
                <div
                className="proximo-nivel"
                style={{backgroundColor: nivelProximoCor}}>
                        <span>{nivelProximo}</span>
                </div>
                <img src="https://gotheextramile.com.br/img/learning-route/nuvem2.png" className="nuvem2" />
                <div
                className="nivel-atual"
                style={{backgroundColor: nivelAtualCor}}>
                        <span>{nivelAtual}</span>
                </div>	

                <div
                style={{
                    bottom: `${porcentagem+113}%`
                }}
                className="balloon wow slideInUp"
                data-wow-duration="2s">
                    <Typography sx={style.porcentagem}
                    variant="p">{porcentagem}%</Typography>
                    <svg
                    version="1.1"
                    id="Camada_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="-17 -36 191.4 257.8"
                    style={{enableBackground: 'new -17 -36 191.4 257.8'}}
                    space="preserve"
                    >
                    <path style={{fill:'#FEFEFE'}} d="M78.7,209h-3.1C67,209,60,202,60,193.5c0-10.8,0,2.1,0-8.7c0-2.2,1-4.2,2.7-5.5c1.7-1.3,1.6-0.8,0.7-3.1
                        l-13-35.3c-1.2-3.2-1-3.5-4.1-4.8C16.6,123.5-4.2,94-4.2,59.7c0-45.8,37.2-82.9,83-82.9l0,0l0,0c45.8,0,82.9,37.1,82.9,82.9
                        c0,34.3-20.8,63.8-50.5,76.4c-3.1,1.3-2.9,1.6-4.1,4.8l-13,35.3c-0.9,2.3-1,1.8,0.7,3.1c1.6,1.2,2.7,3.3,2.7,5.5c0,10.8,0-2.1,0,8.7
                        c0,8.6-7,15.6-15.6,15.6H78.7z M69.5,177.9h5.4c2.4,0,2.6,0,2.6-2.4v-29.2c0-3.5,0.2-3.7-3.3-3.8c-6.5-0.3-12.9-1.4-19-3.3
                        c-3.1-1-2.4,0.5-1.5,2.6l12.3,33.6C66.8,177.7,67,177.9,69.5,177.9z M78.7,7.3L78.7,7.3c-29,0-52.5,23.5-52.5,52.4
                        c0,29,23.5,52.4,52.5,52.4l0,0l0,0c29,0,52.4-23.5,52.4-52.4C131.2,30.8,107.7,7.3,78.7,7.3L78.7,7.3z M88,177.9h-5.4
                        c-2.3,0-2.5,0-2.5-2.4v-29.2c0-3.5-0.2-3.7,3.3-3.8c6.6-0.3,13-1.4,19-3.3c3.1-1,2.4,0.5,1.5,2.6l-12.3,33.6
                        C90.7,177.7,90.4,177.9,88,177.9z"></path>
                    <path style={{fill:'#FEFEFE'}} d="M78.7,221.8h-3.1c-7.8,0-14.9-3.2-20-8.3c-5.1-5.1-8.3-12.2-8.3-20v-8.7c0-3.1,0.7-6,2-8.7
                        c0.1-0.2,0.2-0.4,0.3-0.6L39,146.8c-16.4-7.5-30.3-19.4-40.1-34.3C-11.2,97.3-17,79.2-17,59.7C-17,33.3-6.3,9.4,11-8
                        s41.2-28,67.7-28l0,0l0,0c26.4,0,50.3,10.7,67.7,28s28,41.2,28,67.7c0,19.5-5.8,37.6-15.8,52.8c-9.9,14.9-23.8,26.8-40.2,34.3
                        l-10.6,28.8c0.1,0.2,0.2,0.4,0.3,0.6c1.3,2.6,2,5.6,2,8.7v8.7c0,7.8-3.2,14.9-8.3,20c-5.1,5.1-12.2,8.3-20,8.3H78.7z M75.6,196.3
                        h3.1h3.1c0.8,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2v-2.8h-2.1c-1.5,0-2.7,0-3.9-0.2c-1.2,0.2-2.4,0.2-3.9,0.2h-2v2.8
                        c0,0.8,0.3,1.4,0.8,2C74.1,196,74.8,196.3,75.6,196.3z"></path>
                    <path style={style.ballonMain} d="M78.7-23.2c-45.8,0-83,37.1-83,82.9c0,34.3,20.9,63.8,50.6,76.4c3.1,1.3,2.9,1.6,4.1,4.8l13,35.3
                        c0.9,2.3,1,1.8-0.7,3.1c-1.6,1.2-2.7,3.3-2.7,5.5c0,2.2,0,3.4,0,4c0,0,0,0.2,0,0.6c0,0.6,0,1.8,0,4c0,8.6,7,15.6,15.6,15.6h3.1h3.1
                        c8.6,0,15.6-7,15.6-15.6c0-2.2,0-3.4,0-4c0,0,0-0.2,0-0.6c0-0.6,0-1.8,0-4c0-2.2-1.1-4.2-2.7-5.5c-1.7-1.3-1.5-0.8-0.7-3.1l13-35.3
                        c1.2-3.2,1-3.5,4.1-4.8c29.7-12.6,50.5-42.1,50.5-76.4C161.7,13.9,124.5-23.2,78.7-23.2z M77.4,146.4v29.2c0,2.3-0.2,2.4-2.6,2.4
                        h-5.4c-2.4,0-2.7-0.2-3.5-2.5l-12.3-33.6c-0.9-2.1-1.6-3.6,1.5-2.6c6,1.9,12.4,3,19,3.3C77.5,142.7,77.4,142.8,77.4,146.4z
                            M103.9,141.9l-12.3,33.6c-0.9,2.3-1.1,2.5-3.5,2.5h-5.4c-2.3,0-2.5,0-2.5-2.4v-29.2c0-3.5-0.2-3.7,3.3-3.8c6.6-0.3,13-1.4,19-3.3
                        C105.5,138.2,104.7,139.8,103.9,141.9z M78.7,112.1c-29,0-52.5-23.5-52.5-52.4c0-28.9,23.5-52.4,52.5-52.4s52.4,23.5,52.4,52.4
                        C131.2,88.7,107.7,112.1,78.7,112.1z"></path>
                    </svg>

                </div>
                <img
                src="https://gotheextramile.com.br/img/learning-route/nuvem3.png"
                className="wow zoomIn nuvem3"
                data-wow-duration="2s" />
                <img
                src="https://gotheextramile.com.br/img/learning-route/nuvem4.png"
                className="wow zoomIn nuvem4"
                data-wow-duration="2.8s"/>
            </div>
            }
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={style.modalMsg}>
                <Box sx={style.boxModal}>
                    <Typography variant="p">{msg}</Typography>
                </Box>
            </Modal>
        </Box>
    )
}


export default LearningRoute