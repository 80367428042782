import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import ReactHtmlParser from 'html-react-parser';

import TOEIC from '../data/testeTOEIC'
import TOELF from "../data/testeTOEFL"
// import IELTS from "../data/testeIELTS"

import { Box, Button, FormControl, Typography } from "@mui/material"
import QuestionNivelamentoHandler from "../components/QuestionNivelamentoHandler"
import useTesteNivelamento from "../hooks/useSendTesteNivelamento"

const ProficiencyQuiz = () => {
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento()

    const [partes, setPartes] = useState([])
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [answers, setAnswers] = useState([]);
    const { nome } = useParams()


    useEffect(() => {
        if (nome === 'toeic') {
            setPartes(TOEIC)
            setTitle('TOEIC CHALLENGE')
            setMessage('Que tal uma prévia de como é o teste TOEIC? Segue abaixo uma amostra de questões de gramática.\nGood luck!')
        } else if (nome === 'toefl') {
            setPartes(TOELF)
            setTitle('TOEFL TEST')
        } else if (nome === 'ielts') {
            setPartes(TOELF)
            setTitle('IELTS TEST');
        };
    }, [nome])

    useEffect(() => {
        setAnswers(() => {
            var valueAnsers = [];
            partes.forEach((pt) => {
                var array_answers = []
                if (pt?.questions) {
                    console.log('pt', pt)
                    pt.questions.forEach(() => {
                        array_answers.push(0)
                    })
                }
                valueAnsers.push(array_answers)
            })
            return valueAnsers
        })
        console.log('effect', answers)
    }, [partes])

    const handleSubmit = () => {
        sendTest(partes, answers, nome.toUpperCase())
    }

    const renderQuestions = (questions, index) => {
        if (questions)
            return (
                <QuestionNivelamentoHandler
                    questions={questions}
                    answerHandler={handleAnswer(index, setAnswers)} />
            )
    }

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        boxInside: { width: '100%', pt: '1.5em' },
        imageStyle: { width: '60%', mb: '1.5rem' },
        skeletonStyle: {
            width: '60%',
            mb: '1.5em',
            height: '11rem'
        },
        boxLoading: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '1rem'
        },
        boxSemAtividade: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '1rem'
        },
        divider: {
            m: '2rem 0',
            borderWidth: '1.5px'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h1">{title}</Typography>
            <Typography>{message}</Typography>
            <FormControl >
                {partes.length > 0 &&
                    partes.map((pt, i) => (
                        <Box key={i}>
                            <Typography variant="p">
                                {ReactHtmlParser(`${pt.title}`)}
                            </Typography>
                            {renderQuestions(pt.questions, i)}
                        </Box>
                    ))
                }
                <Button
                    variant="contained"
                    size="large"
                    type='submit'
                    disabled={formSent}
                    onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}


export default ProficiencyQuiz