import { useContext } from "react";
import { UserContext } from "../context/UserProvider";
import Login from "../screens/Login";

export default function RouteWrapper({ loggedComponent, defaultComponent, isPrivate }) {

    const { user } = useContext(UserContext);

    if (user && !isPrivate) {
        return defaultComponent //direciona para página privada.
    } else if (!user && isPrivate) {
        return defaultComponent  //direciona para página inicial.
    } else {
        return user ? loggedComponent : defaultComponent
    }
}