import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, CardMedia, CircularProgress, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
import { motion } from "framer-motion";
import api from "../services/api";
import WOW from 'wowjs';
import './TapAndLearn.css';

export default function TapAndLearn() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { idUser, tipoConta } = useContext(UserContext);

    useEffect(() => {
        api.post(`/ws/tap-and-learn-atividades.php?user_type=${tipoConta}&user_id=${idUser}`)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.atividades);
                    setIsLoading(false);
                } else {
                    alert('Houve um erro na conexão, tente novamente mais tarde!');
                    setIsLoading(false);
                }
            })
            .catch(err => {
                alert('Houve um erro na conexão, tente novamente mais tarde!');
                setIsLoading(false);
            });

        new WOW.WOW({
            live: false
        }).init();
    }, []);

    const styles = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            pb: '10vh'
        },
        boxLoading: { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' },
        button: {
            justifyContent: "space-between",
            mt: '1.5em',
            borderWidth: '2px',
        },
        boxButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            flexDirection: 'column',
            p: '.5rem'
        },
        circularProgress: { m: 'auto' },
        imageStyle: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' }
    };

    return (
        <Box sx={styles.boxOutside}>
            <div id="tap-and-learn-logo">
                <img src="https://gotheextramile.com.br/img/tap-and-learn/tap.png" alt="" class="tap wow bounceIn" data-wow-delay="0.3s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/and.png" alt="" class="and wow bounceIn" data-wow-delay="0.4s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/learn.png" alt="" class="learn wow bounceIn" data-wow-delay="0.5s" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/splash.png" alt="" class="splash wow tada" />
                <img src="https://gotheextramile.com.br/img/tap-and-learn/mao.png" alt="" class="mao wow bounceIn" data-wow-delay="0.15s" />
            </div>
            <Typography fontWeight='bold' mt={'2rem'} textAlign='center'>
                O T&L é uma forma rápida e eficaz de você praticar o seu idioma, aumentando o seu conhecimento através de atividades pontuais e objetivas. É só você clicar, responder e aprender!
            </Typography>
            {
                isLoading ?
                    <Box sx={styles.boxLoading}>
                        <CircularProgress size={'3em'} color="primary" sx={styles.circularProgress} />
                    </Box>
                    :
                    <Box width='100%'>
                        {data.map((obj, index) => {
                            return (
                                <Box component={motion.div}
                                    item xs={6} key={index}
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.25 }}
                                >
                                    <Button
                                        component={Link}
                                        key={index}
                                        to={`/Quiz/${obj.id}`}
                                        variant="outlined"
                                        fullWidth
                                        endIcon={<ArrowForwardIos />}
                                        sx={styles.button}>
                                        <Box sx={styles.boxButton}>
                                            <Typography variant="h2">
                                                {obj.titulo}
                                            </Typography>
                                            <Typography mt={'.25rem'} component={'i'} fontSize={'.85rem'}>
                                                Idioma: <strong>{obj.idioma}</strong>
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Box>
                            )
                        })}
                    </Box>
            }
        </Box>
    )

}