import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

export default function PlanosDeCurso() {

    const style = {
        boxOutside:{
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            height: '90vh',
        },
        cardMedia:{ width: '60%', mb: '1.5em' },
        grid:{ width: '100%' },
        imageStyle:{
            width: {
                xs:'12rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/planos-de-curso-title.png',
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }} />
            <Typography mb='1em' textAlign={'center'}>
                <strong>Clique aqui para ver os idiomas e seus planos de curso</strong>
            </Typography>

            <Grid container spacing={1} sx={style.grid} justifyContent="center">
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/PlanosDeCursoIngles" >English</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/PlanosDeCursoEspanhol" >Español</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/PlanosDeCursoGoKids" >Go Kids</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant="contained" color="secondary" component={Link} to="/PlanosDeCursoGoTeens" >Go Teens</Button>
                </Grid>
            </Grid>
        </Box>
    )
}