import React, { useState } from "react";
import * as yup from 'yup';
import { useFormik } from "formik";
import api from "../services/api";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../context/UserProvider";
import { Box, Button, Card, CardMedia, Checkbox, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

const validationSchema = yup.object({
  email: yup
    .string('Insira seu email ou user')
    .required('Email é um campo necessário')
});

export default function ResetPassword() {

  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => { executeReset(formik.values.email) }
  });

  async function executeReset(email) {
    setIsLoading(true);
    await api.get('ws/reset-password?user=' + email)
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.msg);
        } else {
          setSuccessMessage('Enviamos para seu email o link de redefinição de senha. Verifique na sua caixa de email ou spam.');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setErrorMessage('Erro na conexão, tente novamente mais tarde!');
        setIsLoading(false);
      });
  };

  const styles = {
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: theme.palette.primary.main
    },
    card: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      p: '2rem',
      m: '1rem'
    },
    image: {
      width: '80%',
      height: 'auto'
    },
    skeleton: {
      width: '90%',
      height: '6rem'
    }
  }

  return (
    <Box sx={styles.container}>
      <Card sx={styles.card}>
        <CustomImageHandler props={{ src: 'https://gotheextramile.com.br/img/logo2.png', imageStyle: styles.image, skeletonStyle: styles.skeleton }} />
        <Box sx={{ width: '100%' }} component={'form'} onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email ou Usuário"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{ shrink: true }}
            sx={{
              mt: '2rem'
            }}
          />
          {
            isLoading ?
              <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '1rem' }}>
                <CircularProgress size={'2em'} color="primary" sx={{ m: 'auto' }} />
              </Box>
              :
              <Button color="secondary" variant="contained" fullWidth type="submit" sx={{
                mt: '1rem',
              }}>
                Enviar email
              </Button>
          }
          {
            errorMessage &&
            <Typography textAlign='center' color='red' mt='1rem'>
              {errorMessage}
            </Typography>
          }
          {
            successMessage &&
            <Typography textAlign='center' color='green' mt='1rem'>
              {successMessage}
            </Typography>
          }
          <Button component={Link} to="/" variant="text" fullWidth type="submit" sx={{
            mt: '1rem',
          }}>
            Voltar para login
          </Button>
        </Box>
      </Card>
    </Box>
  );
};