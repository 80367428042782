import { HashRouter, Route, Routes } from 'react-router-dom';
import RouteWrapper from './components/RouteWrapper';
import ComponentProvider from './context/ComponentProvider';
import NotificationsProvider from './context/NotificationsProvider';

import routes from './data/Rotas';
import Layout from './components/layout';

function Router() {
  return (
    <HashRouter>
      <NotificationsProvider>
        <ComponentProvider>
          <Routes>
            {
              routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <RouteWrapper
                        isPrivate={route.isPrivate}
                        loggedComponent={<Layout>{route.loggedComponent}</Layout>}
                        defaultComponent={<Layout>{route.defaultComponent}</Layout>} />
                    } />
                )
              })
            }
          </Routes>
        </ComponentProvider>
      </NotificationsProvider>
    </HashRouter>
  );
};

export default Router;