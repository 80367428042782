import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Card, CardMedia, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";
import ReactHtmlParser from 'html-react-parser';
import CustomImageHandler from "../components/CustomImageHandler";

export default function JournalPost() {

    const [post, setPost] = useState([]);
    const [message, setMessage] = useState('');

    const { id } = useParams();

    useEffect(() => {
        api.post(`ws/journal-post.php?id=${id}`)
            .then(res => {
                if (res.data.success) {
                    setPost(res.data.journals[0]);

                } else {
                    setMessage('Houve um erro na conexão, tente novamente mais tarde!');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Houve um erro na conexão, tente novamente mais tarde!');
            })
    }, [])


    const style = {
        boxOutside:{
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
            p: '2em 1em 5em 1em ',
        },
        button:{
            width: '75%',
            mt: '1em',
            textTransform: 'uppercase'
        },
        iframe:{
            height:'fit-content',
            width:'fit-content'
        },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <Box>
                {
                    post?.source? 
                        <CardMedia component='iframe' sx={style.iframe} src={post.source} />
                    :
                        <CustomImageHandler props={{
                            src:`https://goaheadschool.com.br/img/gonews/${id}.jpg`,
                            imageStyle:style.imageStyle,
                            skeletonStyle:style.skeletonStyle
                        }} />
                }
                <Typography variant="h2" mt='1.5em'>
                    {post.titulo}
                </Typography>
                <Typography mt='1.5em'>
                    {post?.txt && ReactHtmlParser(`${post.txt}`)}
                </Typography>
            </Box>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/Journal'} sx={style.button}>
                Voltar
            </Button>
        </Box>
    )
}