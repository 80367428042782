import * as React from 'react';
import Box from '@mui/material/Box';
import { CalendarMonth, Home, Nature, NotificationsNone, Person, TouchApp } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { ComponentContext } from '../context/ComponentProvider';
import { UserContext } from '../context/UserProvider';
import { Link, useLocation } from 'react-router-dom';
import OnlyToSignedUsers from './OnlyToSignedUsers';

export default function Menu() {

    const { changeDrawer } = React.useContext(ComponentContext);

    const { user } = React.useContext(UserContext);

    const route = useLocation();

    const colorByRoute = (path) => {
        return path === route.pathname ? "secondary" : "primary"
    };
    // console.log('route', route)

    const styles = {
        boxOutside: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexDirection: 'row',
            bgcolor: 'white',
            zIndex: 1500,
        }
    }

    return (
        <OnlyToSignedUsers component={
            <Box sx={styles.boxOutside}>
                <IconButton
                    size="large"
                    key={'Home'}
                    component={Link}
                    to={'/'}>
                    <Home fontSize='2em' color={colorByRoute('/')} />
                </IconButton>
                <IconButton
                    size="large"
                    key={'Calendar'}
                    component={Link}
                    to={'/MinhaAgenda'}>
                    <CalendarMonth fontSize='2em' color={colorByRoute('/MinhaAgenda')} />
                </IconButton>
                <IconButton
                    size="large"
                    key={'Tap & Learn'}
                    component={Link}
                    to={'/TapAndLearn'}>
                    <TouchApp fontSize='2em' color={colorByRoute('/TapAndLearn')} />
                </IconButton>
                <IconButton
                    size="large"
                    key={'Hang Man'}
                    component={Link}
                    to={'/HangMan'}>
                    <Nature fontSize='2em' color={colorByRoute('/HangMan')} />
                </IconButton>
                <IconButton
                    size="large"
                    key={'Menu'}
                    onClick={() => changeDrawer(true)}>
                    <Person fontSize='2em' color='primary' />
                </IconButton>
            </Box>
        } />
    );
}
