import { Box, IconButton, Modal, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import HTMLReactParser from 'html-react-parser';
import { Close } from "@mui/icons-material";

const IdiomPhrasalVerbModal = ({ content }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(true);
    }, [content])

    const handleClose = () => setOpen(false);

    const styles = {
        modal: {
            zIndex: 2100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        box: {
            position: 'absolute',
            width: '85vw',
            bgcolor: 'white',
            boxShadow: 24,
            borderRadius: '8px',
            p: '1rem',
            height: '90vh',
            overflow: 'scroll'
        },
        titulo: {
            padding: '20px',
            fontSize: '20px'
        },
        text: {
            textDecorationColor: 'purple',
            color: 'GrayText',
            fontSize: '1rem',
            b: {
                color: 'black'
            },
            a: {
                color: '#0dd3f8'
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={styles.modal}>
            <Box sx={styles.box}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <IconButton onClick={handleClose} sx={{ position: 'sticky' }}>
                        <Close />
                    </IconButton>
                </Box>
                <Typography variant="h1">Idiom & Phrasal Verb of the Day</Typography>
                <Typography
                    sx={styles.titulo}
                    variant="h2">
                    {content.titulo}
                </Typography>
                <Typography
                    variant="p"
                    sx={styles.text}>
                    {HTMLReactParser(`${content.texto}`)}
                </Typography>
            </Box>
        </Modal>

    )
}

export default IdiomPhrasalVerbModal