import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function PlanosDeCursoGoKids() {
    const styles = {
        boxOutside: {
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5rem'
        },
        button:{
            width: '75%',
            mt: '1em',
            textTransform: 'uppercase'
        },
        accordion:{
            width:'100%',
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component={'img'} image="https://gotheextramile.com.br/img/planos-de-curso-title.png" alt="" sx={styles.cardMedia} />
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Box>
                        <Typography fontWeight={'bold'} fo>GO KIDS - GENERAL ENGLISH</Typography>
                        <Typography>Inglês para crianças de 2 a 6 anos</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography fontWeight="bold">Little Kids 1 </Typography>
                    <br />
                    <Typography><strong>Material:  My First English Adventure 1 (Livro + CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Identificar as cores blue, red, yellow, brown, green, orange / It’s (red) / What color?  <br />
                        • Cumprimentar e despedir-se (Hello. Bye-bye.)  <br />
                        • Identificar pessoas (I’m ... This is… Who’s this? It’s my…)  <br />
                        • Seguir as instruções look (at the board/book/teacher), let’s (sing, play, dance), pick up, show me, touch, stand up, sit down, circle, open/close (your book), rub (your head), point to, dance (with me), turn around, go, stop, raise (your hand), put (your hand) down, make (a line)  <br />
                        • Identificar objetos (glue, crayon, paper, backpack, book, pencil)  <br />
                        • Identificar as partes do corpo arms, head, legs, body, face, hair / I can touch my…  <br />
                        • Identificar os membros da família baby, dad, mom, grandma, grandpa / Who’s this? It’s my (mom)  <br />
                        • Identificar os animais bird, dog, mouse, butterfly, fish, rabbit / It’s a mouse / What do you see? I see (a dog)  <br />
                        • Identificar os brinquedosball, teddy bear, train, doll, drum, kite / my (teddy bear) / It’s a (teddy bear) / I see (two teddy bears)  <br />
                        • Identificar vocabulário de comida apple, banana, cookie, bread, cupcake, milk / It’s a (cookie) / I see (one banana)  <br />
                        • Utilizar Yes / No  <br />
                        • Utilizar os números one, two, three, four  <br />
                        • Perguntar como se diz (How do you say?)  <br />
                        • Utilizar please para pedidos: Come here please / Give me (the apple), please / Repeat, please.  <br />
                        • Agradecer usando thank you / You’re welcome  <br />
                        • Pedir algo emprestado (can I borrow)  <br />
                        • Usar vocabulário referente a festas de aniversário (balloon, cake, present, happy birthday  <br />
                        • Compreender a expressão Let’s (make a costume/ use crayons)  <br />
                        • Usar a expressão What fun!  <br />
                        • Responder à pergunta How old are you? com I’m (four)  <br />
                        • Responder a perguntas sobre objetos: Is it (the cake?)
                    </Typography> <br />

                    <Typography fontWeight="bold">Little Kids 2 </Typography>
                    <br />
                    <Typography><strong>Material:  My First English Adventure 2 (Livro + CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Identificar as cores blue, red, yellow, brown, green, black, pink, white / This is (red)  <br />
                        • Cumprimentar e despedir-se (Hello. Bye-bye.) <br />
                        • Identificar pessoas (I’m ...)Identificar as formas circle, rectangle, square, triangleSeguir as intruções point to (red), show me (blue circles), find (yellow), paint, draw a line, touch (red), stand up, sit down, circle, open/close (your book / your eyes), dance (with me), turn around, go, stop, raise (your hand), put (your hand) down, connect the dots, move (your arms), shake, pat, listen, sing, hop, don’t cry, catch the ball, clean up, let’s go, go to the (board/window/door) <br />
                        • Identificar objetos (glue, crayon, paper, scissors) <br />
                        • Identificar as partes da casa e móveis bathroom, bedroom, house, living room, bathtub, bed, sofa, chair, door, window / This is (the bedroom) / Here’s (the bed) / (Mom) is in the (living room)  <br />
                        • Identificar as partes do corpo arms, head, legs, body, eyes, mouth, nose / This is my (head)  <br />
                        • Identificar os membros da família baby, dad, mom, brother, sister, friend(s) / Who’s this? It’s my (mom) / Is it (the mom)? It’s (the mom) / Is this (the sister)? <br />
                        • Identificar os animais cow, bird, butterfly, fish, rabbit, cat frog, horse / It’s a (fish) / my (rabbit) / I’m a (butterfly) / This is (a bird) / I see (two butterflies)  <br />
                        • Identificar os brinquedos ball, teddy bear, train, drum, boat, hula hoop, jump rope / Look, a (train) for you/me / Give me (the big train) /Here’s your teddy bear  <br />
                        • Identificar vocabulário de comida apple, banana, cookie, milk, cheese, chicken, water / I see (two apples / apples on the tree) / My mom has two apples <br />
                        • Utilizar Yes / No <br />
                        • Utilizar os números one, two, three, four <br />
                        • Utilizar please para pedidos <br />
                        • Compreender a expressão Let’s get crayons / Let’s pick colors / Let’s clean the living room <br />
                        • Identificar árvores (apple tree, banana tree) <br />
                        • Compreender as perguntas How about you? Who/What do you see? <br />
                        • Utilizar as expressões May I go to the bathroom? / I don’t understand / How do you say ______? / Repeat, please <br />
                        • Falar sobre habilidades: I can fly / move like (a butterfly) <br />
                        • Compreender vocabulário de contos de fadas e histórias: clown, costume, pirate, castle, ship, tent, hat, prince, shoes / I see (a clown) / This is (my costume) / I’m (a pirate) / It’s (a clown)
                    </Typography> <br />

                    <Typography fontWeight="bold">Little Kids 3 </Typography>
                    <br />
                    <Typography><strong>Material:  My First English Adventure 3 (Livro + CD) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Cumprimentar e despedir-se: Hello. / Goodbye. / See you soon /How are you? I’m fine. <br />
                        • Identificar material escolar: backpack, book, desk, marker, paper, pencil, eraser, ruler, scissors, school supplies / my (book) / What is it? / Is it (pink/a book?) <br />
                        • Identificar animais: butterfly, cat, dog, frog, horse, goose, mouse, giraffe, hippo, zebra / Is the goose big or small? <br />
                        • Usar números até 10 (How many cats? / How many fish do you see?) <br />
                        • Compreender commandos: Color the paper. Cut the Shape. Let’s work together. Touch. Show me. Point. Use (green). Open/Close your eyes/book. Circle (the pencil). Look. Dance. Raise your hand. Put your hand down. Stand up. Sit down. Put on your (coat). Draw a line.  Let’s (clean up /listen /chant/sing/dance. Clap your hands. Come in. Knock on (the door).  Hurry up. Let’s go. <br />
                        • Utilizar as ‘palavras mágicas’ please e thank you <br />
                        • Identificar partes do corpo: ear, eyes, face, hair, mouth, nose, arms, legs, feet, hands, tummy / my/your (eyes) / My hair is red / Look at my red hair / It’s a nose / It has (blue eyes/a big nose). <br />
                        • Identificar cores: Is it (yellow)?/ What color? / Is your (ear) (blue)? / What color is it? <br />
                        • Identificar membros da família: dad / Who’s this <br />
                        • Identificar os adjetivos big, small, hot, cold, happy, sad <br />
                        • Falar sobre habilidades: I can touch my legs! <br />
                        • Identificar roupas: coat, hat, pants, shoes, skirt, T-shirt, boots, sandals, dress, glasses, sweater / Is it (a pair of shoes)? <br />
                        • Identificar onde as coisas estão: Where’s (my hat)? It’s on (the desk). / It’s in (the bedroom) <br />
                        • Identificar preferências: I like (blue and yellow). / What’s your favorite color/food? / Do you like (blue)? / I don’t like bread / What food do you like? / How about you? / I do, too. <br />
                        • Identificar móveis e partes da casa: bed, chair , door, picture, table, window, bathroom, bedroom, kitchen, living room, lamp, phone, sink / This is (a window) / What’s this?  <br />
                        • Identificar expressões de sala de aula: How do you say? / Repeat, please. / I don’t understand. / May I go to the bathroom? / Go to the board. / Here’s (the glue). / Can I borrow (the glue)? / I need (a crayon). <br />
                        • Identificar comida: bread, cheese, chicken, cupcake, fish, water, apple, banana, cookie, milk, beans, rice, strawberry / This is (my sandwich) <br />
                        • Identificar rotinas: brush your teeth. Go to bed. Put on your pajamas. Wash your ears/ face / hands <br />
                        • Identificar sequências:  first, then, last <br />
                        • Identificar vocabulário de praia: beach, bucket, shell, shovel, starfish, sun, fish, water, crab shark, turtle
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Box>
                        <Typography fontWeight={'bold'}>
                            Business English
                        </Typography>
                        <Typography>
                            Inglês para crianças de 6 a 11 anos
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography fontWeight="bold">Kids 1 </Typography>
                    <br />
                    <Typography><strong>Material:  Fun Way 1 (Livro + CD + livro de leitura) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Cumprimentar (Hi! / How do you do? Fine, thank you) <br />
                        • Entender e expresser as ações (Open the door, come in, turn around, clap, go, touch, say, hop, walk, roar, run, fly, go, look at, stop, wait) <br />
                        • Identificar e falar sobre membros da família (mother, father, brother, baby sister)  <br />
                        • Contar de 1 a 10  <br />
                        • Perguntar e responder sobre quantidades (How many?) <br />
                        • Identificar e falar sobre animais (What’s that? It’s a dog. / dog, cat, duck, rat, rabbit, elephant, lion, monkey, bird, mouse, pig, wolf)  <br />
                        • Identificar cores (red, blue, green, orange, pink, black, yellow, brown, white) <br />
                        • Identificar partes do corpo (head, shoulders, hands, feet, eyes, ears) <br />
                        • Identificar pessoas (student, teacher) <br />
                        • Identificar objetos de sala de aula (blackboard, pen, crayon, pencil, book, notebook) <br />
                        • Identificar partes da casa (bedroom, bathroom, living room, kitchen, garden) <br />
                        • Perguntar e responder onde as coisas/pessoas estão <br />
                        • Identificar e falar sobre comida (orange, apple, lettuce, cheese, corn, broccoli, cheese, bread, banana, orange, rice, beans) <br />
                        • Utilizar os adjetivos big, small, strong <br />
                        • Identificar e falar sobre lugares (market, home, park, club, zoo, school) <br />
                        • Utilizar as preposições to e back <br />
                        • Dar direções (right, left) <br />
                        • Identificar e falar sobre meios de transporte (bus, car, truck) <br />
                        • Identificar e falar sobre formas (circle, square, triangle, rectangle) <br />
                        • Identificar e falar sobre partes do dia (morning, afternoon, evening) <br />
                        • Ler um livro com vocabulário adequado ao nível
                    </Typography>
                    <br />

                    <Typography fontWeight="bold">Kids 2 </Typography>
                    <br />
                    <Typography><strong>Material:  Fun Way 2 (Livro + CD + livro de leitura) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Cumprimentar e despedir-se (Hi! / Hello. / Good morning. / Good afternoon. / Good evening. / Good night. / How are you this morning? I am fine, thank you. Bye! ) <br />
                        • Entender e expressar ações (Listen. / Say. / Sing. / Color. / Touch. / Cut out. / Point to. / Circle. / Go. / Stop. / Go away. / Come back.) <br />
                        • Apresentar alguém  (This is…, my teacher.) <br />
                        • Identificar e falar sobre pessoas ( Mom / Dad/ Teacher / friend(s) <br />
                        • Identificar objetos (What is that? / What is this? It is a/an… / I don’t know. /pan / ball / box / vase / kite / cap) <br />
                        • Fazer adições  (What is… plus…? It is… ) usando números de 0 a 10 <br />
                        • Utilizar adjetivos (fat / big / little /small) <br />
                        • Identificar e falar sobre animais (frog / bird / cat / dog / fish / hamster / monkey / pig / rabbit / turtle / hen / pet / hippo / turtle / alligator / flamingo) <br />
                        • Identificar e falar sobre comida (sausage / bread / butter / coffee / milk / jam / cake / ham / cheese / hamburger / hot dog / ice cream / pasta / pizza / apple / banana / grapes / melon / milk / orange / papaya / tomato / macaroni / pancake) <br />
                        • Utilizar vocabulário de camping (tent / trailer / tree) <br />
                        • Identificar e falar sobre meios de transporte ( bus / car / plane.) <br />
                        • Identificar e falar sobre eletrodomésticos e móveis (radio / sofa / TV set / telephone.  <br />
                        • Identificar e falar sobre cores (What color is your…? What color is…? My… is… / It’s… /  blue / red / yellow / green / purple / white / orange / pink / indigo / violet / black / gray / brown) <br />
                        • Identificar e falar sobre material escolar (book / eraser / notebook / pen / pencil / schoolbag / pencil sharpener / pencil case) <br />
                        • Falar sobre partes do corpo humano e de animais (My hair is… My eyes are… How many…? / head / body / arm / leg / foot / feet / eye / ear / mouth / nose / hair/ beak / wing / tail <br />
                        • Falar sobre tipos de animais (bird / mammal) e sobre animais em risco de extinção (emperor penguin / golden lion tamarin / leatherback turtle / blue macaw / jaguar / giant anteater)  <br />
                        • Falar sobre preferências (What do you like? I like… / I don’t like… / My favorite animals are the… and the…) <br />
                        • Identificar e falar sobre a natureza (The… is… / The… are… / How many…? What color is…? / sun / sea / lake / grass. <br />
                        • Ler um livro com vocabulário adequado ao nível
                    </Typography> <br />

                    <Typography fontWeight="bold">Kids 3 </Typography>
                    <br />
                    <Typography><strong>Material:  Fun Way 3 (Livro + CD + livro de história em quadrinhos) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Identificar pessoas (What’s your name? My name is/name’s…) <br />
                        • Falar e perguntar sobre cores (What color is/are the…? / brown / gray / green / orange / red / yellow / black / blue / pink / purple / white) <br />
                        • Falar sobre quantidade (How many…? / I count) <br />
                        • Identificar números de 0 a 20 e fazer adições (What is… plus…? It is… / What number is this? It’s number…) <br />
                        • Dar e seguir instruções (stand up / touch your knees / sit down / raise your hand / close your eyes / close your books / open the box / point to the cat / point to the floor / touch the floor) <br />
                        • Utilizar as ‘palavras mágicas’ (Excuse me. / I’m sorry. / Please. / Thank you).  <br />
                        • Identificar e falar sobre animais (alligator / bear / bird / cat / dog / fish / flamingo / frog / hamster / hen / lion / monkey / mouse / panda / pig / rabbit / turtle / zebra / hen / macaw) <br />
                        • Falar sobre emoções e sentimentos (How are you today? Fine, thank you. I am…  /angry / fine / happy / sad / sick / tired) <br />
                        • Falar sobre animais em perigo de extinção (gray dolphin / green turtle / jaguar / toucan / cheetah / elephant / emperor penguin / giant panda / giraffe / golden lion tamarin / macaw / sea turtle) <br />
                        • Falar sobre móveis e sobre talheres (What’s this? It’s a… What color is the…? / bed / chair / computer / desk / door / lampshade / table / fork / glass / knife / pan / plate / spoon / shelves /jar / stove) <br />
                        • Falar sobre membros da família (This is my family / Who is this/he/she? This is…  / Who am I? How is/are…? He/She is… / I am… / baby / brother / daddy / father / grandfather / grandmother / mommy / mother / sister) <br />
                        • Falar sobre ações (Don’t do that! / cry / drive / read / jump / sing / talk) <br />
                        • Falar sobre animais (cat / dog / duck / elephant / horse / monkey / penguin / snake / chick / cow / dog / donkey / duck / horse / lamb / pig / polar bear) <br />
                        • Falar sobre partes da casa e sobre onde as pessoas estão (Where is…? She/He is in the… Who’s in the…? It’s… / bathroom / bedroom / garden / kitchen / living room / What is it? It’s… Where is…? It’s in/on/under…) <br />
                        • Usar as preposições in / on / under <br />
                        • Falar sobre lugares (farm / lake / tree / cave / stable / classroom / circus / forest / home / house / park / zoo) e distâncias (We Are Ten Miles from Home / Where are you / we? ) <br />
                        • Falar sobre meios de transporte (boat / bus / car / plane / train) <br />
                        • Falar sobre preferências (What Do You Like? What do you like? I like… / I don’t like… ) <br />
                        • Falar sobre aparências (The… is tall/short/fat/ thin/big/small. What are they? They are… )  <br />
                        • Falar sobre opostos (big × small / tall × short / fat × thin / happy × sad / close × open / angry × fine) <br />
                        • Falar sobre comida (apples / bananas / bread / broccoli / butter / cake / cheese / chicken / chocolate / coffee / cookies / eggs / fish / grapes / ham / ice cream / jam / juice / meat / milk / oranges / papayas / peas / sausages / tomatoes)
                    </Typography> <br />

                    <Typography fontWeight="bold">Kids 4 </Typography>
                    <br />
                    <Typography><strong>Material:  Fun Way 4 (Livro + CD + livro de leitura) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Soletrar (Can you spell...? Yes, I can...) <br />
                        • Utilizar vocabulário de praia (beach / starfish / sunshade / surfboard) <br />
                        • Usar números de 1 a 59 <br />
                        • Falar sobre estações do ano e temperaturas (cold / hot / warm <br />
                        • Falar sobre material escolar <br />
                        • Falar sobre formas <br />
                        • Perguntar e responder sobre a idade das pessoas (How old...? I am... / We are... / She is… <br />
                        • Falar sobre os meses do ano, meses favoritos e de aniversário (What are your favorite months? My favorite months are... / When is... birthday? It’s in...) <br />
                        • Usar os adjetivos possessivos his e her <br />
                        • Falar sobre membros da família <br />
                        • Falar sobre habilidades (What can you do? I can... Can you…? Yes, I can. / No, I can’t.)  <br />
                        • Usar os verbos drink, swim, read, eat, sing, jump, dance, walk, hear, see, smell, taste, touch, listen, paint, ring, talk <br />
                        • Falar sobre partes do corpo  <br />
                        • Falar sobre animais <br />
                        • Perguntar e responder o que as pessoas estão fazendo (Are you reading? Yes, I am. / No, I am not. Are... sleeping? Is... sleeping? Yes, she/he is. / No, she/he is not. / Who is …-ing?) <br />
                        • Usar in, on, under, in the middle of para falar sobre a localização de objetos (There is/There are... How many...? Where is/are the...? <br />
                        • Descrever pessoas e o que estão vestindo (Are you ready? What is... wearing now? … is wearing… / What color is/are...? / dress / skirt / blouse / T-shirt / shirt / pants / jeans / hat / shoes / sneakers / shorts / gloves / cap / coat / boots / underwear / socks / tall / short / fat / thin) e sobre a temperatura (It’s hot/cold/warm.)   <br />
                        • Falar sobre partes da casa  <br />
                        • Perguntar e dizer as horas (It’s time to go. What time is it? It’s one o’clock) <br />
                        • Usar os verbos catch, clean, cook, draw, eat, play, read, sing, study, talk,  wash) <br />
                        • Usar there is e there are (How many... are there? There is/There are…) <br />
                        • Falar sobre profissões (cook / driver / nurse / officer / painter / reporter / singer / teacher) <br />
                        • Usar os artigos the, a, na <br />
                        • Falar sobre o ambiente (What’s that? What can we…? We can… / sea / sea turtle / ocean / beach / in danger / coast / swim / throw / save / help / die / coast)
                    </Typography> <br />

                    <Typography fontWeight="bold">Kids 5 </Typography>
                    <br />
                    <Typography><strong>Material:  Fun Way 5 (Livro + CD + almanaque) <br />Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar e perguntar de onde as pessoas são e onde estão (Where is/are from? … is/are from… Where is/are… now? … is/are in…) e sobre países <br />
                        • Usar pontos cardeais (north / south / west / east) e direções (left / right) <br />
                        • Fazer solicitações (Repeat, please. / Speak slowly, please. / The exit, please?) <br />
                        • Usar adjetivos possessivos her e his <br />
                        • Falar sobre os dias da semana  <br />
                        • Perguntar sobre quantidade (How many… are there?) e sobre o que fica onde (What is on the left/right?) <br />
                        • Falar sobre habilidades e rotinas (Can you…? Yes, I can. / No, I can’t. / Do you…? Yes, I do. / No, I don’t dance / drive / eat / make / paint / play / study / walk / wash / write.) <br />
                        • Perguntar e responder a idade (How old…? ) <br />
                        • Usar números de 1 a 50 <br />
                        • Falar sobre membros da família e atividades do dia-a-dia (What do you do…? Where do you…?)  <br />
                        • Usar os verbos brush, go, have, play, run, walk, wash, bake, cut, eat, have, help, make, plant, call, carry, give, laugh, look, move, push, smile, work <br />
                        • Falar sobre partes do corpo <br />
                        • Falar sobre sentimentos e emoções (How are you today?) <br />
                        • Falar sobre o que as pessoas gostam, tem e querem (Do you like/have/want…? Yes, I do. / No, I don’t. What do you like/have/ want? I like/have/want… / I don’t like/have/want…)  <br />
                        • Falar sobre animais e seus filhotes <br />
                        • Falar sobre roupas <br />
                        • Perguntar e responder sobre o que as pessoas estão fazendo, porque e quando (Are you… -ing now? Why are you… -ing? Why do you…? What is/are... doing/having? What time…?) <br />
                        • Falar sobre refeições (breakfast / lunch / snack / dinner) <br />
                        • Falar sobre profissões <br />
                        • Falar sobre quantidade com números de 20 a 100 (How many… do you have? I have… How many… are there? There is/There are… Is/Are there…?  <br />
                        • Usar os adjetivos lazy, noisy, ready, sleepy, tired.  <br />
                        • Falar sobre lugares aonde irá e justificar (I will go to… because… ) <br />
                        • Usar adjetivos e substantivos para descrever lugares (archipelago / bay / canyon / desert / dunes / forest / gulf / hills / island / lake / mountain / oasis / peninsula / planet / river / valley / volcano / waterfall / beautiful / big / cold / hot / interesting / modern / old) <br />
                        • Usar vocabulário de contos de fada (castle / king / kingdom / princess / queen) <br />
                        • Ler folhetos, convites, bilhetes, cartas, e outros textos curtos
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/PlanosDeCurso'} sx={styles.button}>
                Voltar
            </Button>
        </Box>
    )
}