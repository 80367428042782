import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function PlanosDeCursoGoTeens() {
    const styles = {
        boxOutside:{
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5rem'
        },
        button:{
            width: '75%',
            mt: '1em',
            textTransform: 'uppercase'
        }, 
        accordion:{
            width:'100%',
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component={'img'} image="https://gotheextramile.com.br/img/planos-de-curso-title.png" alt="" sx={styles.cardMedia} />
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}> BASIC 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material:  Insights 1  <br /> Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br /><br />
                    <Typography>
                        • Apresentar-se  <br />
                        • Falar sobre sua idade  <br />
                        • Falar sobre nacionalidades  <br />
                        • Perguntar sobre nomes, nacionalidades, idade  <br />
                        • Falar / perguntar sobre endereço, número do telefone  <br />
                        • Falar sobre “posse”  <br />
                        • Dizer as horas  <br />
                        • Descrever lugares  <br />
                        • Falar sobre sua família  <br />
                        • Descrever pessoas  <br />
                        • Falar / perguntar sobre habilidades  <br />
                        • Dar instruções  <br />
                        • Falar sobre o que gosta / não gosta  <br />
                        • Falar sobre atividades de rotina  <br />
                        • Falar sobre a frequência de atividades  <br />
                        • Descrever lugares  <br />
                        • Descrever ações do momento <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong> <br /><br />

                        • Verbo to be (frases afirmativas, negativas, interrogativas)  <br />
                        • Pronomes pessoais (I, You, He, She, It, We, They)  <br />
                        • Perguntas: Who …? Where …? When …? How old …? What …?  <br />
                        • Pronomes demonstrativos <strong><i>this /that /these/ those</i></strong>  <br />
                        • Artigo indefinido <strong><i>a/an</i></strong>  <br />
                        • Plural dos substantivos  <br />
                        • Pronomes possessivos <strong><i>my /her /his /its /your/our</i></strong>  <br />
                        • Preposições de lugar / tempo  <br />
                        • Verbo <strong><i>have</i></strong>  <br />
                        • Verbo <strong><i>can/can´t</i></strong>  <br />
                        • Modo Imperativo  <br />
                        • Artigo definido <strong><i>the</i></strong>  <br />
                        • Conjunções <strong><i>and/but/or</i></strong>  <br />
                        • Presente Simples (frases afirmativas, negativas, interrogativas) <br />
                        • Advérbios de frequência <br />
                        • Presente Contínuo (frases afirmativas, negativas, interrogativas) <br />
                        • There is /are
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>BASIC 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material: Insights 2 <br /> Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre atividades de rotina  <br />
                        • Falar sobre o que as pessoas estão fazendo no momento  <br />
                        • Falar sobre “posse”  <br />
                        • Fazer comparações  <br />
                        • Falar/ perguntar sobre eventos do passado  <br />
                        • Falar sobre planos e intenções futuras  <br />
                        • Perguntar a razão de algo e dar justificativas  <br />
                        • Fazer / aceitar / recusar ofertas  <br />
                        • Falar sobre dinheiro / preço  <br />
                        • Fazer confirmações <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong> <br /> <br />

                        • Presente Simples x Presente Contínuo  <br />
                        • Pronomes Possessivos <strong><i>my /her /his /its /your/our</i></strong> <br />
                        • Perguntas com <strong><i>Whose … ?</i></strong> <br />
                        • Forma comparativa dos adjetivos <strong><i>ER / MORE</i></strong>  <br />
                        • Passado Simples (frases afirmativas, negativas, interrogativas) <br />
                        • Uso do going to  <br />
                        • Uso do <strong><i>Why ... ? Because ...</i></strong>  <br />
                        • Verbo + ing  <br />
                        • Forma superlativa do adjetivo  <br />
                        • Uso de <strong><i>some / any</i></strong>  <br />
                        • Substantivos contáveis e incontáveis  <br />
                        • Expressões com verbo <strong><i>have</i></strong>  <br />
                        • Uso de <strong><i>How much ... ?</i></strong>  <br />
                        • Tag questions
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>PRÉ-INTERMEDIÁRIO 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material: Insights 3 <br /> Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre atividades de rotina  <br />
                        • Descrever ações do momento  <br />
                        • Falar sobre posse  <br />
                        • Fazer comparações  <br />
                        • Dar conselhos  <br />
                        • Dizer onde as coisas estão  <br />
                        • Falar sobre o que gosta / não gosta  <br />
                        • Falar sobre a habilidade das pessoas  <br />
                        • Falar sobre eventos do passado  <br />
                        • Descrever o que estava acontecendo  <br />
                        • Falar sobre causa e efeito  <br />
                        • Fazer previsões  <br />
                        • Falar sobre como as pessoas fazem as coisas <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong> <br /><br />

                        • Presente Simples  <br />
                        • Presente Contínuo  <br />
                        • Pronomes relativos <strong><i>who/that</i></strong>  <br />
                        • Uso do <strong><i>´s</i></strong> <br />
                        • Formas comparativa & superlativa dos adjetivos <br />
                        • Uso de <strong><i>should / shouldn´t</i></strong> <br />
                        • Verbo + preposição + ing  <br />
                        • Preposições de lugar  <br />
                        • Passado Simples x Passado Contínuo  <br />
                        • 1º condicional <strong><i>(if + will)</i></strong> <br />
                        • Futuro simples <strong><i>will/won´t</i></strong> <br />
                        • Advérbios de modo
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>PRÉ-INTERMEDIÁRIO 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material: Insights 4 <br /> Ao término deste nível, o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Falar sobre combinações futuras <br />
                        • Descrever sobre a sequência de eventos <br />
                        • Fazer pedido em um restaurante <br />
                        • Dar direções <br />
                        • Falar sobre eventos recentes <br />
                        • Falar sobre experiências de vida <br />
                        • Expressar obrigação / proibição / falta de obrigação <br />
                        • Fazer sugestões <br />
                        • Expressar preferências <br />
                        • Descrever um processo <br />
                        • Expressar propósito e consequência <br />
                        • Falar sobre possibilidade futura <br />
                        • Falar sobre eventos do passado <br /><br />

                        <strong>Conteúdos Gramaticais a serem abordados:</strong><br /><br />

                        • Presente Contínuo (para expressar futuro) <br />
                        • <strong><i>How much ...? / How many ...?</i></strong> <br />
                        • <strong><i>Some / Any</i></strong> <br />
                        • Substantivos contáveis / incontáveis <br />
                        • Preposições de direção <br />
                        • Presente Perfeito <br />
                        • <strong><i>Too much / too many</i></strong> <br />
                        • Uso de <strong><i>must / mustn´t</i></strong> <br />
                        • Uso de <strong><i>have to / don´t have to</i></strong> <br />
                        • Pronomes relativos <strong><i>which / that</i></strong> <br />
                        • Uso de <strong><i>I´d rather</i></strong> <br />
                        • Verbo + infinitivo <br />
                        • Voz passiva
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/PlanosDeCurso'} sx={styles.button}>
                Voltar
            </Button>
        </Box>
    )
}