import { VideogameAsset } from "@mui/icons-material";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomImageHandler from "../components/CustomImageHandler";

export default function HangMan() {

    const style = {
        box:{
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '90vh'
        },
        cardMedia:{ width: '80%' },
        button:{mt: '1.5em'},
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.box}>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/Hangman-retangular.png',
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }} />
            <Button component={Link} to={'/HangManGame'} startIcon={<VideogameAsset />} variant="contained" sx={style.button}>
                Começar agora
            </Button>
        </Box>
    )
}