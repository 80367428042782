import { ExpandMore, Send } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Grid, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactHtmlParser from 'html-react-parser';
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function Faq() {
    const [faqs, setFaqs] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [apiMessage, setApiMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        api.get('/ws/faq.php')
            .then(res => {
                if (res.data.success) {
                    setFaqs(res.data.faq);
                } else {
                    setMessage('Houve um erro na conexão, tente novamente mais tarde!');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Houve um erro na conexão, tente novamente mais tarde!');
            })
    }, []);

    function handleSend() {
        if (name != '' && email != '' && message != '') {
            setIsLoading(true);
            api.post('/ws/form-faq.php?nome=' + name + '&email=' + email + '&mensagem=' + message)
                .then(res => {
                    if (res.data.success) {
                        setSuccessMessage('Mensagem enviada com sucesso!')
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        setApiMessage('Houve um erro! Tente novamente ou contate o admnistrador.')
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                    setApiMessage('Houve um erro! Tente novamente ou contate o admnistrador.')
                })
        } else {
            setApiMessage('Por favor, preencha os campos acima!')
        }
    }

    const style = {
        boxOutside: {
            p: '2em 1em 5em 1em ',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },

        boxInside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: '1.5em'
        },

        textField: { mt: '1em' },

        imageStyle:{
            width: {
                xs:'10rem',
                sm:'16rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{ src: 'https://gotheextramile.com.br/img/faq.png', imageStyle: style.imageStyle, skeletonStyle: style.skeletonStyle }} />
            <Typography>
                Confira abaixo as dúvidas mais frequentes. Para outras informações, envie-nos uma mensagem.
            </Typography>
            <Box mt='1.5em'>
                {
                    faqs.map((obj) => {
                        return (
                            <Accordion key={obj.id} component={Paper}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography fontWeight='bold'>
                                        {obj.titulo}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {ReactHtmlParser(`${obj.texto}`)}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </Box>
            <Box sx={style.boxInside}>
                <Typography variant="h2">
                    Olá! Você está com alguma dúvida? Mande uma mensagem aqui embaixo.
                </Typography>

                <TextField sx={style.textField} value={name} onChange={e => setName(e.target.value)} label='Nome' fullWidth />
                <TextField sx={style.textField} value={email} onChange={e => setEmail(e.target.value)} label='Email' fullWidth />
                <TextField sx={style.textField} value={message} onChange={e => setMessage(e.target.value)} label='Mensagem' multiline rows={4} fullWidth />

                {
                    apiMessage &&
                    <Typography color={'red'} textAlign='center' mt='1em'>
                        {apiMessage}
                    </Typography>
                }
                {
                    successMessage &&
                    <Typography color={'green'} textAlign='center' mt='1em'>
                        {successMessage}
                    </Typography>
                }

                <Button startIcon={<Send />} onClick={() => { handleSend() }} color="secondary" sx={style.textField} variant="contained" fullWidth >
                    Enviar
                </Button>
            </Box>
        </Box>
    )
}