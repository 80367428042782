const questions1 = [
  {
    question: '1. The president ___________ the election by a landslide.',
    options: [
      { id: '1_1', value: 'won', label: 'won' },
      { id: '1_2', value: 'he won', label: 'he won' },
      { id: '1_3', value: 'yesterday', label: 'yesterday' },
      { id: '1_4', value: 'yesterday', label: 'yesterday' }
    ]
  },
  {
    question: '2. When ___________ the conference ?',
    options: [
      { id: '2_1', value: 'the doctor attended', label: 'the doctor attended' },
      { id: '2_2', value: 'did the doctor attend', label: 'did the doctor attend' },
      { id: '2_3', value: 'the doctor will attend', label: 'the doctor will attend' },
      { id: '2_4', value: 'the doctor´s attendance', label: 'the doctor´s attendance' }
    ]
  },
  {
    question: '3. The North Pole ___________ a latitude of 90 degrees north.',
    options: [
      { id: '3_1', value: 'it has', label: 'it has' },
      { id: '3_2', value: 'is having', label: 'is having' },
      { id: '3_3', value: 'which is having', label: 'which is having' },
      { id: '3_4', value: 'has', label: 'has' }
    ]
  },
  {
    question: '4. The city of Beverly Hills is surrounded on ___________ the city of Los Angeles.',
    options: [
      { id: '4_1', value: 'its sides', label: 'its sides' },
      { id: '4_2', value: 'the sides are', label: 'the sides are' },
      { id: '4_3', value: 'it is the side of', label: 'it is the side of' },
      { id: '4_4', value: 'all sides by', label: 'all sides by' }
    ]
  },
  {
    question: '5. ___________ greyhound, can achieve speeds up to thirty-six miles per hour.',
    options: [
      { id: '5_1', value: 'The', label: 'The' },
      { id: '5_2', value: 'The fastest', label: 'The fastest' },
      { id: '5_3', value: 'The fastest dog', label: 'The fastest dog' },
      { id: '5_4', value: 'The fastest dog, the', label: 'The fastest dog, the' }
    ]
  },
  {
    question: '6. Marmots spend their time foraging among meadow plants and flowers or ___________ on rocky cliffs.',
    options: [
      { id: '6_1', value: 'gets sun', label: 'gets sun' },
      { id: '6_2', value: 'sunning', label: 'sunning' },
      { id: '6_3', value: 'the sun', label: 'the sun' },
      { id: '6_4', value: 'sunny', label: 'sunny' }
    ]
  },
  {
    question: '7. The greenhouse effect occurs ___________ heat radiated from the Sun.',
    options: [
      { id: '7_1', value: 'when does the Earth´s atmosphere trap', label: 'when does the Earth´s atmosphere trap' },
      { id: '7_2', value: 'does the Earth´s atmosphere trap', label: 'does the Earth´s atmosphere trap' },
      { id: '7_3', value: 'when the Earth´s atmosphere traps', label: 'when the Earth´s atmosphere traps' },
      { id: '7_4', value: 'the Earth´s atmosphere traps', label: 'the Earth´s atmosphere traps' }
    ]
  },
  {
    question: '8. The Rose Bowl, ___________ place in New Year´s Day, is the oldest postseason collegiate football game in the United States.',
    options: [
      { id: '8_1', value: 'takes', label: 'takes' },
      { id: '8_2', value: 'it takes', label: 'it takes' },
      { id: '8_3', value: 'which takes', label: 'which takes' },
      { id: '8_4', value: 'took', label: 'took' }
    ]
  },
  {
    question: '9. Experiments ___________ represent a giant step into the medicine of the future.',
    options: [
      { id: '9_1', value: 'using gene therapy', label: 'using gene therapy' },
      { id: '9_2', value: 'use gene therapy', label: 'use gene therapy' },
      { id: '9_3', value: 'they use', label: 'they use' },
      { id: '9_4', value: 'gene therapy uses', label: 'gene therapy uses' }
    ]
  },
  {
    question: '10. ___________ off the Hawaiian coastline are living, others are dead.',
    options: [
      { id: '10_1', value: 'While some types of coral reefs', label: 'While some types of coral reefs' },
      { id: '10_2', value: 'Some types of coral reefs', label: 'Some types of coral reefs' },
      { id: '10_3', value: 'There are many types of coral reefs', label: 'There are many types of coral reefs' },
      { id: '10_4', value: 'Coral reefs', label: 'Coral reefs' }
    ]
  },
  {
    question: '11. Nimbostratus clouds are thick, dark gray clouds ___________ forebode rain.',
    options: [
      { id: '11_1', value: 'what', label: 'what' },
      { id: '11_2', value: 'which', label: 'which' },
      { id: '11_3', value: 'what they', label: 'what they' },
      { id: '11_4', value: 'which they', label: 'which they' }
    ]
  },
  {
    question: '12. Some economists now suggest that home equity loans are merely a new trap to push consumers beyond ___________.',
    options: [
      { id: '12_1', value: 'they can afford', label: 'they can afford' },
      { id: '12_2', value: 'they can afford it', label: 'they can afford it' },
      { id: '12_3', value: 'what is affordable', label: 'what is affordable' },
      { id: '12_4', value: 'able to afford', label: 'able to afford' }
    ]
  },
  {
    question: '13. People who reverse the letters of words ___________ to read suffer from dyslexia.',
    options: [
      { id: '13_1', value: 'when trying', label: 'when trying' },
      { id: '13_2', value: 'if they tried', label: 'if they tried' },
      { id: '13_3', value: 'when tried', label: 'when tried' },
      { id: '13_4', value: 'if he tries', label: 'if he tries' }
    ]
  },
  {
    question: '14. Featured at the Henry Ford Museum ___________ of antique cars dating from 1865.',
    options: [
      { id: '14_1', value: 'is an exhibit', label: 'is an exhibit' },
      { id: '14_2', value: 'an exhibit', label: 'an exhibit' },
      { id: '14_3', value: 'an exhibit is', label: 'an exhibit is' },
      { id: '14_4', value: 'which is an exhibit', label: 'which is an exhibit' }
    ]
  },
  {
    question: '15. Rubber ___________ from vulcanized silicones with a high molecular weight is difficult to distinguish from natural rubber.',
    options: [
      { id: '15_1', value: 'is produced', label: 'is produced' },
      { id: '15_2', value: 'producing', label: 'producing' },
      { id: '15_3', value: 'that produces', label: 'that produces' },
      { id: '15_4', value: 'produced', label: 'produced' }
    ]
  },
  {
    question: '16. ___________ appears considerably larger at the horizon than it does overhead is merely an optical illusion.',
    options: [
      { id: '16_1', value: 'The Moon', label: 'The Moon' },
      { id: '16_2', value: 'That the Moon', label: 'That the Moon' },
      { id: '16_3', value: 'When the Moon', label: 'When the Moon' },
      { id: '16_4', value: 'The Moon which', label: 'The Moon which' }
    ]
  },
  {
    question: '17. According to the World Health Organization, ___________ any of the six most dangerous diseases to break out, it could be cause for quarantine.',
    options: [
      { id: '17_1', value: 'were', label: 'were' },
      { id: '17_2', value: 'they were', label: 'they were' },
      { id: '17_3', value: 'there were', label: 'there were' },
      { id: '17_4', value: 'were they', label: 'were they' }
    ]
  },
  {
    question: '18. The 1980 explosion of ___________ the first volcanic eruption in the continental United States in over 60 years.',
    options: [
      { id: '18_1', value: 'Mount St. Helens', label: 'Mount St. Helens' },
      { id: '18_2', value: 'Was Mount St. Helens', label: 'Was Mount St. Helens' },
      { id: '18_3', value: 'It was Mount St. Helens', label: 'It was Mount St. Helens' },
      { id: '18_4', value: 'Mount St. Helens was', label: 'Mount St. Helens was' }
    ]
  },
  {
    question: '19. Static electricity ___________ one cloud to another or between clouds and the ground creates lightning.',
    options: [
      { id: '19_1', value: 'flows from', label: 'flows from' },
      { id: '19_2', value: 'the flow from', label: 'the flow from' },
      { id: '19_3', value: 'flowing from', label: 'flowing from' },
      { id: '19_4', value: 'is flowing from', label: 'is flowing from' }
    ]
  },
  {
    question: '20. The Model T car, introduced in 1908, ___________ $850.',
    options: [
      { id: '20_1', value: 'the price was', label: 'the price was' },
      { id: '20_2', value: 'a price of', label: 'a price of' },
      { id: '20_3', value: 'to be priced at', label: 'to be priced at' },
      { id: '20_4', value: 'was priced at', label: 'was priced at' }
    ]
  }
];

const questions2 = [
  {
    question: '21. The word immeasurably in the passage is closest in meaning to',
    options: [
      { id: '21_1', value: 'frequently', label: 'frequently' },
      { id: '21_2', value: 'greatly', label: 'greatly' },
      { id: '21_3', value: 'rapidly', label: 'rapidly' },
      { id: '21_4', value: 'reportedly', label: 'reportedly' }
    ]
  },
  {
    question: '22. According to the paragraph, the presidency of Andrew Jackson was especially significant for which of the following reasons?',
    options: [
      { id: '22_1', value: 'The President granted a portion of his power to the Senate', label: 'The President granted a portion of his power to the Senate' },
      { id: '22_2', value: 'The President began to address the Senate on a regular basis.', label: 'The President began to address the Senate on a regular basis.' },
      { id: '22_3', value: 'It was the beginning of the modern presidency in the United States.', label: 'It was the beginning of the modern presidency in the United States.' },
      { id: '22_4', value: 'It was the first time that the Senate had been known to oppose the President.', label: 'It was the first time that the Senate had been known to oppose the President.' }
    ]
  }
];

const questions3 = [
  {
    question: '23. The word <b>concur</b> in the passage is closest in meaning to',
    options: [
      { id: '23_1', value: 'estimate', label: 'estimate' },
      { id: '23_2', value: 'agree', label: 'agree' },
      { id: '23_3', value: 'expect', label: 'expect' },
      { id: '23_4', value: 'understand', label: 'understand' }
    ]
  },
  {
    question: '24. The word <b>them</b> in the passage refers to',
    options: [
      { id: '24_1', value: 'emotions', label: 'emotions' },
      { id: '24_2', value: 'people', label: 'people' },
      { id: '24_3', value: 'photographs', label: 'photographs' },
      { id: '24_4', value: 'cultures', label: 'cultures' }
    ]
  },
  {
    question: '25. According to the paragraph, which of the following was true of the Fore people of New Guinea?',
    options: [
      { id: '25_1', value: 'They did not want to be shown photographs.', label: 'They did not want to be shown photographs.' },
      { id: '25_2', value: 'They were famous for their story-telling skills.', label: 'They were famous for their story-telling skills.' },
      { id: '25_3', value: 'They knew very little about Western culture.', label: 'They knew very little about Western culture.' },
      { id: '25_4', value: 'They did not encourage the expression of emotions.', label: 'They did not encourage the expression of emotions.' }
    ]
  },
  {
    question: '26. Which of the sentences below best expresses the essential information in the <u>underlined</u> sentence in the passage? Incorrect choices change the meaning in important ways or leave out essential information.',
    options: [
      { id: '26_1', value: 'The Fore’s facial expressions indicated their unwillingness to pretend to be story characters.', label: 'The Fore’s facial expressions indicated their unwillingness to pretend to be story characters.' },
      { id: '26_2', value: 'The Fore were asked to display familiar facial expressions when they told their stories.', label: 'The Fore were asked to display familiar facial expressions when they told their stories.' },
      { id: '26_3', value: 'The Fore exhibited the same relationship of facial expressions and basic emotions that is seen in Western culture when they acted out stories.', label: 'The Fore exhibited the same relationship of facial expressions and basic emotions that is seen in Western culture when they acted out stories.' },
      { id: '26_4', value: 'The Fore were familiar with the facial expressions and basic emotions of characters in stories.', label: 'The Fore were familiar with the facial expressions and basic emotions of characters in stories.' }
    ]
  }
];

const questions4 = [
    {
        question: '27. According to the paragraph, sculptors in the Italian Renaissance stopped using cannonballs in bronze statues of horses because',
        options: [
            { id: '27_1', value: 'they began using a material that made the statues weigh less', label: 'they began using a material that made the statues weigh less' },
            { id: '27_2', value: 'they found a way to strengthen the statues internally', label: 'they found a way to strengthen the statues internally' },
            { id: '27_3', value: 'the aesthetic tastes of the public had changed over time', label: 'the aesthetic tastes of the public had changed over time' },
            { id: '27_4', value: 'the cannonballs added too much weight to the statues', label: 'the cannonballs added too much weight to the statues' }
        ]
    }
]

const questions5 = [
    {
    question: '28. According to the paragraph, all of the following were true of American art in the late 1800’s and early 1900’s EXCEPT:',
    options: [
      { id: '28_1', value: 'Most Americans thought art was unimportant.', label: 'Most Americans thought art was unimportant.' },
      { id: '28_2', value: 'American art generally copied European styles and traditions.', label: 'American art generally copied European styles and traditions.' },
      { id: '28_3', value: 'Most Americans considered American art inferior to European art', label: 'Most Americans considered American art inferior to European art' },
      { id: '28_4', value: 'American art was very popular with European audiences.', label: 'American art was very popular with European audiences.' }
    ]
  },
  {
    question: '29. “In the animal world the task of moving about is fulfilled in many ways. For some animals locomotion is accomplished by changes in body shape ...” The word locomotion in the passage is closest in meaning to',
    options: [
      { id: '29_1', value: 'evolution', label: 'evolution' },
      { id: '29_2', value: 'movement', label: 'movement' },
      { id: '29_3', value: 'survival', label: 'survival' },
      { id: '29_4', value: 'escape', label: 'escape' }
    ]
  },
  {
    question: '30. “Some poisonous snake bites need to be treated immediately or the victim will suffer paralysis ...” In stating that the victim will suffer paralysis the author means that the victim will',
    options: [
      { id: '30_1', value: 'lose the ability to move', label: 'lose the ability to move' },
      { id: '30_2', value: 'become unconscious', label: 'become unconscious' },
      { id: '30_3', value: 'undergo shock', label: 'undergo shock' },
      { id: '30_4', value: 'feel great pain', label: 'feel great pain' }
    ]
  }
]

const questions6 = [
    {
        question:'31. Where would the sentence best fit?',
        options:[
            {id:'31_01', value:'A', label: 'A'},
            {id:'31_02', value:'B', label: 'B'},
            {id:'31_03', value:'C', label: 'C'},
            {id:'32_04', value:'D', label: 'D'}
        ]
    }
]

const questions7 = [
    {
    question: '32. The word precious in the passage is closest in meaning to',
    options: [
      { id: '32_1', value: 'exact', label: 'exact' },
      { id: '32_2', value: 'scarce', label: 'scarce' },
      { id: '32_3', value: 'valuable', label: 'valuable' },
      { id: '32_4', value: 'initial', label: 'initial' }
    ]
  },
  {
    question: '33. Pakicetus and modern cetaceans have similar',
    options: [
      { id: '33_1', value: 'hearing structures', label: 'hearing structures' },
      { id: '33_2', value: 'adaptations for diving', label: 'adaptations for diving' },
      { id: '33_3', value: 'skull shapes', label: 'skull shapes' },
      { id: '33_4', value: 'breeding locations', label: 'breeding locations' }
    ]
  },
  {
    question: '34. The air inside a house or office building often has higher concentrations of contaminants ___________ heavily polluted outside air.',
    options: [
      { id: '34_1', value: 'than does', label: 'than does' },
      { id: '34_2', value: 'more', label: 'more' },
      { id: '34_3', value: 'as some that are', label: 'as some that are' },
      { id: '34_4', value: 'like of', label: 'like of' }
    ]
  },
  {
    question: '35. Not only ___________ to determine the depth of the ocean floor, but it is also used to locate oil.',
    options: [
      { id: '35_1', value: 'to use seismology', label: 'to use seismology' },
      { id: '35_2', value: 'is seismology used', label: 'is seismology used' },
      { id: '35_3', value: 'seismology is used', label: 'seismology is used' },
      { id: '35_4', value: 'using seismology', label: 'using seismology' }
    ]
  },
  {
    question: '36. Before every presidential election in the United States, the statisticians try to guess the proportion of the population that ___________ for each candidate.',
    options: [
      { id: '36_1', value: 'are voted', label: 'are voted' },
      { id: '36_2', value: 'voting', label: 'voting' },
      { id: '36_3', value: 'to be voted', label: 'to be voted' },
      { id: '36_4', value: 'will vote', label: 'will vote' }
    ]
  }
]

const questions8 = [
    {
    question: '37. What does the passage mainly discuss?',
    options: [
      { id: '37_1', value: 'The origin of theater', label: '37. The origin of theater' },
      { id: '37_2', value: 'The role of ritual in modern dance', label: '37. The role of ritual in modern dance' },
      { id: '37_3', value: 'The importance of storytelling', label: '37. The importance of storytelling' },
      { id: '37_4', value: 'The variety of early religious activities', label: '37. The variety of early religious activities' }
    ]
  },
  {
    question: '38. The word “they” in line 6 refers to',
    options: [
      { id: '38_1', value: 'Seasonal changes', label: '38. Seasonal changes' },
      { id: '38_2', value: 'Natural forces', label: '38. Natural forces' },
      { id: '38_3', value: 'Theories', label: '38. Theories' },
      { id: '38_4', value: 'Human beings', label: '38. Human beings' }
    ]
  },
  {
    question: '39. What aspect of drama does the author discuss in the first paragraph?',
    options: [
      { id: '39_1', value: 'The reason drama is often unpredictable', label: '39. The reason drama is often unpredictable' },
      { id: '39_2', value: 'The seasons in which dramas were performed', label: '39. The seasons in which dramas were performed' },
      { id: '39_3', value: 'The connection between myths and dramatic plots', label: '39. The connection between myths and dramatic plots' },
      { id: '39_4', value: 'The importance of costumes in early drama', label: '39. The importance of costumes in early drama' }
    ]
  },
  {
    question: '40. Which of the following is NOT mentioned as a common element of theater and ritual?',
    options: [
      { id: '40_1', value: 'Dance', label: '40. Dance' },
      { id: '40_2', value: 'Costumes', label: '40. Costumes' },
      { id: '40_3', value: 'Music', label: '40. Music' },
      { id: '40_4', value: 'Magic', label: '40. Magic' }
    ]
  },
  {
    question: '41. The word "considerable" in line 11 refers to:',
    options: [
      { id: '41_1', value: 'thoughtful', label: '41. thoughtful' },
      { id: '41_2', value: 'substantial', label: '41. substantial' },
      { id: '41_3', value: 'relational', label: '41. relational' },
      { id: '41_4', value: 'ceremonial', label: '41. ceremonial' }
    ]
  }
]

const questions9 = [
  {
    question: '42.',
    options: [
      { id: '42_1', value: 'They done it very well after they had practiced.', label: '42. They done it very well after they had practiced.' },
      { id: '42_2', value: 'They did it very well after they had practiced.', label: '42. They did it very well after they had practiced.' }
    ]
  },
  {
    question: '43.',
    options: [
      { id: '43_1', value: 'Before she run the computer problem, she had checked it out with her supervisor.', label: '43. Before she run the computer problem, she had checked it out with her supervisor.' },
      { id: '43_2', value: 'Before she ran the computer problem, she had checked it out with her supervisor.', label: '43. Before she ran the computer problem, she had checked it out with her supervisor.' }
    ]
  },
  {
    question: '44.',
    options: [
      { id: '44_1', value: 'We ate dinner in Albuquerque on our vacation last year.', label: '44. We ate dinner in Albuquerque on our vacation last year.' },
      { id: '44_2', value: 'We eat dinner in Albuquerque on our vacation last year.', label: '44. We eat dinner in Albuquerque on our vacation last year.' }
    ]
  },
  {
    question: '45.',
    options: [
      { id: '45_1', value: 'My nephew begun working for me about ten years ago.', label: '45. My nephew begun working for me about ten years ago.' },
      { id: '45_2', value: 'My nephew began working for me about ten years ago.', label: '45. My nephew began working for me about ten years ago.' }
    ]
  },
  {
    question: '46.',
    options: [
      { id: '46_1', value: 'I know that you were forty on your last birthday.', label: '46. I know that you were forty on your last birthday.' },
      { id: '46_2', value: 'I know that you been forty on your last birthday.', label: '46. I know that you been forty on your last birthday.' }
    ]
  },
  {
    question: '47.',
    options: [
      { id: '47_1', value: 'After you show me the way, I can to go by myself.', label: '47. After you show me the way, I can to go by myself.' },
      { id: '47_2', value: 'After you show me the way, I can go by myself.', label: '47. After you show me the way, I can go by myself.' }
    ]
  },
  {
    question: '48.',
    options: [
      { id: '48_1', value: 'Our friends might stop to seeing us on their way to California.', label: '48. Our friends might stop to seeing us on their way to California.' },
      { id: '48_2', value: 'Our friends might stop to see us on their way to California.', label: '48. Our friends might stop to see us on their way to California.' }
    ]
  },
  {
    question: '49.',
    options: [
      { id: '49_1', value: 'I would, if there is time, liking to make a phone call.', label: '49. I would, if there is time, liking to make a phone call.' },
      { id: '49_2', value: 'I would, if there is time, like to make a phone call.', label: '49. I would, if there is time, like to make a phone call.' }
    ]
  },
  {
    question: '50.',
    options: [
      { id: '50_1', value: 'Beth may, with effort, pass this course.', label: '50. Beth may, with effort, pass this course.' },
      { id: '50_2', value: 'Beth may, with effort, to pass this course.', label: '50. Beth may, with effort, to pass this course.' }
    ]
  }
];

const TOELF = [
    {
        title:'',
        questions:questions1
    },
    {
        title:`The development of the modern presidency in the United States began with Andrew Jackson who swept to power in 1829
            at the head of the Democratic Party and served until 1837. During his administration he <b>immeasurably</b> enlarged the
             power of the presidency. “The President is the direct representative of the American people,” he lectured the Senate when it opposed him.
            “He was elected by the people, and is responsible to them.” With this declaration, 
            Jackson redefined the character of the presidential office and its relationship to the people.`,
        questions:questions2
    },
    {
        title:`Most investigators <b>concur</b> that certain facial expressions suggest the same emotions in all people.
        Moreover, people in diverse cultures recognize the emotions manifested by the facial expressions.
        In classic research Paul Ekman took photographs of people exhibiting the emotions of anger, disgust, fear, happiness, and sadness.
        He then asked people around the world to indicate what emotions were being depicted in <b>them</b>.
        Those queried ranged from European college students to members of the Fore, a tribe that dwells in the New Guinea highlands.
        All groups, including the Fore, who had almost no contact with Western culture, agreed on the portrayed emotions.
        <u>The Fore also displayed familiar facial expressions when asked how they would respond if they were the characters in stories that called for basic emotional responses.</u>
        .kman and his colleagues more recently obtained similar results in a study of ten cultures in which participants were permitted to report that multiple emotions were shown by facial expressions. The participants generally agreed on which two emotions were being shown and which emotion was more intense. `,
        questions:questions3
    },
    {
        title:'“... Sculptures must, for example, be stable, which requires an understanding of the properties of mass, weight distribution, and stress. Paintings must have rigid stretchers so that the canvas will be taut, and the paint must not deteriorate, crack, or discolor. These are problems that must be overcome by the artist because they tend to intrude upon his or her conception of the work. For example, in the early Italian Renaissance, bronze statues of horses with a raised foreleg usually had a cannonball under that hoof. This was done because the cannonball was needed to support the weight of the leg. In other words, the demands of the laws of physics, not the sculptor’s aesthetic intentions, placed the ball there. That this device was a necessary structural compromise is clear from the fact that the cannonball quickly disappeared when sculptors learned how to strengthen the internal structure of a statue with iron braces (iron being much stronger than bronze)... “',
        questions:questions4
    },
    {
        title:'“<p>The United States in the 1800’s was full of practical, hardworking people who did not consider the arts—from theater to painting—useful occupations. In addition, the public’s attitude that European art was better than American art both discouraged and infuriated American artists. In the early 1900’s there was a strong feeling among artists that the United States was long overdue in developing art that did not reproduce European traditions. Everybody agreed that the heart and soul of the new country should be reflected in its art. But opinions differed about what this art would be like and how it would develop.</p>”',
        questions:questions5
    },
    {
        title:'“<p>Scholars offer three related but different opinions about this puzzle. (*A) One opinion is that the paintings were a record of the seasonal migrations made by herds. (*B) Because some paintings were made directly over others, obliterating them, it is probable that a painting’s value ended with the migration it pictured. (*C) Unfortunately, this explanation fails to explain the hidden locations, unless the migrations were celebrated with secret ceremonies. (*D)” <br>Look at the four squares (*) that indicate where the following sentence could be added to the passage. <br><b>All three of them have strengths and weaknesses, but none adequately answers all of the questions the paintings present.</b> </p>',
        questions:questions6
    },
    {
        title:`<p>The fossil consists of a complete skull of an archaeocyte, an extinct group of ancestors of modern cetaceans.
        Although limited to a skull, the Pakicetus fossil provides <b>precious</b> details on the origins of cetaceans.
        The skull is cetacean-like but its jawbones lack the enlarged space that is filled with fat or oil and used for receiving underwater sound in modern whales.
        Pakicetus probably detected sound through the ear opening as in land mammals.
        The skull also lacks a blowhole, another cetacean adaptation for diving.
        Other features, however, show experts that Pakicetus is a transitional form between a group of extinct flesh-eating mammals, the mesonychids, and cetaceans.
        It has been suggested that Pakicetus fed on fish in shallow water and was not yet adapted for life in the open ocean.
        It probably bred and gave birth on land.</p>`,
        questions:questions7
    },
    {
        title: `<p>There are many theories abut the beginning of drama in ancient Greece.
         The one most widely accepted today is based on the assumption that drama evolved from ritual.
         The argument for this view goes as follows.
         In the beginning, human beings viewed the natural forces of the world, 
        even the seasonal changes, as unpredictable, and <b>they</b> sought, through various means, to control these unknown and feared powers.
         Those measures which appeared to bring the desired result were then retained and repeated until they hardened into fixed rituals. 
         Eventually stories arose which explained or veiled the mysteries of the rites.
         As time passed some rituals were abandoned, but the stories, later called myths, persisted and provided material for art and drama. 
        Those who believed that drama evolved out of ritual also argue that those rites contained the seed of theater because music, dance, masks,
         and costumes were almost always used. Furthermore, a suitable site had to be provided for performances, 
         and when the entire community did not participate, a clear division was usually made between the “acting area” and the “auditorium”.
         In addition, there were performers, and, since <b>considerable</b> importance was attached to avoiding mistakes in the enactment of rites,
         religious leaders usually assumed the task. Eventually such dramatic representations were separated from religious activities.</p>`,
        questions:questions8
    },
    {
        title:'<b>Choose the correct alternative:</b>',
        questions:questions9
    }
]

export default TOELF