import { Box, CardMedia, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player/youtube";
import { useParams } from "react-router-dom";
import api from "../services/api";

export default function WeDescription() {

    const { id } = useParams();

    const [image, setImage] = useState(null);
    const [desc, setDesc] = useState('');

    const [data, setData] = useState([{}]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        api.post('/ws/wes.php?id=' + id)
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.wes);
                    setIsLoading(false);
                } else {
                    alert('Houve um erro! Tente novamente ou contate o administrador!');
                }
            })
            .catch(() => {
                alert('Houve um erro! Tente novamente ou contate o administrador!');
            });

        if (id == 1) {
            setImage('we-cook');
            setDesc('Assista nossos vídeos, leia os textos e fique por dentro da cultura gastronômica de outros países.');

        } else if (id === '2') {
            setImage('we-green');
            setDesc('Curta as atividades ecofriendly e discuta sobre um mundo mais sustentável em outro idioma.');

        } else if (id === '3') {
            setImage('we-movies');
            setDesc('Curta as atividades cinematrográficas e fique por dentro do mundo da sétima arte.');

        } else if (id === '4') {
            setImage('we-music');
            setDesc('Assista nossos vídeos, curta as atividades de música, enriqueça seu vocabulário e “aprimore seu ouvido” com seus ídolos.');

        } else if (id === '5') {
            setImage('we-sports');
            setDesc('Assista nossos vídeos e ponha seu vocabulário em forma aprendendo termos relacionados a diversos esportes.');

        } else if (id === '6') {
            setImage('we-travel');
            setDesc('Assista os vídeos, curta as atividades e viaje conosco aprendendo sobre a cultura de outros países.');

        } else if (id === '7') {
            setImage('we-mix');
            setDesc('Curta os artigos sobre os mais variados temas e se atualize com as últimas tendências!');

        } else if (id === '8') {
            setImage('we-kids&teens');
            setDesc('Curta as atividades elaboradas exclusivamente para nosos KIDS & TEENS e aproveite os conteúdos pedagógicos e divertidos!');

        } else {
            setImage('wes');
            setDesc('Oops, nenhum We foi selecionado!');

        }
    }, []);

    const styles = {
        boxOutside: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '90vh'
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        },
        typography: { mt: '1.5em', textAlign: 'center' },
        cardMediaGrid: { width: '100%', m: '2em 0' },
        circularProgress: { m: '1em auto' }

    }

    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component="img" image={image ? `https://gotheextramile.com.br/img/${image}.png` : "https://gotheextramile.com.br/img/appIcons/wes.png"}
                alt="" sx={styles.cardMedia} />
            <Typography sx={styles.typography}>
                {desc}
            </Typography>
            <Grid container>
                {
                    !isLoading ?
                        data.map((obj) => {
                            return (
                                <Grid item xs={12} key={obj.id}>
                                    <CardMedia sx={styles.cardMediaGrid} component={"img"} image="https://gotheextramile.com.br/img/line-04.png" />
                                    {
                                        obj.tipo === "img" &&
                                        <CardMedia image={obj.source} component={'img'} alt={obj.titulo} />
                                    }
                                    {
                                        obj.tipo === "youtube" &&
                                        <ReactPlayer
                                            width={'100%'}
                                            height={'40vh'}
                                            controls={true}
                                            url={obj.source}
                                        />
                                    }
                                    <Typography variant="h2" mt='1em'>
                                        {obj.titulo}
                                    </Typography>
                                    <Typography mt='1em' maxWidth='90vw' overflow='hidden'>
                                        {HTMLReactParser(obj.txt)}
                                    </Typography>
                                </Grid>
                            )
                        })
                        :
                        <CircularProgress size='2em' mt color="primary" sx={styles.circularProgress} />
                }
            </Grid>
        </Box>
    )
}