import { ArrowBack } from "@mui/icons-material";
import { Box, Button, CardMedia, createTheme, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AppVersion from "../components/AppVersion";

export default function PrivacyPolicies() {

    return (
        <Box sx={styles.centralize}>
            <Box sx={styles.container}>
                <Box sx={styles.logoContainer}>
                    <CardMedia
                        component={'img'}
                        sx={styles.cardMedia}
                        image={'https://gotheextramile.com.br/img/logo2.png'}
                    />
                </Box>
                <Typography sx={styles.h1}>
                    POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS GO AHEAD IDIOMAS
                </Typography>
                <Typography sx={styles.paragraph}>
                    <Typography sx={{ ...styles.bold, marginBottom: 0, }}>A GO AHEAD IDIOMAS EIRELI</Typography>, sociedade inscrita no CNPJ sob n. 02.817.524/0001-03, respeita sua privacidade e os princípios norteadores da proteção de dados pessoais, nos termos da Lei 13.709/2018 (Lei Geral de Proteção de Dados - LGPD).
                </Typography>
                <Typography sx={styles.paragraph}>
                    A presente Política demonstra como tratamos seus dados pessoais de todos Titulares que se relacionam com a escola de maneira online ou offline. Estamos empenhados em proteger os dados pessoais dos nossos alunos, funcionários, fornecedores, prestadores e demais usuários que se relacionam com a escola ou navegam em nosso site, adotando medidas técnicas e organizacionais voltadas a esta finalidade.  Agradecemos sua confiança e cuidaremos delas com a atenção, zelo e responsabilidade.
                </Typography>
                <Typography sx={styles.paragraph}>
                    O tratamento de dados realizado pela GO AHEAD consiste na coleta, recepção, utilização, acesso, transmissão, distribuição, comunicação, compartilhamento, processamento, arquivamento, armazenamento e eliminação. É realizada para consecução dos objetivos comerciais voltados a prestação de serviços educacionais do ensino de idiomas e para melhoria continua dos nossos produtos e serviços. Para tanto, todo tratamento de dados é realizado em ambiente com acesso controlado e seguro.
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD trata dados pessoais, respeitando a proteção e privacidade destes. Ao navegar em nosso site, preencher nosso cadastro ou contratar nossos serviços você deve ler integralmente nossa Política de Privacidade e Proteção de Dados, de forma a anuir às disposições regulatórias aqui previstas.
                </Typography>
                <Typography sx={styles.bold}>
                    1 – Controladora de dados pessoais:
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD figura como Controladora de dados pessoais, considerando que compete a esta as decisões referentes ao tratamento.  Tratamos os seus dados pessoais para prestar os serviços necessários à concretização dos objetivos educacionais e comerciais da escola, e melhoria continua dos nossos produtos e serviços.
                </Typography>
                <Typography sx={styles.bold}>
                    2 – Dados Pessoais coletados:
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD pode coletar dados quando Você acessa nosso site ou aplicativo e fornece seus dados em nosso Formulário de Contato e de NOVO CADASTRO. Você pode optar por não fornecer certas informações, mas, neste caso, poderá não conseguir se beneficiar da totalidade de nossos serviços.
                    Para o seu cadastramento são coletados:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Nome completo, endereço de E-mail, data nascimento, número de Skype, gênero, empresa/setor onde trabalha, como conheceu escola, CPF, RG, endereço comercial, CEP, Estado, filiação, endereço residencial, objetivo de estudo, se tem filhos, número do celular, qual sistema operacional de smartphone utiliza, senhas e confirmação senhas e endereço de IP.
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD coleta informações de identificação pessoal para registro e processamento de nossos serviços educacionais, compreendendo o agendamento de aulas, informações pedagógicas (níveis, materiais, certificados, etc), informações financeiras (avisos de mensalidades e recibos), acompanhamento da evolução e aprendizado do aluno, plano de fidelidade para brindes e recompensas, quizzes diários e biblioteca de atividades.
                </Typography>
                <Typography sx={styles.bold}>
                    Crianças e Adolescentes
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD oferece dois programas de idiomas para crianças e adolescentes: GO KIDS e o GO TEENS. Para o fornecimento dos produtos e serviços acima indicados, são coletados dados pessoais de crianças e adolescentes.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Para coleta dos dados pessoais de crianças e adolescentes a escola envia o link NOVO CADASTRO ao responsável legal para preenchimento. Os dados pessoais coletados das crianças e adolescentes são: nome e sobrenome, a instituição do aluno, nível de escolaridade do aluno, turno de preferência na aula, data de nascimento, nome do responsável legal e grau de parentesco.
                </Typography>
                <Typography sx={styles.bold}>
                    3 – A finalidade do tratamento dos dados pessoais:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Tratamos seus dados pessoais para atender, prestar, desenvolver e melhorar os produtos e serviços que oferecemos aos nossos usuários, alunos, funcionários, fornecedores e demais clientes da GO AHEAD. Essas finalidades incluem:
                </Typography>
                <Typography sx={styles.bold}>
                    3.1. Contratação de serviços
                </Typography>
                <Typography sx={styles.paragraph}>
                    Coletamos dados pessoais quando Você entra em contato com a escola e preenche nosso Formulário de Contato e de NOVO CADASTRO. A finalidade da coleta de dados é para contato, emissão de contrato de prestação de serviços educacionais, envio de regulamento e política de funcionamento da Escola, campanhas promocionais, confirmação e envio de recibos de pagamentos, agendamento das atividades acadêmicas e envio de certificados.
                </Typography>
                <Typography sx={styles.bold}>
                    3.2. Melhoria dos Produtos e Serviços da GO AHEAD
                </Typography>
                <Typography sx={styles.paragraph}>
                    Podemos usar seus dados pessoais para auxiliar na definição de suas preferências e melhoria da usabilidade e efetividade dos produtos e serviços educacionais prestados pela GO AHEAD.
                </Typography>
                <Typography sx={styles.bold}>
                    3.3. Coletamos e usamos suas informações pessoais para cumprimento de obrigações legais ou regulatórias
                </Typography>
                <Typography sx={styles.paragraph}>
                    Coletamos e utilizamos seus dados pessoais para o fornecimento de nossos serviços educacionais, emissão de nota fiscal e fornecimento de informações aos órgãos fiscalizadores municipais, estaduais e federais.
                </Typography>
                <Typography sx={styles.bold}>
                    3.4. Usamos seus dados pessoais para nos comunicarmos com Você em relação aos serviços educacionais prestados pela GO AHEAD
                </Typography>
                <Typography sx={styles.paragraph}>
                    Coletamos e utilizamos seus dados pessoais para comunicarmos com alunos, funcionários, fornecedores, prestadores e demais usuários que se relacionam com a escola. A finalidade da coleta é para a execução de contrato ou procedimentos preliminares em que o titular é parte.
                    A comunicação pode ser feita por telefone, pelo site, por e-mail, por mensageria e pelos aplicativos de redes sociais. Fique atento à Política de Privacidade da plataforma escolhida em que efetuou seu login e senha.
                </Typography>
                <Typography sx={styles.bold}>
                    3.5. Tratamos seus dados pessoais para sua segurança
                </Typography>
                <Typography sx={styles.paragraph}>
                    Realizamos o tratamento de dados com a finalidade de impedir, detectar e proteger fraudes e abusos relacionadas a segurança da informação de sua conta de usuário, dados cadastrais, login e senha e demais informações armazenadas em nosso site, servidor, portal do aluno, intranet e no aplicativo da GO AHEAD.
                </Typography>
                <Typography sx={styles.bold}>
                    3.6. Tratamento de dados pessoais para um fim específico mediante seu consentimento
                </Typography>
                <Typography sx={styles.paragraph}>
                    Podemos pedir o seu consentimento para tratar suas informações pessoais em uma finalidade específica como ações de publicidade e marketing. Quando você concorda com o tratamento de suas informações pessoais para uma finalidade específica, você pode revogar o seu consentimento a qualquer momento, e o tratamento para este fim específico será interrompido.
                </Typography>
                <Typography sx={styles.bold}>
                    4 - Meios de coleta de dados pessoais:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Podemos coletar dados por telefone, e-mail, site, aplicativo da GO AHEAD, aplicativos de mensageria (WhatsApp e Igente) e pelos aplicativos de redes sociais.
                </Typography>
                <Typography sx={styles.bold}>
                    5 - Você possui acesso aos seus dados:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Você pode acessar seus dados pessoais cadastrados conosco livremente, dispondo dos seguintes direitos trazidos pelo artigo 18 pela Lei 13.709 de 2018:
                </Typography>
                <Typography sx={styles.bold}>
                    5.1. Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.
                </Typography>
                <Typography sx={styles.bold}>
                    5.2. Acessar seus dados, podendo solicitá-los em uma cópia legível impressa ou por meio eletrônico, seguro e idôneo.
                </Typography>
                <Typography sx={styles.bold}>
                    5.3. Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.
                </Typography>
                <Typography sx={styles.bold}>
                    5.4. Limitar seus dados quando forem desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.
                </Typography>
                <Typography sx={styles.bold}>
                    5.5. Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a GO AHEAD trata a seu respeito.
                </Typography>
                <Typography sx={styles.bold}>
                    5.6. Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.
                </Typography>
                <Typography sx={styles.bold}>
                    5.7. Revogar seu consentimento, desautorizando o tratamento específico de seus dados.
                </Typography>
                <Typography sx={styles.bold}>
                    5.8. Informar sobre a possibilidade de não fornecer seu consentimento  e sobre as consequências advindas de sua negativa.
                </Typography>
                <Typography sx={styles.bold}>
                    6 – Compartilhamento dos seus dados pessoais com terceiros:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Para concretização dos objetivos educacionais e comerciais e melhoria contínua dos nossos produtos e serviços a GO AHEAD pode compartilhar seus dados com nossos Fornecedores e Terceiros para execução dos serviços contratados, desde que para o cumprimento das finalidades propostas e com observância dos princípios da finalidade, adequação, necessidade, segurança e prevenção previstos na Lei 13.709/2018 e em conformidade com a presente Política de Privacidade e Proteção de Dados, de forma a respeitar o direito dos Titulares.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Ainda, seus dados poderão compartilhados nas seguintes hipóteses:
                </Typography>
                <Typography sx={styles.paragraph}>
                    I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.
                    II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática;
                    III – Proteção dos direitos da GO AHEAD em qualquer tipo de conflito, inclusive os de teor judicial.
                </Typography>
                <Typography sx={styles.bold}>
                    7 – Cookies ou dados de navegação
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD  faz uso de Cookies, que são arquivos de texto enviados pelo nosso servidor da web ao seu computador e que nele se armazenam as informações relacionadas à sua navegação em nosso site. Os Cookies são utilizados para aprimorar a sua experiência de uso.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Os cookies têm as seguintes classificações:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Essenciais: São cookies indispensáveis ao funcionamento de sua navegação no site e que depende das ações do usuário como preencher formulários, salvar logins e senhas, sem os quais tais funções não podem ser realizadas.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Funcionais: São cookies que personalizam as suas preferências, tornando a navegação personalizada ao seu perfil de usuário.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.
                </Typography>
                <Typography sx={styles.paragraph}>
                    A GO AHEAD utiliza os seguintes Cookies:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Cookies públicos: Google Analytics, Youtube, Twitter e Facebook
                    Cookies para usuários logados: Cookies de sessão
                </Typography>
                <Typography sx={styles.paragraph}>
                    Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos do site e aplicativo.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador na área de gestão de Cookies.
                </Typography>
                <Typography sx={styles.bold}>
                    8 - Conservação e eliminação de dados:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Conservamos os seus dados pessoais enquanto for necessário para prestação dos serviços educacionais da GO AHEAD e para fins comerciais, legítimos e essenciais, cumprir as nossas obrigações legais, e melhor atender as necessidades dos nossos usuários, alunos, funcionários, fornecedores e demais clientes.
                    Se formos obrigados a manter os dados pessoais para as nossas obrigações jurídicas, fiscais, de auditoria e contabilidade, iremos reter os dados pessoais necessários pelo período exigido pela legislação aplicável, sempre que necessário para os nossos legítimos interesses, como a prevenção contra fraudes ou para manter a segurança dos nossos usuários, alunos, funcionários, fornecedores e demais clientes.
                </Typography>
                <Typography sx={styles.bold}>
                    9 - Alterações da Política de Privacidade e Proteção de Dados
                </Typography>
                <Typography sx={styles.paragraph}>
                    Eventualmente, poderemos efetuar alterações em nossa Política e quando a fizermos comunicaremos de forma visível e adequada de acordo com as circunstâncias.
                </Typography>
                <Typography sx={styles.paragraph}>
                    Assim, é fundamental que todos titulares de dados pessoais se certifiquem de ler qualquer comunicação atentamente.
                </Typography>
                <Typography sx={styles.bold}>
                    10 – Disposições Gerais
                </Typography>
                <Typography sx={styles.paragraph}>
                    Quaisquer dúvidas, comentários, solicitações pedimos que entrem em contato conosco pelos seguintes canais de comunicação:
                </Typography>
                <Typography sx={styles.paragraph}>
                    Telefone da Escola: (51) 99366.1058
                </Typography>
                <Typography sx={styles.paragraph}>
                    Encarregado de dados: Fabiana Seidel
                </Typography>
                <Typography sx={styles.paragraph}>
                    E-mail: goahead@goaheadschool.com.br
                </Typography>
                <Typography mt={'1rem'} fontWeight={600} color={'primary'}>
                    {<AppVersion />}
                </Typography>
                <Box sx={styles.buttonContainer}>
                    <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/'} sx={{ width: '75%', mt: '1em', textTransform: 'uppercase' }}>
                        Voltar
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const styles = createTheme({
    centralize: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    container: {
        color: '#616161',
        width: '85%',
        marginTop: '20%',
        marginBottom: '20%',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '70%',
        color: 'white'
    },
    h1: {
        fontSize: 22,
        marginBottom: '5%',
        marginTop: '10%',
    },
    paragraph: {
        fontSize: 14,
        marginBottom: '5%',
    },
    bold: {
        fontWeight: '600',
        marginBottom: '5%',

    },
    cardMedia: { width: '90%', resizeMode: 'contain' },
})