import { useContext, useState } from "react"
import api from "../services/api"
import { UserContext } from "../context/UserProvider"

const useTesteNivelamento = ()=>{
    const {user } = useContext(UserContext)
    const [formSent, setFormSent ] = useState(false);
    const url = 'ws/teste-nivelamento'

    /**
     * @param {array} quizz teste de nivelamento
     * @param {array} answers respostas do usuário 
     * @param {string} lan título do teste.
     * @author Gui
     */
    const sendTest = (quizz,answers ,lan)=>{   
        setFormSent(true)
        const objeto_post = {
            aluno:user['nome'],
            quizz,
            answers,
            lan,
        }
        api.post(url, objeto_post)
    }

    /**
     * @param {number} part 
     * @param {function} setAnswers 
     */
    const handleAnswer = (part, setAnswers)=>{
        return (value, index)=>{
            setAnswers((previosValue)=>{
                previosValue[part][index] = value
                console.log(previosValue)
                return previosValue
            })
        }
    }

    return {sendTest, handleAnswer, formSent}
}

export default useTesteNivelamento;