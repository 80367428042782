import { Close, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, CircularProgress, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function AtividadeDetalhes() {

    const [data, setData] = useState([]);
    const [pdf, setPdf] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [title, setTitle] = useState('');

    const [openModal, setOpenModal] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false)

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleOpenVideoModal = () => setOpenVideoModal(true);
    const handleCloseVideoModal = () => setOpenVideoModal(false);

    const { id } = useParams();

    useEffect(() => {
        api.post('/ws/biblioteca-de-atividades-detalhes.php?id=' + id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.detalhe);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    const style = {
        modal: {
            zIndex: 2100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        box: {
            position: 'absolute',
            width: '80vw',
            height: '80vh',
            bgcolor: 'white',
            boxShadow: 24,
            borderRadius: '8px',
            p: '1rem',
            overflow: 'auto',
        },
        titulo: {
            padding: '20px',
            fontSize: '20px'
        },
        text: {
            textDecorationColor: 'purple',
            color: 'GrayText',
            fontSize: '14px',
            b: {
                color: 'black'
            },
            a: {
                color: '#0dd3f8'
            }
        },
        boxOutise: {
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        boxInside: {
            mt: '1em',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },

        iFrame: {
            width: '100%',
            height: '100%',
            border: 'none',
            borderRadius: '8px'
        },
        cardMedia: { width: '60%', mb: '1.5em' },
        typography: { width: '100%' },
        imageStyle: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' }
    };

    const openMobilePDF = (url, tipo, nome) => {
        if (window.cordova) {
            window.cordova.InAppBrowser.open(url, '_blank', 'location=yes')
        } else {

            if (tipo === 'pdf') {
                console.log('PDF: ' + url)
                setPdf(url);
                setTitle(nome);
                handleOpenModal();
            } else {
                console.log('Youtube: ' + url)
                setVideoUrl(url);
                setTitle(nome);
                handleOpenVideoModal();
            }
        };
    };

    return (
        <Box sx={style.boxOutise}>
            <CustomImageHandler props={{
                src: "https://gotheextramile.com.br/img/biblioteca-de-atividades.png",
                imageStyle: style.imageStyle,
                skeletonStyle: style.skeletonStyle
            }} />
            <Box sx={style.boxInside}>
                {data?.length ?
                    data.map(({ nivel, itens, index }) => {
                        return (
                            <Accordion key={index} defaultExpanded={data.length === 1}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}>
                                    <Typography fontWeight={'bold'}>
                                        {nivel}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {itens?.map(item => {

                                        let url = '';


                                        if (item.tipo === "pdf") {
                                            url = `https://goaheadschool.com.br/files/biblioteca/${item.bibli}/${item.nivel}/${item.source}`
                                        } else {
                                            url = item.source
                                        };

                                        return (
                                            <Button fullWidth onClick={() => { openMobilePDF(url, item.tipo, item.nome) }}>
                                                <Typography sx={style.typography} fontWeight="bold" textAlign="start" color="primary">
                                                    - {item.nome}
                                                </Typography>
                                            </Button>
                                        )
                                    })}
                                </AccordionDetails>
                            </Accordion>)
                    })
                    :
                    <CircularProgress />
                }
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={style.modal}>
                <Box sx={style.box}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={handleCloseModal} sx={{ position: 'fixed' }}>
                            <Close />
                        </IconButton>
                    </Box>
                    <iframe title={title} src={pdf} style={style.iFrame} />
                </Box>
            </Modal>
            <Modal
                open={openVideoModal}
                onClose={handleCloseVideoModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={style.modal}>
                <Box sx={style.box}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={handleCloseVideoModal} sx={{ position: 'fixed' }}>
                            <Close />
                        </IconButton>
                    </Box>
                    <iframe src={videoUrl} title={title} style={style.iFrame} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Box>
            </Modal>
        </Box>
    );
};