import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon, ListItemText, useTheme } from '@mui/material';
import navData from '../data/navData';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from './UserProvider';
import { ExitToApp } from '@mui/icons-material';
import Menu from '../components/Menu';
import NotificationBar from '../components/NotificationBar';
import BackNavigation from '../components/BackNavigation';
import OnlyToSignedUsers from '../components/OnlyToSignedUsers';

export const ComponentContext = React.createContext({});

export default function ComponentProvider({ children }) {

    const theme = useTheme();

    const { signOut } = React.useContext(UserContext);

    const [isOpen, changeDrawer] = React.useState(false);

    const route = useLocation();

    const colorByRoute = (path) => {
        return path === route.pathname ? theme.palette.secondary.main : theme.palette.primary.main
    };

    function sideBar() {
        return (
            <Drawer
                anchor={'right'}
                open={isOpen}
                onClose={() => { changeDrawer(false) }}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                sx={{
                    zIndex: 2000
                }}
            >
                <Box
                    sx={{
                        width: 250,
                    }}
                    role="presentation"
                >
                    <List>
                        {navData.map((obj) => (
                            <ListItem key={obj.id} disablePadding>
                                <ListItemButton
                                    key={obj.path}
                                    component={Link}
                                    to={obj.path}
                                    onClick={() => { changeDrawer(false) }}>
                                    <ListItemIcon sx={{ color: colorByRoute(obj.path) }}>
                                        {obj.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={obj.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => { signOut(); changeDrawer(false) }}>
                                <ListItemIcon>
                                    <ExitToApp color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={'Sign out'} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                target='_blank'
                                to={'https://dedstudio.com.br/'}
                                onClick={() => { changeDrawer(false) }}>
                                <ListItemText primary={'Desenvolvido por DeD Studio'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        )
    };

    return (
        <ComponentContext.Provider
            value={{
                changeDrawer,
            }}>
            <NotificationBar />
            <BackNavigation />
            <OnlyToSignedUsers component={sideBar()} />
            {children}
            <Menu />
        </ComponentContext.Provider>
    );
};