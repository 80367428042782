import { useState } from "react";
import { Box, Button, CardMedia, FormControl, Typography } from "@mui/material"
//componentes
import QuestionNivelamentoHandler from "../../components/QuestionNivelamentoHandler";
import CustomImageHandler from "../../components/CustomImageHandler";

//data 
import partes from "../../data/testeNivelamentoFrances";
import useTesteNivelamento from "../../hooks/useSendTesteNivelamento";

const TesteNivelamentoFrances = ()=>{
    const {sendTest, handleAnswer, formSent} = useTesteNivelamento()
    const [answers, setAnswers] = useState([
        [0,0,0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0,0],
        [0,0,0,0,0,0,0,0,0,0,]
    ]);

    const handleSubmit = (event) => {
        event.preventDefault();
        sendTest(partes, answers, 'Francês')
    };

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        },
        formSent:{
            color: 'green',
        },
        skeleton:{
            width: '100%',
            height: '30vh'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h2">Francês</Typography>
            <Typography> Teste de nivelamento </Typography>
            <CustomImageHandler props={{
                src:'https://gotheextramile.com.br/img/frances-title.png',
                imageStyle:styles.cardMedia,
                skeletonStyle:styles.skeleton
            }} />
            {/* <CardMedia
            component="img"
            image="https://gotheextramile.com.br/img/frances-title.png"
            alt=""
            sx={styles.cardMedia} /> */}
            <FormControl>
                {
                    partes.map(({title, questions}, index)=>{
                        return(
                            <Box key={index}>
                                <Typography>{title}</Typography>
                                <QuestionNivelamentoHandler
                                questions={questions}
                                answerHandler={handleAnswer(index, setAnswers)} />
                            </Box>
                        )
                    })
                }
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                variant="contained"
                size="large"
                type='submit'
                disabled={formSent}
                onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}

export default TesteNivelamentoFrances