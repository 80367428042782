import AssessmentPlus from '../screens/AssessmentPlus';
import Balloons from '../screens/Balloons';
import Development from '../screens/Development';
import EditProfile from '../screens/EditProfile';
import Faq from '../screens/Faq';
import Financeiro from '../screens/Financeiro';
import HangMan from '../screens/HangMan';
import Home from '../screens/Home';
import IdiomPhrasalVerb from '../screens/IdiomPhrasalVerb';
import IdiomPhrasalVerbDetails from '../screens/IdiomPhrasalVerbDetails';
import InfomacoesPedagogicas from '../screens/InfomacoesPedagogicas';
import Journal from '../screens/Journal';
import JournalPost from '../screens/JournalPost';
import Login from '../screens/Login';
import OurTeam from '../screens/OurTeam';
import PlanosDeCurso from '../screens/PlanosDeCurso';
import PlanosDeCursoEspanhol from '../screens/PlanosDeCurso/PlanosDeCursoEspanhol';
import PlanosDeCursoGoKids from '../screens/PlanosDeCurso/PlanosDeCursoGoKids';
import PlanosDeCursoGoTeens from '../screens/PlanosDeCurso/PlanosDeCursoGoTeens';
import PlanosDeCursoIngles from '../screens/PlanosDeCurso/PlanosDeCursoIngles';
import PrivacyPolicies from '../screens/PrivacyPolicies';
import SunnysReadersGang from '../screens/SunnysReadersGang';
import Teacher from '../screens/Teacher';
import Team from '../screens/Team';
import TesteDeNivelamento from '../screens/TesteDeNivelamento';
import TesteDeNivelamentoIngles from '../screens/TesteDeNivelamento/TesteDeNivelamentoIngles';
import Wes from '../screens/Wes';
import YourMiles from '../screens/YourMiles';
import WeDescription from '../screens/WeDescription';
import HangManGame from '../screens/HangManPlay';
import NotificationDetails from '../screens/NotificationDetails';
import BibliotecaDeAtividades from '../screens/BibliotecaDeAtividades';
import AtividadeDetalhes from '../screens/AtividadeDetalhes';
import MinhaAgenda from '../screens/MinhaAgenda';
import TapAndLearn from '../screens/TapAndLearn';
import Quiz from '../screens/Quiz';
import TesteDeNivelamentoGoTeens from '../screens/TesteDeNivelamento/TesteDeNivelamentoGoTeens';
import TesteDeNivelamentoAlemao from '../screens/TesteDeNivelamento/TesteDeNivelamentoAlemao';
import TesteNivelamentoFrances from '../screens/TesteDeNivelamento/TesteDeNivelamentoFrances';
import TesteDeNivelamentoEspanhol from '../screens/TesteDeNivelamento/TesteDeNivelamentoEspanhol';
import TesteDeNivelamentoPortugues from '../screens/TesteDeNivelamento/TesteDeNivelamentoPortugues';
import ProgressTestList from '../screens/ProgressTestList';
import ProgressTest from '../screens/ProgressTest';
import ProficiencyTests from '../screens/ProficiencyTests';
import ProficiencyQuiz from '../screens/ProficiencyQuizz';
import LearningRoute from '../screens/LearningRoute';
import ResetPassword from '../screens/ResetPassword';

const routes = [
  { path: '/', isPrivate: true, loggedComponent: <Home />, defaultComponent: <Login /> },
  { path: '/reset-password', isPrivate: true, loggedComponent: <Home />, defaultComponent: <ResetPassword /> },
  { path: '/Home', isPrivate: true, loggedComponent: <Home />, defaultComponent: <Login /> },
  { path: '/Login', isPrivate: false, loggedComponent: <Home />, defaultComponent: <Login /> },
  { path: '/Development', isPrivate: true, loggedComponent: <Development />, defaultComponent: <Development /> },
  { path: '/PrivacyPolicies', isPrivate: true, loggedComponent: <PrivacyPolicies />, defaultComponent: <PrivacyPolicies /> },
  { path: '/EditProfile', isPrivate: true, loggedComponent: <EditProfile />, defaultComponent: <Home /> },
  { path: '/MinhaAgenda', isPrivate: true, loggedComponent: <MinhaAgenda />, defaultComponent: <Home /> },
  { path: '/InfomacoesPedagogicas', isPrivate: true, loggedComponent: <InfomacoesPedagogicas />, defaultComponent: <Home /> },
  { path: '/YourMiles', isPrivate: true, loggedComponent: <YourMiles />, defaultComponent: <Home /> },
  { path: '/LearningRoute/:idTurma', isPrivate: true, loggedComponent: <LearningRoute />, defaultComponent: <Home /> },
  { path: '/Financeiro', isPrivate: true, loggedComponent: <Financeiro />, defaultComponent: <Home /> },
  { path: '/Balloons', isPrivate: true, loggedComponent: <Balloons />, defaultComponent: <Home /> },
  { path: '/TapAndLearn', isPrivate: true, loggedComponent: <TapAndLearn />, defaultComponent: <Home /> },
  { path: '/Quiz/:id', isPrivate: true, loggedComponent: <Quiz />, defaultComponent: <Home /> },
  { path: '/IdiomPhrasalVerb', isPrivate: true, loggedComponent: <IdiomPhrasalVerb />, defaultComponent: <Home /> },
  { path: '/IdiomPhrasalVerbDetails/:id', isPrivate: true, loggedComponent: <IdiomPhrasalVerbDetails />, defaultComponent: <Home /> },
  { path: '/HangMan', isPrivate: true, loggedComponent: <HangMan />, defaultComponent: <Home /> },
  { path: '/HangManGame', isPrivate: true, loggedComponent: <HangManGame />, defaultComponent: <Home /> },
  { path: '/BibliotecaDeAtividades/:id', isPrivate: true, loggedComponent: <BibliotecaDeAtividades />, defaultComponent: <Home /> },
  { path: '/AtividadeDetalhes/:id', isPrivate: true, loggedComponent: <AtividadeDetalhes />, defaultComponent: <Home /> },
  { path: '/SunnysReadersGang', isPrivate: true, loggedComponent: <SunnysReadersGang />, defaultComponent: <Home /> },
  { path: '/PlanosDeCurso', isPrivate: true, loggedComponent: <PlanosDeCurso />, defaultComponent: <Home /> },
  { path: '/PlanosDeCursoIngles', isPrivate: true, loggedComponent: <PlanosDeCursoIngles />, defaultComponent: <Home /> },
  { path: '/PlanosDeCursoEspanhol', isPrivate: true, loggedComponent: <PlanosDeCursoEspanhol />, defaultComponent: <Home /> },
  { path: '/PlanosDeCursoGoKids', isPrivate: true, loggedComponent: <PlanosDeCursoGoKids />, defaultComponent: <Home /> },
  { path: '/PlanosDeCursoGoTeens', isPrivate: true, loggedComponent: <PlanosDeCursoGoTeens />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamento', isPrivate: true, loggedComponent: <TesteDeNivelamento />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoIngles', isPrivate: true, loggedComponent: <TesteDeNivelamentoIngles />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoGoTeens', isPrivate: true, loggedComponent: <TesteDeNivelamentoGoTeens />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoAlemao', isPrivate: true, loggedComponent: <TesteDeNivelamentoAlemao />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoFrances', isPrivate: true, loggedComponent: <TesteNivelamentoFrances />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoEspanhol', isPrivate: true, loggedComponent: <TesteDeNivelamentoEspanhol />, defaultComponent: <Home /> },
  { path: '/TesteDeNivelamentoPortugues', isPrivate: true, loggedComponent: <TesteDeNivelamentoPortugues />, defaultComponent: <Home /> },
  { path: '/progressTests', isPrivate: true, loggedComponent: <ProgressTestList />, defaultComponent: <Home /> },
  { path: '/ProgressTest/:id', isPrivate: true, loggedComponent: <ProgressTest />, defaultComponent: <Home /> },
  { path: '/ProficiencyTests', isPrivate: true, loggedComponent: <ProficiencyTests />, defaultComponent: <Home /> },
  { path: '/ProficiencyQuizz/:nome', isPrivate: true, loggedComponent: <ProficiencyQuiz />, defaultComponent: <Home /> },
  { path: '/AssessmentPlus', isPrivate: true, loggedComponent: <AssessmentPlus />, defaultComponent: <Home /> },
  { path: '/Wes', isPrivate: true, loggedComponent: <Wes />, defaultComponent: <Home /> },
  { path: '/WeDescription/:id', isPrivate: true, loggedComponent: <WeDescription />, defaultComponent: <Home /> },
  { path: '/Journal', isPrivate: true, loggedComponent: <Journal />, defaultComponent: <Home /> },
  { path: '/JournalPost/:id', isPrivate: true, loggedComponent: <JournalPost />, defaultComponent: <Home /> },
  { path: '/Team/:id', isPrivate: true, loggedComponent: <Team />, defaultComponent: <Home /> },
  { path: '/OurTeam', isPrivate: true, loggedComponent: <OurTeam />, defaultComponent: <Home /> },
  { path: '/Teacher/:id', isPrivate: true, loggedComponent: <Teacher />, defaultComponent: <Home /> },
  { path: '/Faq', isPrivate: true, loggedComponent: <Faq />, defaultComponent: <Home /> },
  { path: '/NotificationDetails/:id', isPrivate: true, loggedComponent: <NotificationDetails />, defaultComponent: <Home /> },
]

export default routes;