import { Box, Button, CardMedia, FormControl, Typography } from "@mui/material";
import { useState } from "react";

import partes from '../../data/testeNivelamentoIngles'
import TesteNivelamentoParte from "../../components/TesteNivelamentoParte";
import CustomImageHandler from "../../components/CustomImageHandler";

import useTesteNivelamento from "../../hooks/useSendTesteNivelamento";

export default function TesteDeNivelamentoIngles() {
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento();
    const [answers, setAnswers] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])

    const handleSubmit = (event) => {
        event.preventDefault()
        sendTest(partes, answers, 'Inglês')
    }

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        formControl: {
            padding: '10px',
            marginBotton: '10px',
        },
        formSent: {
            color: 'green',
        },
        skeleton: {
            width: '100%',
            height: '30vh'
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h2"> INGLÊS </Typography>
            <Typography> Teste de nivelamento </Typography>
            <CustomImageHandler props={{
                src: 'https://gotheextramile.com.br/img/ingles-title.png',
                skeletonStyle: styles.skeleton,
                imageStyle: styles.cardMedia
            }} />
            <Typography variant="h2">Choose the correct alternative:</Typography>
            <FormControl sx={styles.formControl}>
                {partes.map(({ title, message, typeFile1, typeFile2, srcFile1, srcFile2, questions, }, index) => (
                    <TesteNivelamentoParte
                        key={title + index}
                        title={title}
                        message={message}
                        part={index}
                        typeFile1={typeFile1}
                        typeFile2={typeFile2}
                        srcFile1={srcFile1}
                        srcFile2={srcFile2}
                        questions={questions}
                        AnswerHandler={handleAnswer(index, setAnswers)} />
                ))}
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                    variant="contained"
                    size="large"
                    type='submit'
                    disabled={formSent}
                    onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}