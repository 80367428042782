const homeData = [
    {
        id: 1,
        tipo: [1, 2, 3],
        path: 'EditProfile',
        icon: 'https://gotheextramile.com.br/img/appIcons/meu-cadastro.png'
    },
    {
        id: 2,
        tipo: [1, 2, 3],
        path: 'MinhaAgenda',
        icon: 'https://gotheextramile.com.br/img/appIcons/minha-agenda.png'
    },
    {
        id: 3,
        tipo: [1, 3],
        path: 'InfomacoesPedagogicas',
        icon: 'https://gotheextramile.com.br/img/appIcons/informacoes-pedagogicas.png'
    },
    {
        id: 4,
        tipo: [1, 3],
        path: 'YourMiles',
        icon: 'https://gotheextramile.com.br/img/appIcons/your-miles.png'
    },
    {
        id: 5,
        tipo: [1, 3],
        path: 'Financeiro',
        icon: 'https://gotheextramile.com.br/img/appIcons/financeiro.png'
    },
    {
        id: 6,
        tipo: [1, 2, 3],
        path: 'Balloons',
        icon: 'https://gotheextramile.com.br/img/appIcons/balloon-bank.png'
    },
    {
        id: 7,
        tipo: [1, 3],
        path: 'TapAndLearn',
        icon: 'https://gotheextramile.com.br/img/appIcons/tap-and-learn.png'
    },
    {
        id: 8,
        tipo: [1, 2, 3],
        path: 'IdiomPhrasalVerb',
        icon: 'https://gotheextramile.com.br/img/appIcons/idiom-phrasal-verb-of-the-day.png'
    },
    {
        id: 10,
        tipo: [1, 2, 3],
        path: 'HangMan',
        icon: 'https://gotheextramile.com.br/img/Hangman-quadrada.png'
    },
    {
        id: 10,
        tipo: [1, 2, 3],
        path: 'BibliotecaDeAtividades/English',
        icon: 'https://gotheextramile.com.br/img/appIcons/biblioteca-de-atividades.png'
    },
    {
        id: 11,
        tipo: [1, 2, 3],
        path: 'SunnysReadersGang',
        icon: 'https://gotheextramile.com.br/img/appIcons/sunny-s-readers-gang.png'
    },
    {
        id: 12,
        tipo: [2, 3],
        path: 'Development',
        icon: 'https://gotheextramile.com.br/img/icons/first-class-activities.png'
    },
    {
        id: 13,
        tipo: [2, 3],
        path: 'Development',
        icon: 'https://gotheextramile.com.br/img/icons/book-plans.png'
    },
    {
        id: 14,
        tipo: [1, 2, 3],
        path: 'PlanosDeCurso',
        icon: 'https://gotheextramile.com.br/img/appIcons/planos-de-curso.png'
    },
    {
        id: 15,
        tipo: [1, 2, 3],
        path: 'TesteDeNivelamento',
        icon: 'https://gotheextramile.com.br/img/appIcons/teste-de-nivelamento.png'
    },
    {
        id: 16,
        tipo: [1, 2, 3],
        path: 'progressTests',
        icon: 'https://gotheextramile.com.br/img/appIcons/progress-tests.png'
    },
    {
        id: 17,
        tipo: [1, 2, 3],
        path: 'ProficiencyTests',
        icon: 'https://gotheextramile.com.br/img/appIcons/proficiency-tests.png'
    },
    {
        id: 18,
        tipo: [1, 2, 3],
        path: 'AssessmentPlus',
        icon: 'https://gotheextramile.com.br/img/appIcons/assessment-plus.png'
    },
    {
        id: 19,
        tipo: [1, 2, 3],
        path: 'Wes',
        icon: 'https://gotheextramile.com.br/img/appIcons/wes.png'
    },
    {
        id: 20,
        tipo: [1, 2, 3],
        path: 'Journal',
        icon: 'https://gotheextramile.com.br/img/appIcons/journal.png'
    },
    {
        id: 21,
        tipo: [1, 3],
        path: 'OurTeam',
        icon: 'https://gotheextramile.com.br/img/appIcons/our-team.png'
    },
    {
        id: 22,
        tipo: [1, 2, 3],
        path: 'Faq',
        icon: 'https://gotheextramile.com.br/img/appIcons/faq.png'
    },
];

export default homeData;