import { useContext } from "react";
import { UserContext } from "../context/UserProvider";

export default function OnlyToSignedUsers({component}) {

    const { user } = useContext(UserContext);

    if (user) {
        return component;
    };
}