import { Box, Button, CardMedia, FormControl, Typography } from "@mui/material"
import { useState } from "react";
//componentes
import QuestionNivelamentoHandler from "../../components/QuestionNivelamentoHandler";
import CustomImageHandler from "../../components/CustomImageHandler";
//data
import partes from "../../data/testeNivelamentoAlemao";
import useTesteNivelamento from "../../hooks/useSendTesteNivelamento";

const TesteDeNivelamentoAlemao = () => {
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento()
    const [answers, setAnswers] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ]);

    const handleSubmit = (event) => {
        event.preventDefault()
        sendTest(partes, answers, 'Alemão')
    }

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
            fontFamily: 'sans-serif'
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        },
        formSent: {
            color: 'green',
        },
        skeleton: {
            width: '100%',
            height: '30vh'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h2">ALEMÃO</Typography>
            <Typography> Teste de nivelamento </Typography>
            <CustomImageHandler props={{
                src: 'https://gotheextramile.com.br/img/alemao-title.png',
                skeletonStyle: styles.skeleton
            }} />
            <FormControl>
                {partes.map(({ questions }, index) => (
                    <QuestionNivelamentoHandler
                        questions={questions}
                        answerHandler={handleAnswer(index, setAnswers)}
                        key={index} />
                ))}
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                    variant="contained"
                    size="large"
                    type='submit'
                    disabled={formSent}
                    onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}

export default TesteDeNivelamentoAlemao