import api from "../services/api";

function oneSignal(id, tipo_usuario, onesignal) {

    const payload = { id_usuario: id, tipo_usuario: tipo_usuario, id_onesignal: onesignal };

    api.post('ws/usuarios-onesignal.php', payload)
        .then(res => {
            console.log(JSON.stringify(res.data));
        })
        .catch(error => {
            // alert("ERRO: " + error);
        });
};

export function oneSignalPush(id, tipo_usuario) {

    if (window.cordova) {


        window.plugins.OneSignal.initialize("1c1ded7d-c02d-4051-a6dc-c392a50e216c");


        window.plugins.OneSignal.Notifications.requestPermission(true).then((accepted) => {

            console.log("User accepted notifications: " + accepted);
        });

        // alert(JSON.stringify(window.plugins.OneSignal.User));

        window.plugins.OneSignal.addSubscriptionObserver((state) => {

            if (!state.from.isSubscribed && state.to.isSubscribed) {

                console.log("Subscribed for OneSignal push notifications!")
                // get player ID

                let onesignal = state.to.userId;

                oneSignal(id, tipo_usuario, onesignal);

            };
            console.log("Push Subscription state changed: " + JSON.stringify(state));
        });
    };
};