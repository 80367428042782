import React, { useEffect, useState } from 'react';

const LinkOpener = ({ children }) => {
    const [linkOpened, setLinkOpened] = useState(false);
    const [linkHref, setLinkHref] = useState('');

    useEffect(() => {
        const openLinkInInAppBrowser = () => {
            if (linkOpened) {
                window.cordova.InAppBrowser.open(linkHref, '_blank', 'location=yes');
            };
        };

        openLinkInInAppBrowser();
    }, [linkOpened, linkHref]);

    const handleLinkClick = (event) => {
        event.preventDefault();
        const link = event.target.getAttribute('href');
        if (link) {
            setLinkOpened(true);
            setLinkHref(link);
        }
    };

    return (
        <div onClick={handleLinkClick}>
            {children}
        </div>
    );
};

export default LinkOpener;