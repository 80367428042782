import CustomImageHandler from "../components/CustomImageHandler";
import {
  React,
  useState,
  useEffect,
  useContext,
  createRef
} from 'react';

import api from '../services/api';
import { UserContext } from '../context/UserProvider';
import { Box, Button, CardMedia, Checkbox, createTheme, Divider, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { Clear, Delete, Edit, PhotoCamera } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AppVersion from "../components/AppVersion";

export default function EditProfile() {
  const imageInputRef = createRef()

  const { user, tipoConta, idUser, signIn } = useContext(UserContext);

  const [id, setId] = useState('');
  const [enviandoForm, setEnviandoForm] = useState(false)

  const [message, setMessage] = useState('');
  const [data, setData] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [celular, setCelular] = useState('');
  const [f_residencial, setFResidencial] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [sexo, setSexo] = useState('');
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [c_conheceu, setConheceu] = useState('');
  const [skype, setSkype] = useState('');
  const [CPF, setCPF] = useState('');
  const [RG, setRG] = useState('');
  const [filhos, setFilhos] = useState('');
  const [pai, setPai] = useState('');
  const [mae, setMae] = useState('');
  const [endereco, setEndereco] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [CEP, setCEP] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [end_comercial, setEndComercial] = useState('');
  const [comp_comercial, setCompComercial] = useState('');
  const [bairro_comercial, setBairroComercial] = useState(''); //
  const [CEP_comercial, setCEPComercial] = useState('');
  const [cid_comercial, setCidComercial] = useState('');
  const [est_comercial, setEstComercial] = useState('');
  const [fone_comercial, setFoneComercial] = useState('');
  const [ramal, setRamal] = useState('');
  const [userImage, setUserImage] = useState('https://gotheextramile.com.br/img/avatar/profile.png');
  const [infoMail, setInfoMail] = useState('');
  const [senha, setSenha] = useState('');
  const [conf_senha, setConfSenha] = useState('');
  const [authMail, setAuthMail] = useState('');


  const required = [nome, CPF, RG, sexo, filhos, nascimento, c_conheceu, celular]

  useEffect(() => {
    setId(idUser)
    setData(user);
    setNome(user.nome);
    setSobrenome(user.nome);
    setCelular(user.celular);
    setFResidencial(user.fone_residencial);
    setNascimento(user.data_nasc);
    setSexo(user.sexo);
    setEmail1(user.email1);
    setEmail2(user.email2);
    setConheceu(user.como_conheceu);
    setSkype(user.skype);
    setCPF(user.cpf);
    setRG(user.rg);
    setFilhos(user.filhos);
    setPai(user.nome_pai);
    setMae(user.nome_mae);
    setEndereco(user.endereco);
    setComplemento(user.complemento);
    setBairro(user.bairro);
    setCEP(user.cep);
    setCidade(user.cidade);
    setEstado(user.estado);
    setEmpresa(user.empresa);
    setDepartamento(user.departamento);
    setEndComercial(user.endereco_comercial);
    setCompComercial(user.complemento_comercial);
    setBairroComercial(user.bairro_comercial);
    setCEPComercial(user.cep_comercial);
    setCidComercial(user.cidade_comercial);
    setFoneComercial(user.fone_comercial);
    setRamal(user.ramal);
    setAuthMail(user.status_2fa_email);
    setInfoMail(user.avisos_notificacoes);

    if (tipoConta == 1) {
      setUserImage(`https://gotheextramile.com.br/img/avatar/students/${idUser}_160x160.jpg`)
    } else if (tipoConta == 2) {
      setUserImage(`https://gotheextramile.com.br/img/avatar/users/${idUser}_160x160.jpg`)
    }
  }, [data]);

  const navigate = useNavigate();

  const loadInfoUsuario = () => {
    const data = {
      id_usuario: idUser,
      nome: nome,
      tipo_conta: tipoConta,
      user: {
        ...user,
        bairro: bairro,
        bairro_comercial,
        celular: celular,
        cep: CEP,
        cidade: cidade,
        complemento: complemento,
        como_conheceu: c_conheceu,
        cpf: CPF,
        data_nasc: nascimento,
        departamento: departamento,
        email1: email1,
        email2: email2,
        empresa: empresa,
        endereco: endereco,
        endereco_comercial: end_comercial,
        estado: estado,
        fone_comercial: fone_comercial,
        fone_residencial: f_residencial,
        filhos: filhos,
        nome: nome,
        nome_mae: mae,
        nome_pai: pai,
        rg: RG,
        sexo: sexo,
        skype: skype
      }
    }
    signIn(data)
  }

  useEffect(() => {
    var objSend = {
      id: idUser,
      img: userImage
    }
    if (userImage.includes('https')) return;
    axios.post('https://gotheextramile.com.br/ws/upload-image.php', objSend)
      .then((res) => {
        if (res.data.success)
          setUserImage(`https://gotheextramile.com.br/img/avatar/users/${idUser}_160x160.jpg`)
      });
  }, [userImage]);

  const onSuccess = (imageData) => {
    let picSrc = "data:image/jpeg;base64," + imageData;
    setUserImage(picSrc);
  }

  const onFail = (message) => {
    message.error("Ops! Ocorreu um erro com a câmera: " + message, 6)
  }

  const takePicture = (event) => {
    const destinationType = navigator.camera.DestinationType;
    event.preventDefault();
    navigator.camera.getPicture(onSuccess, onFail, {
      quality: 30,
      correctOrientation: true,
      allowEdit: false,
      destinationType: destinationType.DATA_URL
    });
  };

  const executeSet = () => {
    const objSend = {
      idalunos: idUser,
      nome,
      nascimento,
      sexo,
      como_conheceu: c_conheceu,
      cpf: CPF,
      rg: RG,
      filhos,
      pai,
      mae,
      endereco,
      complemento,
      bairro,
      CEP,
      cidade,
      estado,
      celular,
      f_residencial,
      email1,
      email2,
      skype,
      empresa,
      departamento,
      end_comercial,
      comp_comercial,
      bairro_comercial,
      cid_comercial,
      est_comercial,
      CEP_comercial,
      fone_comercial,
      ramal,
      senha,
      conf_senha,
      authMail,
      tipoConta,
      //tirei pra não ficar dando erro no back-end no resto dos testes
    }

    if (tipoConta == 1) {
      api.post('ws/edit-profile', objSend)
        .then((res) => {
          alert('Edições feitas com sucesso')
          loadInfoUsuario()
          navigate('/')
        })
        .catch((error) => {
          alert("verifique sua conexão com a internet ou tente novamente mais tarde")
        }).finally(() => {
          setEnviandoForm(false)
        })
    }
    else {
      alert("O seu usuário não pode realizar edições.")
    };
  };

  const deleteImg = (evt) => {
    setUserImage('')
  }

  const onFileChange = (e) => {
    let files = e.target.files
    let fileReader = new FileReader()
    fileReader.readAsDataURL(files[0])

    fileReader.onload = (event) => {
      setUserImage(event.target.result)
      // setNewImg(true)
    }
  }

  const submitChange = (evt) => {
    evt.preventDefault()
    const ready = checkRequiredFields()
    if (ready) {
      setEnviandoForm(true)
      executeSet()
    } else {
      alert('preencha todos os campos obrigatórios.')
    }
  };

  function deleteAccount() {

    let certificado_individual = CPF;
    let id_usuario = id;

    let payload = { certificado_individual, id_usuario }

    api.post("ws/excluir-conta.php", payload)
      .then(res => {
        if (res.data.success) {
          alert('Sua solicitação foi enviada com sucesso! Nosso consultor entrará em contato em até 24h.');
        } else {
          alert('Houve um erro ao solicitar a exclusão de sua conta! Tente novamente mais tarde.');
        };
      }).catch(err => {
        alert('Houve um erro ao solicitar a exclusão de sua conta! Tente novamente mais tarde.');
        console.log(err);
      });
  };

  const checkRequiredFields = () => {
    const allfilled = required.reduce((previousValue, currentValue) => {
      if (previousValue) {
        return currentValue !== ''
      } else {
        return previousValue
      }
    }, true)
    return allfilled
  }


  return (
    <Box sx={styles.centralize}>
      <Box sx={styles.userProfileBox}>
        <Box sx={{ bgcolor: '#0098da', borderRadius: '50%', p: '3px' }}>
          <CardMedia sx={styles.userProfile} image={userImage} />
        </Box>
        <Box sx={styles.editButton}>
          <Button
            id='edit-image'
            color="secondary" variant="contained"
            onClick={takePicture}
            sx={styles.leftButton}>
            <PhotoCamera />
          </Button>
          <Button
            id='delete-image'
            color="primary" variant="contained"
            onClick={deleteImg}
            sx={styles.rightButton}>
            <Delete />
          </Button>
        </Box>
        {tipoConta == 1 &&
          <Box onSubmit={submitChange} sx={{ width: '100%' }}>
            <TextField
              label="Nome"
              sx={styles.textField}
              required={true}
              value={'' || nome}
              onChange={(e) => setNome(e.target.value)}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />

            <TextField
              sx={styles.textField}
              required={true}
              label="Nascimento"
              value={'' || nascimento}
              onChange={(e) => setNascimento(e.target.value)}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />

            <TextField
              value={sexo}
              onChange={(e) => setSexo(e.target.value)}
              select
              sx={styles.textField}
              required={true}
              label="Sexo">
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Feminino">Feminino</MenuItem>
            </TextField>

            <TextField
              label="Como você conheceu a GO AHEAD"
              required={true}
              sx={styles.textField}
              value={'' || c_conheceu} onChange={(e) => setConheceu(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField
              sx={styles.textField}
              label="CPF"
              value={'' || CPF}
              required={true}
              onChange={(e) => setCPF(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField
              sx={styles.textField}
              label="RG"
              value={'' || RG}
              required={true}
              onChange={(e) => setRG(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />


            <TextField
              value={filhos}
              required={true}
              onChange={(e) => setFilhos(e.target.value)}
              select
              sx={styles.textField}
              label="Filhos">
              <MenuItem value={1}>Sim</MenuItem>
              <MenuItem value={2}>Não</MenuItem>
            </TextField>

            <TextField
              sx={styles.textField}
              label="Filiação / Pai"
              value={'' || pai}
              onChange={(e) => setPai(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField
              sx={styles.textField}
              label="Filiação / Mãe" value={'' || mae} onChange={(e) => setMae(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField sx={styles.textField} label="Endereço residencial" value={'' || endereco} onChange={(e) => setEndereco(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Complemento" value={'' || complemento} onChange={(e) => setComplemento(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Bairro" value={'' || bairro} onChange={(e) => setBairro(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="CEP" value={'' || CEP} onChange={(e) => setCEP(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Cidade" value={'' || cidade} onChange={(e) => setCidade(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Estado" value={'' || estado} onChange={(e) => setEstado(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField
              required={true}
              sx={styles.textField}
              label="Celular"
              value={'' || celular}
              onChange={(e) => setCelular(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField sx={styles.textField} label="Telefone residencial" value={'' || f_residencial} onChange={(e) => setFResidencial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField
              sx={styles.textField}
              label="Email 1 (Profissional / Pessoal)"
              required={true}
              value={'' || email1}
              onChange={(e) => setEmail1(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField sx={styles.textField} label="Email 2 (Profissional / Pessoal)" value={'' || email2} onChange={(e) => setEmail2(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Skype" value={'' || skype} onChange={(e) => setSkype(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Empresa onde trabalha" value={'' || empresa} onChange={(e) => setEmpresa(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Departamento" value={'' || departamento} onChange={(e) => setDepartamento(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField
              sx={styles.textField}
              label="Endereço Comercial"
              value={'' || end_comercial}
              onChange={(e) => setEndComercial(e.target.value)} InputProps={{
                endAdornment:
                  <InputAdornment position="end">{<Edit />}</InputAdornment>
              }} />
            <TextField sx={styles.textField} label="Complemento" value={'' || comp_comercial} onChange={(e) => setCompComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Bairro" value={'' || bairro_comercial} onChange={(e) => setBairroComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="CEP" value={'' || CEP_comercial} onChange={(e) => setCEPComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Cidade" value={'' || cid_comercial} onChange={(e) => setCidComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Estado" value={'' || est_comercial} onChange={(e) => setEstComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={styles.textField} label="Telefone Comercial" value={'' || fone_comercial} onChange={(e) => setFoneComercial(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={{ ...styles.textField, mb: '1.5em' }} label="Ramal" value={'' || ramal} onChange={(e) => setRamal(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />

            <Divider />

            <Typography sx={{ mt: '1em' }} variant='h2'>Senha</Typography>

            <TextField sx={styles.textField} label="Senha" value={'' || senha} onChange={(e) => setSenha(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />
            <TextField sx={{ ...styles.textField, mb: '1.5em' }} label="Confirmar Senha" value={'' || conf_senha} onChange={(e) => setConfSenha(e.target.value)} InputProps={{
              endAdornment:
                <InputAdornment position="end">{<Edit />}</InputAdornment>
            }} />

            <Divider />

            <Typography sx={{ mt: '1em', mb: '1em' }} variant='h2'>Privacidade e Segurança</Typography>

            <Box sx={styles.checkboxContainer}>
              <Checkbox
                disabled={false}
                value={authMail == 1 ? true : false}
                onChange={(newValue) => authMail(newValue == true ? 1 : 0)}
              />
              <Typography>Autenticação por e-mail</Typography>
            </Box>
            <Button onClick={submitChange} color="secondary" variant="contained" type='submit' fullWidth sx={{ mt: '1.5em' }} disabled={enviandoForm}>
              Editar cadastro
            </Button>
            <Button startIcon={<Clear />} onClick={deleteAccount} color="primary" variant="text" sx={{ mt: '1.5em', fontSize: '.8rem' }} >
              Caso queira excluir sua conta, clique aqui
            </Button>
          </Box>
        }
        {message && <Typography>{message}</Typography>}
        <Typography mt={'1rem'} fontWeight={600} color={'primary'}>
          {<AppVersion />}
        </Typography>
      </Box>
    </Box>
  )
}

const styles = createTheme({
  centralize: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '2em 1em 5em 1em ',
  },
  userProfile: {
    height: 150,
    width: 150,
    borderWidth: 2,
    borderColor: '#0098da',
    backgroundImage: 'url(https://gotheextramile.com.br/img/avatar/profile.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '50%'
  },
  userProfileBox: {
    marginTop: '1.5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    margin: 10,
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1.5em'
  },
  leftButton: {
    width: '30%',
    height: 40,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rightButton: {
    width: '30%',
    height: 40,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textField: {
    mt: '1.5em',
    width: '100%'
  },
  imageStyle: {
    width: {
      xs: '60%',
      sm: '30rem'
    },
    mb: '1.5em'
  },
  skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' },
  fileInput: { display: 'none' }
})