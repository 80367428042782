import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Button, Divider, Typography } from '@mui/material';
import { UserContext } from './UserProvider';
import api from '../services/api';
import { CalendarMonth, NotificationsNone } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import OnlyToSignedUsers from '../components/OnlyToSignedUsers';

export const NotificationContext = React.createContext({});

export default function NotificationsProvider({ children }) {

    const { idUser, user, tipoConta } = React.useContext(UserContext);
    const [isNotify, changeNotify] = React.useState(false);
    const [notificationList, setNotificationList] = React.useState(null);
    const [totalNotificacoes, setTotalNotificacoes] = React.useState(0);

    function refreshNotifications() {
        api.post(`/ws/notificacoes.php?id=${idUser}&tipoConta=${tipoConta}`)
            .then(res => {
                if (res.data.success) {
                    setNotificationList(res.data.notificacoes);
                    setTotalNotificacoes(res.data.total_notificacoes);
                } else {
                    alert('Houve um erro! Tente novamente ou contate o administrador!');
                }
            })
            .catch(err => {
                alert('Houve um erro! Tente novamente ou contate o administrador!');
            });
    };

    function getHeight() {
        if (notificationList) {
            return 350;
        } else {
            return 'auto';
        };
    };

    React.useEffect(() => {
        if (user) {
            refreshNotifications();
        };

    }, [user]);

    function notifyBar() {

        return (
            <Drawer
                anchor={'top'}
                open={isNotify}
                onClose={() => { changeNotify(false) }}
                transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
                sx={{
                    zIndex: 2000,
                }}
            >
                <Box sx={{
                    height: getHeight(),
                }} >
                    <Box sx={{
                        m: '1.5em 0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <NotificationsNone color='secondary' sx={{ fontSize: 40 }} />
                        <Typography variant='h2' mt='.5em'>
                            {notificationList ? 'Suas notificações:' : 'Você não possui notificações'}
                        </Typography>
                    </Box>
                    {
                        notificationList?.map((row) => (
                            <Button
                                component={Link}
                                to={'/NotificationDetails/' + row.id}
                                onClick={() => { changeNotify(false) }}
                                variant='outlined'
                                key={row.id}
                                sx={{
                                    height: '7em',
                                    m: '1em auto',
                                    width: '95%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'start'
                                }}>
                                {console.log(row)}
                                <Typography color="primary" fontWeight={'bold'} textAlign={'start'}>
                                    {row.titulo}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '.5em' }}>
                                    <CalendarMonth sx={{ color: '#424242' }} fontSize="small" />
                                    <Typography textAlign={'start'} justifyContent="center" alignItems={"center"} ml=".5em">
                                        {row.data_hora_registro}
                                    </Typography>
                                </Box>
                            </Button>
                        ))
                    }
                </Box>
            </Drawer>
        )
    };

    return (
        <NotificationContext.Provider
            value={{
                changeNotify,
                totalNotificacoes,
                refreshNotifications
            }}>
            <OnlyToSignedUsers component={notifyBar()} />
            {children}
        </NotificationContext.Provider>
    );
}
