import { Box, Modal, Typography } from "@mui/material"
import HTMLReactParser from 'html-react-parser';

const ModalAgenda = ({open, handleClose, content})=>{

    const styles = {
        modal:{
            zIndex: 2100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        title:{
            borderBottom: '1px solid gray',
            marginBottom: '30px'
        },
        box:{
            position: 'absolute',
            width: '70vw',
            bgcolor: 'white',
            boxShadow: 24,
            borderRadius: '8px',
            p: '1rem',
            overflow: 'auto',
            div:{
                backgroundColor: '#fcfcfc',
                border: '1px solid #eaeaea',
                padding: '5px',
                fontSize: '13px',
                margin: '6px 0',
            }
        },
    }
    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={styles.modal} >
            <Box sx={styles.box}>
                <Typography sx={styles.title} variant="h1">Detalhes de aula:</Typography>
                <Typography variant="p">
                    {HTMLReactParser(`${content}`)}
                </Typography>
            </Box>
        </Modal>
    )
}

export default ModalAgenda