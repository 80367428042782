import UserProvider from "./context/UserProvider";
import Router from "./routes";
import { ThemeProvider } from "@mui/system";
import global from "./theme/global";

function App() {
  return (
    <ThemeProvider theme={global}>
      <UserProvider>
        <Router />
      </UserProvider>
    </ThemeProvider>
  )
};

export default App;