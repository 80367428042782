import { Download } from "@mui/icons-material";
import { Box, Button, CardMedia, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function BibliotecaDeAtividades() {

    const [data, setData] = useState(null);

    const { id } = useParams();

    const style = {
        boxOutside: {
            p: '2rem 1rem 4rem 1rem',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '85vh',
        },

        grid: { width: '100%', mt: '1.5em' },

        button: { border: '2px solid' },
        cardMedia: { width: '60%', mb: '1.5em' },
        imageStyle:{
            width: {
                xs:'15rem',
                sm:'22rem'
            },
            mb: '1.5em' 
        },
        skeletonStyle:{ width: '60%', height: '18rem', mb: '1.5em' }
    };

    useEffect(() => {
        api.post('/ws/biblioteca-de-atividades.php?id=' + id)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.atividades);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [id])


    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{
                src:"https://gotheextramile.com.br/img/biblioteca-de-atividades.png",
                imageStyle:style.imageStyle,
                skeletonStyle:style.skeletonStyle
            }}/>
            <Typography textAlign={'center'} variant="h2">
                Clique aqui para acessar conteúdos e exercícios para todos os níveis
            </Typography>
            <Grid container spacing={2} sx={style.grid} justifyContent="center">
                {data?.length? 
                        data && data.map((obj, index) => {
                            return (
                                <Grid item key={index}>
                                    <Button sx={style.button} fullWidth variant="outlined" color="secondary" component={Link} to={`/AtividadeDetalhes/${obj.id}`} >
                                        <Typography fontWeight="bold">
                                            {obj.nome}
                                        </Typography>
                                    </Button>
                                </Grid>
                            )
                        })
                    :
                    <CircularProgress />
                }
            </Grid>

        </Box>
    );
}