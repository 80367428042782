import { ArrowBack, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function PlanosDeCursoEspanhol() {
    const styles = {
        boxOutside: {
            p: '1em',
            pt: '2em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            },
            mb: '1.5rem'
        },
        button: { width: '75%', mt: '1em', textTransform: 'uppercase' },
        accordion: {
            width: '100%',
        }
    }
    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component={'img'} image="https://gotheextramile.com.br/img/planos-de-curso-title.png" alt="" sx={styles.cardMedia} />
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}> A1 - Elemental 1</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography fontWeight={'bold'}>Material: Pasaporte A1 (Libro + CD) <br /> Contenidos Lingüísticos</Typography>
                    <br />
                    <Typography>
                        • El abecedario  <br />
                        • Deletrear  <br />
                        • Presente de ser y llamarse   <br />
                        • Pronombres interrogativos   <br />
                        • Los números del 1 al 10   <br />
                        • Presente de tener   <br />
                        • Verbos regulares em presente –ar, -er, -ir   <br />
                        • La acentuación de las palabras   <br />
                        • Los adjetivos posesivos  <br />
                        • El verbo gustar em presente   <br />
                        • El acento em la penúltima sílaba   <br />
                        • Las palabras terminadas em vocal, n o s no se acentúan em la penúltima sílaba   <br />
                        • El género, el número y los artículos definidos   <br />
                        • El tapeo y el uso de los diminutivos   <br />
                        • El verbo parecer en presente   <br />
                        • El artículo indefinido  <br />
                        • Hay / está n, mucho y muy   <br />
                        • Los verbos irregulares ir, seguir, hacer  <br />
                        • Las preposiciones con medios de transporte   <br />
                        • Los números ordinales   <br />
                        • Los verbos de acciones cotidianas y las partes del día   <br />
                        • Los verbos irregulares con diptongo E EI, O EU y los reflexivos en presente   <br />
                        • Las preposiciones con expresiones de tempo   <br />
                        • Los pronombres personales sin y con preposiciones   <br />
                        • Ir a + infinitivo, pensar + infinitivo, querer + infinitivo   <br />
                        • Estar + gerundio, acabar de + infinitivo <br /><br />

                        <strong>Contenidos Funcionales</strong><br /><br />

                        • Dar información personal; expresar gustos  <br />
                        • Los saludos y las despedidas formales e informales   <br />
                        • La profesión   <br />
                        • Tu o Usted   <br />
                        • La familia   <br />
                        • Describir el físico   <br />
                        • Los puestos de trabajo   <br />
                        • Los tratamientos de persona   <br />
                        • Los alimentos   <br />
                        • Expresar gustos y hablar de la frecuencia   <br />
                        • Los números hasta 1000  <br />
                        • Los pesos y las medidas   <br />
                        • Las formas de comer; los platós de comida; manejarse em un restaurante   <br />
                        • La ciudad; describir un barrio; preguntar por una dirección e informar; los estabelecimientos públicos, profesionales y comerciales   <br />
                        • Situar los lugares según la distancia   <br />
                        • Hablar de la frecuencia   <br />
                        • Preguntar e informar sobre la hora   <br />
                        • Los días de la semana, los meses del año y las estaciones   <br />
                        • Quedar   <br />
                        • Los atractivos turísticos   <br />
                        • Las estructuras comparativas   <br />
                        • Hablar por teléfono
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>A1 - Elemental 1 - Nuevo Prisma</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material: Nuevo Prisma A1 (Libro + CD)</strong></Typography>
                    <br />
                    <Typography>
                        Ao término deste nível o aluno estará apto a: <br />
                        • Saudar e responder a saudações formal e informalmente <br />
                        • Despedir-se <br />
                        • Apresentar-se <br />
                        • Pedir confirmação de informação prévia <br />
                        • Perguntar como se diz algo em outra língua <br />
                        • Soletrar <br />
                        • Pedir e dar informações pessoais (nome, idade, profissão, formação acadêmica, onde mora, origem) <br />
                        • Falar da profissão e lugar de trabalho <br />
                        • Falar sobre a rotina diária <br />
                        • Expressar sensações e sentimentos <br />
                        • Falar sobre a existência de algo, alguém e de quantidade <br />
                        • Pedir e dar informação de localização <br />
                        • Falar e perguntar sobre as relações pessoais <br />
                        • Expressar posse <br />
                        • Descrever pessoas: descrição física, personalidade, vestimenta <br />
                        • Expressar necessidades, desejos e preferê<nciasbr></nciasbr>
                        • Perguntar / dizer o preço de algo <br />
                        • Agradecer / responder ao agradecimento <br />
                        • Pedir / dar instruções para usar meios de transporte <br />
                        • Descrever ações e atividades habituais e falar sobre frequência <br />
                        • Perguntar e pedir as horas <br />
                        • Expressar / perguntar sobre gostos e preferências <br />
                        • Expressar dor e mal estar <br />
                        • Falar sobre planos e intenções <br />
                        • Dar conselhos <br />
                        • Falar sobre a forma de se fazer algo <br />
                        • Dar / pedir opinião <br />
                        • Expressar concordância / discordância <br />
                        • Dar instruções <br />
                        • Formas de expressar negação <br />
                        • Falar de ações terminadas em um tempo relacionado com o presente
                        <br /><br />

                        <strong>Conteúdos gramaticais a serem abordados: </strong><br /><br />

                        • Pronomes pessoais <br />
                        • Tú / Usted <br />
                        • Verbo ser <br />
                        • Verbo llamarse <br />
                        • Pronome interrogativo ¿Cómo?<br />
                        • Artigo definido <br />
                        • Gênero e plural dos substantivos <br />
                        • Concordância do artigo definido e do adjetivo com o substantivo <br />
                        • Pronomes interrogativos ¿Cuál? ¿Qué? ¿Cuántos? <br />
                        • Presente do indicativo regular <br />
                        • Verbos reflexivos <br />
                        • Verbo tener <br />
                        • Artigo indeterminado <br />
                        • (No) hay + artigo indeterminado + substantivo <br />
                        • Verbo estar <br />
                        • Hay / está(n) <br />
                        • Mucho/a/os/as, poco/a/os/as + substantivo <br />
                        • Muy + adjetivo <br />
                        • Pronomes interrogativos: ¿Dónde? ¿Qué? ¿Cuántos/as? <br />
                        • Adjetivos possessivos <br />
                        • Adjetivos de descrição física e caráter <br />
                        • Ser, tener, llevar <br />
                        • Verbo ir <br />
                        • Verbos necessitar, querer, preferir + infinitivo / substantivo <br />
                        • Preposições a e en com verbos de movimento <br />
                        •  Irregularidades do presente do indicativo <br />
                        • Advérbios e expressões de quantidade <br />
                        • Advérbios e expressões de frequência <br />
                        • Verbos gustar, encantar <br />
                        • Verbo doler / tener dolor de <br />
                        • Pronomes de objeto indireto <br />
                        • Adjetivos e advérbios de quantidade: nada, poco, demasiado, bastante, mucho <br />
                        • También / tampoco <br />
                        • Gerúndio, formas e usos <br />
                        • Estar + gerúndio <br />
                        • Verbo quedar <br />
                        • Poder + infinitivo com valor de sugestão <br />
                        • Ir + a + infinitivo <br />
                        • Creo que / Penso que / Para mi + opinião <br />
                        • Verbo parecer <br />
                        • Yo estoy de acuerdo com / No estoy de acuerdo com + opinião <br />
                        • Imperativo afirmativo: regulares / irregulares <br />
                        • Morfologia do pretérito perfeito: regular / irregular <br />
                        • Hoy, esta manãna, esta tarde, este mês, este año <br />
                        • Ya, todavia no <br />
                        • Pretérito indefinido <br />
                        • Expressões de tempo (ayer, anoche, anteayer, el outro dia, la semana pasada, el mes pasado, el año pasado)
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>A2 - Elemental 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Material: Pasaporte A2 (Libro + CD) <br /> Contenidos Lingüísticos</strong></Typography>
                    <br />
                    <Typography>
                        • Los adjetivos de carácter y los estados de animo  <br />
                        • Uso de ser e estar con adjetivos  <br />
                        • El género de los sustantivos as características profesionales  <br />
                        • El pretérito indefinido  <br />
                        • Los marcadores temporales  <br />
                        • Las oraciones causales  <br />
                        • Hace, hace que y desde hace  <br />
                        • Las oraciones relativas y los verbos de gusto  <br />
                        • Los pronombres personales de objeto directo y indirecto  <br />
                        • El pretérito perfecto  <br />
                        • Los comparativos y superlativos  <br />
                        • Los verbos irregulares en el presente   <br />
                        • Los pronombres posesivos  <br />
                        • El imperfecto   <br />
                        • Uso del imperfecto (antes y ahora)  <br />
                        • Contraste de los pasados  <br />
                        • El imperativo regular  <br />
                        • Las perífrasis hay que + infinitivo, tener que + infinitivo y poder + infinitivo y la posición de los pronombres <br /><br />

                        <strong>Contenidos Funcionales</strong><br /><br />

                        • Hablar del carácter y de los estados de animo     <br />
                        • Expresar preferencias   <br />
                        • Hacer un cumplido  <br />
                        • Expresar la causa y la opinión  <br />
                        • Relatar el pasado  <br />
                        • Informarse de las salas de un museo  <br />
                        • Presentar un currículum  <br />
                        • Describir la ropa  <br />
                        • Comprar ropa en una tienda  <br />
                        • Reclamar  <br />
                        • Expresar la opinión   <br />
                        • Expresar acuerdo y desacuerdo  <br />
                        • Hablar de la habilidad para hacer algo  <br />
                        • Describir las circunstancias que rodean los acontecimientos en el pasado  <br />
                        • Alquilar o comprar un piso  <br />
                        • Hablar de acciones y de descripciones  <br />
                        • Expresar dolor y molestar  <br />
                        • Expresar posibilidad, permiso, necesidad y obligación
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={styles.accordion}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <Typography fontWeight={'bold'}>A2 - Elemental 2 - Nuevo Prisma</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography><strong>Ao término deste nível o aluno estará apto a:</strong></Typography>
                    <br />
                    <Typography>
                        • Saudar, responder a saudações e despedir-se  <br />
                        • Apresentar-se e apresentar alguém  <br />
                        • Pedir e dar informações a alguém sobre motivos e razões <br />
                        • Expressar opinião  <br />
                        • Fazer recomendações  <br />
                        • Perguntar e expressar preferências  <br />
                        • Pedir a dar informações pessoais  <br />
                        • Estabelecer conversa ao telefone  <br />
                        • Falar sobre ações do passado  <br />
                        • Estabelecer semelhanças e diferenças  <br />
                        • Linguagem verbal / não verbal  <br />
                        • Falar sobre passado recente  <br />
                        • Falar sobre feitos históricos  <br />
                        • Narrar momentos importantes sobre a vida de uma pessoa  <br />
                        • Pedir emprego  <br />
                        • Pedir a dar informações sobre o currículo de uma pessoa  <br />
                        • Identificar e definir  <br />
                        • Descobrir pessoas, objetos, lugares  <br />
                        • Fazer comparações <br />
                        • Expressar obrigação, permissão, e proibição  <br />
                        • Falar sobre novidades <br />
                        • Falar sobre normas sociais  <br />
                        • Evocar recordações  <br />
                        • Falar sobre hábitos / costumes do passado comparados ao tempo presente  <br />
                        • Descobrir características físicas de pessoas / animais e coisas  <br />
                        • Expressar surpresa e desilusão  <br />
                        • Falar sobre ações futuras  <br />
                        • Fazer previsões, promessas e conjecturas  <br />
                        • Falar sobre condições climáticas  <br />
                        • Fazer hipóteses e expressar probabilidade sobre o passado  <br />
                        • Pedir /dar conselhos e sugestões  <br />
                        • Dar ordens <br />
                        • Persuadir  <br />
                        • Expressar conhecimento  <br />
                        • Perguntar sobre habilidade para fazer algo <br /><br />

                        <strong>Conteúdos gramaticais a serem abordados:</strong><br /><br />

                        - Presente do Indicativo – verbos regulares / irregulares  <br />
                        - ¿Por qué / Para qué + presente do indicativo ¿  <br />
                        - Es útil / bueno / necesario + infinitivo  <br />
                        - Para + infinitivo  <br />
                        - Porque + verbo conjugado  <br />
                        - Tener que + infinitivo  <br />
                        - Poder + infinitivo  <br />
                        - Pretérito indefinido  <br />
                        - Marcadores temporais do pretérito indefinido  <br />
                        - Preposições : a, en y de  <br />
                        - Hoy, esta manãna, hace unos minutos  <br />
                        - Pronomes objeto direto / indireto  <br />
                        - Pretérito indefinido : formas irregulares em 3º pessoa  <br />
                        - Marcadores de tempo : al cabo de …, a los …, después de … desde … hasta …, de … a …  <br />
                        - Contraste pretérito indefinido / pretérito perfeito <br />
                        - Marcadores de tempo para expresar frecuencia : nunca, ya, todavía no, alguma vez  <br />
                        - Ser / estar – usos gerais  <br />
                        - Comparativos de igualdade, inferioridade, superioridade  <br />
                        - Comparativos irregulares  <br />
                        - Poder, dever + infinitivo  <br />
                        - Se puede, se debe + infinitivo  <br />
                        - Está permitido/prohibido + infinitivo  <br />
                        - Antes / mientras / siempre / todos los días / cuando  <br />
                        - Soler + infinitivo  <br />
                        - Es de / Está hecho de + material / Procede de + artigo + substantivo / Sirve para + infinitivo  <br />
                        - Fututo imperfeito  <br />
                        - Creo / imagino / supongo + que + futuro imperfeito  <br />
                        - No sé si / cuándo / dónde + futuro imperfeito  <br />
                        - Condicional simples  <br />
                        - Interjeições / expressões para mostrar surpresa e incredulidade  <br />
                        - A lo mejor + indicativo  <br />
                        - Imperativo afirmativo e negativo  <br />
                        - Presente do subjuntivo regular e alguns irregulares  <br />
                        - Ir a , volver a , acabar de, empezar a, tener que
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/PlanosDeCurso'} sx={styles.button}>
                Voltar
            </Button>
        </Box>
    )
}