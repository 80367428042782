// import './fullcalendar.css'

import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";
import { UserContext } from "../context/UserProvider";
import ModalAgenda from '../components/ModalAgenda';
import HTMLReactParser from 'html-react-parser';
import { createRef } from '@fullcalendar/core/preact';

export default function MinhaAgenda() {
    const calendarRef = createRef()

    const theme = useTheme()

    const { idUser, tipoConta } = useContext(UserContext)
    const [isLoading, setIsLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false)
    const [modalContent, setModalContent] = useState('')

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [events, setEvents] = useState([])
    useEffect(() => {
        setIsLoading(true)
        api.get(`/ws/agenda.php?id_usuario=${idUser}&tipo_usuario=${tipoConta}`)
            .then((res) => {
                setEvents(res.data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const clickCalendar = (click) => {
        console.log("click2", click.event._def)
        setOpenModal(true)
        setModalContent(click.event._def.extendedProps.description)
    }

    const style = {
        boxOutside: {
            p: '2em 1em',
            pb: '10vh',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        cardMedia: { width: '60%', mb: '1.5em' },
        boxGrid: {
            borderRadius: '4px',
            bgcolor: '#2DB200',
            padding: '3px 6px'
        },
        boxItem1: {
            borderRadius: '4px',
            bgcolor: '#D9D900',
            padding: '3px 6px'
        },
        boxItem2: { borderRadius: '4px', bgcolor: '#f58220', padding: '3px 6px' },
        boxItem3: { borderRadius: '4px', bgcolor: '#B20000', padding: '3px 6px' },
        boxItem4: { borderRadius: '4px', bgcolor: '#0080FF', padding: '3px 6px' },
        boxItem5: { borderRadius: '4px', bgcolor: '#5E35B1', padding: '3px 6px' },
        boxItem6: { borderRadius: '4px', bgcolor: '#0097A7', padding: '3px 6px' },
        boxItem7: { borderRadius: '4px', bgcolor: '#006666', padding: '3px 6px' },
        boxCalendar: {
            width: { sm: '80%', xs: '100%' },
            mt: '2rem',
            fontFamily:"sans-serif",
            '& .fc-toolbar-title':{
                fontSize:{
                    md:'20px',
                    xs:'15px',
                },
            },
            '& .fc-button-group button':{
                background:theme.palette.primary.main,
            }, 
            '& .fc-button-primary':{
                fontSize:{ md:'20px' }
            },
            '& .fc-header-toolbar':{
                display: 'flex',
                flexDirection:{
                    xs:'column',
                    md:'row'
                },
            },
            '& .fc-toolbar-chunk':{
                display:'flex',
                flexDirection:'row',
            },
            '& .fc-toolbar-chunk':{
                margin:'5px'
            },
            '& .fc-col-header-cell-cushion':{
                fontSize:'12px'
            }
        },
        imageStyle: {
            width: {
                xs: '15rem',
                md: '22rem'
            },
            mb: '1.5em'
        },
        skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' }
    }
    console.log(theme)
    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{ src: "https://gotheextramile.com.br/img/minha-agenda.png", imageStyle: style.imageStyle, skeletonStyle: style.skeletonStyle }}></CustomImageHandler>
            <Typography textAlign='center'>
                Confira abaixo seu calendário de aulas. Clique sobre uma aula para mais detalhes:
            </Typography>
            <Grid container justifyContent={'center'} spacing={1}>
                <Grid item>
                    <Box sx={style.boxGrid}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula confirmada
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem1}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula a confirmar
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem2}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada pelo professor
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem3}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada turma / aluno
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem4}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada problemas c/ internet
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem5}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada problemas c/ internet - Aluno
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem6}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada problemas c/ internet - Professor
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={style.boxItem7}>
                        <Typography fontWeight={'bold'} fontSize={'.825em'} color={"white"}>
                            Aula cancelada férias turma / aluno
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={style.boxCalendar}>
                <FullCalendar
                    ref={calendarRef}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay'
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    events={events}
                    locale="pt-BR"
                    titleFormat={{
                        year: '2-digit',
                        month: '2-digit',
                        day: '2-digit'
                    }}
                    buttonText={{
                        today: "Hoje",
                        prev: HTMLReactParser('&#x3C;'),
                        next: HTMLReactParser('&#x3E;'),
                        day: 'Dia',
                        month: 'Mês',
                        week: 'Semana'
                    }}
                    eventClick={clickCalendar} />
            </Box>
            <ModalAgenda
                open={openModal}
                content={modalContent}
                handleClose={handleCloseModal} />
        </Box>
    )
}