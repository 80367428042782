import { Box, Button, CardMedia, FormControl, Typography } from "@mui/material";
import { useState } from "react";

import partes from './../../data/testeNivelamentoPortugues'
import QuestionNivelamentoHandler from "../../components/QuestionNivelamentoHandler";
import CustomImageHandler from "../../components/CustomImageHandler";
import useTesteNivelamento from "../../hooks/useSendTesteNivelamento";

const TesteDeNivelamentoPortugues = () => {
    const { sendTest, handleAnswer, formSent } = useTesteNivelamento();
    const [answers, setAnswers] = useState([
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])

    const handleSubmit = (event) => {
        event.preventDefault()
        sendTest(partes, answers, 'Português')
    }

    const styles = {
        boxOutside: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'left',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        formControl: {
            padding: '10px',
            marginBotton: '10px',
        },
        formSent: {
            color: 'green',
        },
        skeleton: {
            width: '100%',
            height: '30vh'
        },
        cardMedia: {
            width: {
                xs: '15rem',
                sm: '22rem'
            }
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <Typography variant="h1">Português</Typography>
            <Typography> Teste de nivelamento </Typography>
            <CustomImageHandler props={{
                src: 'https://gotheextramile.com.br/img/portugues-title.png',
                skeletonStyle: styles.skeleton,
                imageStyle: styles.cardMedia
            }} />
            <Typography variant="h2">Escolha a alternativa correta:</Typography>
            <FormControl sx={styles.formControl}>
                {partes.map(({ title, questions }, index) => {
                    return (
                        <>
                            <Typography key={`${title}-${index}`}>{title}</Typography>
                            <QuestionNivelamentoHandler
                                questions={questions}
                                value={index + 1}
                                key={`${index}-${title}`}
                                answerHandler={handleAnswer(index, setAnswers)} />
                        </>
                    )
                })}
                {formSent && <Typography sx={styles.formSent}>Teste enviado com sucesso</Typography>}
                <Button
                    variant="contained"
                    size="large"
                    type='submit'
                    disabled={formSent}
                    onClick={handleSubmit}>Enviar</Button>
            </FormControl>
        </Box>
    )
}

export default TesteDeNivelamentoPortugues