import { Box, Button, CardMedia, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactHtmlParser from 'html-react-parser';
import api from "../services/api";
import { Link, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

export default function Teacher() {
    const [data, setData] = useState([]);

    const { id } = useParams();

    useEffect(() => {
        api.get('ws/ourteam.php?id=0')
            .then(res => {
                if (res.data.success) {
                    var teacher = res.data.team.filter((obj) => (obj.id === id))
                    setData(teacher[0]);
                }
            })
            .catch(err => {
                console.log(err);
            })
    });

    const styles = {
        boxOutside:{
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: '2em 1em 5em 1em ',
        },
        cardMedia:{ width: '80%', mb: '1.5em' },
        button:{
            width: '75%',
            mt: '1.5em',
            textTransform: 'uppercase'
        }
    }

    return (
        <Box sx={styles.boxOutside}>
            <CardMedia component='img' image={`https://goaheadschool.com.br/img/our_team/${data.id}_500x750.jpg`} alt="" sx={styles.cardMedia} />
            <Typography variant="h2">
                {data.nome}
            </Typography>
            <Typography mt='.5em' fontWeight='bold'>
                {data.idiomas}
            </Typography>
            <Typography mt='.5em' textAlign='center'>
                {data.cargo}
            </Typography>
            <Typography mt='1em'>
                {ReactHtmlParser(`${data.biografia}`)}
            </Typography>
            <Button startIcon={<ArrowBack />} color="secondary" variant="contained" component={Link} to={'/OurTeam'} sx={styles.button}>
                Voltar
            </Button>
        </Box>
    )

}