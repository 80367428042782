import { Button, Grid } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import CustomImageHandler from "./CustomImageHandler";

export default function HomeItemHandler(params) {

    const obj = params.params;

    const styles = {
        feedItem: {
            width: "100%",
            height: "100%",
            shadowColor: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'white',
            "&:hover": {
                bgcolor: 'white'
            }
        },
        skeleton: {
            width: '100%',
            height: '8rem'
        }
    };

    return (
        <Grid component={motion.div}
            item xs={6} sm={4} md={2} key={obj.index}
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.25 }}
        >
            <Button
                component={Link}
                to={obj.path}
                variant="contained"
                sx={styles.feedItem}
                onClick={() => <Navigate to={obj.path}
                />}>
                <CustomImageHandler props={{ src: obj.icon, imageStyle: obj.icon, skeletonStyle: styles.skeleton, }} />
            </Button>
        </Grid>
    )
}