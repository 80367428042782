import { Box, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";

export default function Layout({ children }) {

    const theme = useTheme();

    const location = useLocation();

    const globalBgColor = () => {
        return location.pathname === '/' || location.pathname === '/Home' ? theme.palette.primary.main : 'white'
    };

    const styles = {
        container: {
            bgcolor: globalBgColor(),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        content: {
            maxWidth: '1280px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.content}>
                {children}
            </Box>
            <ScrollToTop />
        </Box>
    );
};