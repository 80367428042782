import { Box, createTheme, Grid, Typography } from "@mui/material";
import CustomImageHandler from "../components/CustomImageHandler";

export default function AssessmentPlus() {

    return (
        <Grid container spacing={2} sx={styles.boxOutside}>
            <Grid item xs={12}>
                <CustomImageHandler props={{ src: 'https://goaheadschool.com.br/img/assessment-plus.png', imageStyle: styles.imageStyle, skeletonStyle: styles.skeletonStyle }} />
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Pensando em uma ferramenta para gerenciamento e potencialização do aprendizado, a Go Ahead lançou, em novembro de 2016, o programa <strong>Assessment Plus (A+)</strong> no seu portfólio de serviços e produtos.
                    <br></br>
                    <br></br>
                    O <strong>Assessment Plus +</strong> tem como objetivo direcionar o seu plano de aulas utilizando como referência as faixas de pontuação propostas por proficiency tests de reconhecimento internacional (tanto para o ambiente business/corporativo como acadêmico) aliado ao acompanhamento personalizado feito por teachers e pela coordenação pedagógica da escola. Para aprimorar ainda mais a ferramenta, em 2022, a Go Ahead lançou a <strong>Assessment Plus Testing Platform</strong>, agregando mais valor para empresas e avaliados.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box component={'iframe'} sx={styles.videoStyle}
                    src="https://www.youtube.com/embed/mi0p8iEpLwE" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen />
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    <strong><i>- Em termos práticos, como funciona o programa A+?</i></strong>
                    <br></br>
                    <br></br>
                    O programa constitui-se de 1 ou 2 aplicações anuais de assessments (conforme demanda ou interesse do aluno ou da empresa). De acordo com as pontuações de "entrada" (1o. assessment) são feitas as análises e elaborações dos diagnósticos, sempre de forma individual. Os gaps do aluno são identificados e um plano de desenvolvimento é elaborado e apresentado para o aluno/empresa. Cada teste, independente de ser de entrada ou saída, tem considerada a elaboração de relatórios detalhados contendo a pontuação, nivelamento, diagnóstico, e as recomendações necessárias de acordo com as interpretações dos scores. É utilizada como referência a escala internacional de pontuação e descrição de score proposta pelo CEFR (The Common European Framework of Reference for Languages), o que constitui-se em ferramenta precisa para as avaliações quantitativas e qualitativas de progresso.
                    <br></br>
                    <br></br>
                    <strong><i>- Como os testes são aplicados?</i></strong>
                    <br></br>
                    <br></br>
                    O Assessment é realizado através de dois acessos à plataforma:
                    <br></br>
                    <br></br>
                    <strong><i>- Um acesso independente, onde o avaliado faz os testes de Listening, Grammar&Vocabulary, e Reading (LGVR)
                        <br></br>
                        -Um acesso supervisionado, realizado junto a um test-administrator (teacher), onde o avaliado faz os integrated tests de Speaking&Writing</i></strong>
                    <br></br>
                    <br></br>
                    A plataforma também disponibiliza uma ferramenta para os agendamentos do Speaking&Writing tests.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box component={'iframe'} sx={styles.videoStyle}
                    src="https://www.youtube.com/embed/Z1TvZVsH53Q" frameborder="0" allow="autoplay; encrypted-media"
                    allowfullscreen />
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Com base nos resultados de cada test/skill, confeccionamos os relatórios/diagnósticos. Os relatórios completos são entregues em até 3 dias após a aplicação do teste. Estes relatórios também são passados aos teachers dos avaliados para que possam direcionar o conteúdo, abordagem e plano de aulas levando em consideração as recomendações feitas.
                    <br></br>
                    <br></br>
                    <strong><i>- Valor do investimento:</i></strong>
                    <br></br>
                    <br></br>
                    Oferecemos 2 planos:
                    <br></br>
                    <br></br>
                    <strong>- Simplified A+:</strong> nesta opção o avaliado recebe um Feedback Report contendo os resultados de cada área e considerações gerais acerca de sua performance
                    <br></br>
                    <br></br>
                    <strong>- Full A+:</strong> nesta opção o avaliado recebe, além do Feedback Report, um diagnóstico detalhado de todas as áreas abordadas, assim como um Action Plan (plano de ação/de desenvolvimento), com todas as recomendações e ações necessárias para a evolução na rota de aprendizagem e troca de níveis.
                    <br></br>
                    <br></br>
                    Informe-se diretamente com a escola sobre valores e opções de pagamentos.
                    <br></br>
                    <br></br>
                    Ficaremos à disposição para tirarmos dúvidas e fazermos os agendamentos.
                </Typography>
            </Grid>
            {/*   <Grid item xs={12}>
                Form Assessment
            </Grid> */}
        </Grid>
    )
}

const styles = createTheme({
    boxOutside: {
        minHeight: '85vh',
        p: '2em 1em 5em 1em ',
    },
    imageStyle: {
        width: {
            xs: '80%',
            sm: '20rem'
        },
        mb: '1.5em'
    },
    videoStyle: {
        width: {
            xs: '100%',
            sm: '70%'
        },
        height: {
            xs: '15rem',
            sm: '18rem'
        },
        mb: '1.5em'
    },
    skeletonStyle: { width: '60%', height: '18rem', mb: '1.5em' }
})