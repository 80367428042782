import { Notifications, NotificationsActive } from "@mui/icons-material";
import { Badge, Card, IconButton } from "@mui/material";
import { useContext } from "react";
import { NotificationContext } from "../context/NotificationsProvider";
import OnlyToSignedUsers from "./OnlyToSignedUsers";

export default function NotificationBar() {

    const { changeNotify, totalNotificacoes } = useContext(NotificationContext);

    const isRinging = totalNotificacoes > 0;

    const styles = {
        notificationCard: {
            position: 'fixed',
            bottom: 80,
            right: 5,
            zIndex: 1950
        }
    };

    return (
        <OnlyToSignedUsers component={<Card sx={styles.notificationCard}>
            <IconButton
                size="large"
                key={'Notification'}
                onClick={() => changeNotify(true)}>
                <Badge color="secondary" badgeContent={totalNotificacoes}>
                    {
                        isRinging ?
                            <NotificationsActive fontSize='2em' color='error' />
                            :
                            <Notifications fontSize='2em' color='primary' />
                    }
                </Badge>
            </IconButton>
        </Card>} />
    )
};