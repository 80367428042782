import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"

import ReactHtmlParser from 'html-react-parser';

/**
 * @param {array} questions
 * @param {string} questions[].question 
 * @param {array} question[].options 
 * @param {string} questions[].options[].id 
 * @param {string} questions[].options[].label 
 * @param {string} questions[].options[].value 
 * @param {function} answerHandler
 * @author Gui 
 */

const QuestionNivelamentoHandler = ({ questions, answerHandler }) => {
    const styles = {
        boxQuestion: {
            fontFamily: 'sans-serif'
        },
        typographyQuestion: {
            margin: '2px',
            padding: '2px',
        },

        radioGroup: {
            margin: '4px',
        },
        formControlLabel: {
            fontFamily: 'sans-serif',
        },
    };

    return (
        <>
            {questions.map(({ question, options }, index) => (
                <Box key={`${index}-question`} sx={styles.boxQuestion} m={'2rem 0'}>
                    <Typography
                        variant="p"
                        sx={styles.typographyQuestion} >{ReactHtmlParser(`${question}`)}</Typography>
                    <RadioGroup
                        fontSize='10px'
                        sx={styles.radioGroup}
                        onChange={(evt) => answerHandler(evt.target.value, index)}>
                        {options.map(({ id, value, label }, index) => (
                            <FormControlLabel
                                // sx={styles.formControlLabel}
                                style={{
                                    fontFamily: 'sans-serif'
                                }}
                                key={`${id}_${index}-option`}
                                value={value}
                                control={<Radio />}
                                label={label} />)
                        )}
                    </RadioGroup>
                </Box>
            ))}
        </>
    )
}

export default QuestionNivelamentoHandler