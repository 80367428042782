import { Download } from "@mui/icons-material";
import { Box, CardMedia, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserProvider";
import api from "../services/api";
import CustomImageHandler from "../components/CustomImageHandler";

export default function Financeiro() {

    const [data, setData] = useState([{}]);
    const [message, setMessage] = useState(null);

    const { idUser } = useContext(UserContext);

    useEffect(() => {
        api.get('ws/financeiro.php?id=' + idUser)
            .then(res => {
                if (res.data.success) {
                    setData(res.data.financeiro);
                } else {
                    setMessage('Houve um erro na conexão, tente novamente mais tarde!');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Houve um erro na conexão, tente novamente mais tarde!');
            })
    }, []);

    const style = {
        boxOutside:{
            p: '2em 1em',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '83vh',
        },
        table:{ mt: '1.5em' },
        typography: { mt: '1.5em' },
        imageStyle:{ width: {xs:'15rem', sm:'22rem'}, mb: '1.5em'  },
        skeletonStyle:{ width: '60%', height: '10rem', mb: '1.5em' }
    }

    return (
        <Box sx={style.boxOutside}>
            <CustomImageHandler props={{ src: "https://gotheextramile.com.br/img/financeiro.png", imageStyle: style.imageStyle , skeletonStyle: style.skeletonStyle }} />
            <Box>
                <Typography>
                    Acompanhe as informações financeiras de suas aulas:
                </Typography>
                <br />
                <Typography>
                    Segue dados das contas para depósitos/transferências, ambas de CNPJ 02.817.524/0001-03, Go Ahead Idiomas Eireli ME:
                </Typography>
                <br />
                <Typography>
                    <strong>BB PIX</strong>
                    <br />
                    Celular
                    <br />
                    51993661058
                </Typography>
                <br />
                <Typography>
                    <strong>BB</strong>
                    <br />
                    ag 0479-0
                    <br />
                    cc 34317-X
                </Typography>
                <br />
                <Typography>
                    <strong>Itaú</strong>
                    <br />
                    ag 6319
                    <br />
                    cc 07646-6
                </Typography>
            </Box>

            {data == 'vazio' ?

                <Typography sx={style.typography} variant="h2" >
                    Você não possui registros financeiros.
                </Typography>
                :
                <TableContainer component={Paper} sx={style.table}>
                    <Table>
                        <TableHead>
                            <TableCell component="th" scope="row" align="center">
                                <strong>
                                    MÊS
                                </strong>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <strong>
                                    Nº de Horas/ Aula
                                </strong>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <strong>
                                    VALOR
                                </strong>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <strong>
                                    STATUS
                                </strong>
                            </TableCell>
                            <TableCell component="th" scope="row" align="center">
                                <strong>
                                    AÇÃO
                                </strong>
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">
                                        {row.data}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.total_horas}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.valor}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.estado}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton target="_blank" href={row.download} >
                                            <Download />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}