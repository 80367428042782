const questions1 = [
  {
    question: "1. Je __________ Antoine.",
    options: [
      { id: "debutant1_1", value: "s'appelle", label: "s'appelle" },
      { id: "debutant1_2", value: "vous vous appelez", label: "vous vous appelez" },
      { id: "debutant1_3", value: "m'appelle", label: "m'appelle" }
    ]
  },
  {
    question: "2. Eros  __________ italien.",
    options: [
      { id: "debutant2_1", value: "est", label: "est" },
      { id: "debutant2_2", value: "ont", label: "ont" },
      { id: "debutant2_3", value: "a", label: "a" }
    ]
  },
  {
    question: "3. Nous  __________ 20 ans.",
    options: [
      { id: "debutant3_1", value: "va", label: "va" },
      { id: "debutant3_2", value: "allez", label: "allez" },
      { id: "debutant3_3", value: "avons", label: "avons" }
    ]
  },
  {
    question: "4. __________ crayon est sur __________ table.",
    options: [
      { id: "debutant4_1", value: "La – La", label: "La – La" },
      { id: "debutant4_2", value: "La- Le", label: "La- Le" },
      { id: "debutant4_3", value: "Le- La", label: "Le- La" }
    ]
  },
  {
    question: "5. Il __________ du soleil.",
    options: [
      { id: "debutant5_1", value: "fait", label: "fait" },
      { id: "debutant5_2", value: "est", label: "est" },
      { id: "debutant5_3", value: "y a", label: "y a" }
    ]
  },
  {
    question: "6. __________  femme allemande marche devant moi.",
    options: [
      { id: "debutant6_1", value: "Une", label: "Une" },
      { id: "debutant6_2", value: "Des", label: "Des" },
      { id: "debutant6_3", value: "Un", label: "Un" }
    ]
  },
  {
    question: "7. La négation de la phrase \"Vous aimez le chocolat\" est:",
    options: [
      { id: "debutant7_1", value: "Vous ne aimez le chocolat.", label: "Vous ne aimez le chocolat." },
      { id: "debutant7_2", value: "Vous ne aimez pas le chocolat.", label: "Vous ne aimez pas le chocolat." },
      { id: "debutant7_3", value: "Vous n'aimez pas le chocolat.", label: "Vous n'aimez pas le chocolat." }
    ]
  },
  {
    question: "8. __________ unique culotte avait un large trou. __________ étoiles au ciel avaient un doux frou-frou.",
    options: [
      { id: "debutant8_1", value: "Ma – Mes", label: "Ma – Mes" },
      { id: "debutant8_2", value: "Ma- Mon", label: "Ma- Mon" },
      { id: "debutant8_3", value: "Mon – Mes", label: "Mon – Mes" }
    ]
  },
  {
    question: "9. __________, Pierre est allé au cinéma.",
    options: [
      { id: "debutant9_1", value: "Après-demain", label: "Après-demain" },
      { id: "debutant9_2", value: "Hier", label: "Hier" },
      { id: "debutant9_3", value: "La semaine prochaine", label: "La semaine prochaine" }
    ]
  },
  {
    question: "10. __________ de fleurs rouges.",
    options: [
      { id: "debutant10_1", value: "Ce sont", label: "Ce sont" },
      { id: "debutant10_2", value: "C’est", label: "C’est" },
      { id: "debutant10_3", value: "Cette", label: "Cette" }
    ]
  },
  {
    question: "11. Elles __________ des croissants pour le petit déjeuner.",
    options: [
      { id: "debutant11_1", value: "achète", label: "achète" },
      { id: "debutant11_2", value: "achetez", label: "achetez" },
      { id: "debutant11_3", value: "achètent", label: "achètent" }
    ]
  },
  {
    question: "12. J'habite __________ Sénégal.",
    options: [
      { id: "debutant12_1", value: "au", label: "au" },
      { id: "debutant12_2", value: "en", label: "en" },
      { id: "debutant12_3", value: "à la", label: "à la" }
    ]
  }
];

const questions2 = [
    {
      question: "1. A l’université, nous étudions __________.",
      options: [
        { id: "elementaire1_1", value: "la histoire", label: "la histoire" },
        { id: "elementaire1_2", value: "le histoire", label: "le histoire" },
        { id: "elementaire1_3", value: "l’histoire", label: "l’histoire" }
      ]
    },
    {
      question: "2. J’aime __________ légumes.",
      options: [
        { id: "elementaire2_1", value: "des", label: "des" },
        { id: "elementaire2_2", value: "les", label: "les" },
        { id: "elementaire2_3", value: "la", label: "la" }
      ]
    },
    {
      question: "3. __________, on s’est connus au lycée.",
      options: [
        { id: "elementaire3_1", value: "vous", label: "vous" },
        { id: "elementaire3_2", value: "nous", label: "nous" },
        { id: "elementaire3_3", value: "ils", label: "ils" }
      ]
    },
    {
      question: "4. On __________ sortir?",
      options: [
        { id: "elementaire4_1", value: "peut", label: "peut" },
        { id: "elementaire4_2", value: "peux", label: "peux" },
        { id: "elementaire4_3", value: "pouvons", label: "pouvons" }
      ]
    },
    {
      question: "5. Quand ma mère était petite, la télévision __________ pas.",
      options: [
        { id: "elementaire5_1", value: "n’existait", label: "n’existait" },
        { id: "elementaire5_2", value: "n’existe", label: "n’existe" },
        { id: "elementaire5_3", value: "n’existais", label: "n’existais" }
      ]
    },
    {
      question: "6. Elles __________ ski.",
      options: [
        { id: "elementaire6_1", value: "font du", label: "font du" },
        { id: "elementaire6_2", value: "font de la", label: "font de la" },
        { id: "elementaire6_3", value: "font des", label: "font des" }
      ]
    },
    {
      question: "7. J’ai vu um film __________ est très romantique.",
      options: [
        { id: "elementaire7_1", value: "que", label: "que" },
        { id: "elementaire7_2", value: "qu’", label: "qu’" },
        { id: "elementaire7_3", value: "qui", label: "qui" }
      ]
    },
    {
      question: "8. Alice et Sophie __________ au bord de la mer.",
      options: [
        { id: "elementaire8_1", value: "sont allé", label: "sont allé" },
        { id: "elementaire8_2", value: "sont allées", label: "sont allées" },
        { id: "elementaire8_3", value: "est allée", label: "est allée" }
      ]
    },
    {
      question: "9. Je vais __________ dentiste.",
      options: [
        { id: "elementaire9_1", value: "à la", label: "à la" },
        { id: "elementaire9_2", value: "en", label: "en" },
        { id: "elementaire9_3", value: "chez", label: "chez" }
      ]
    },
    {
      question: "10. Je bois __________ ",
      options: [
        { id: "elementaire10_1", value: "d’eau", label: "d’eau" },
        { id: "elementaire10_2", value: "de l’eau", label: "de l’eau" },
        { id: "elementaire10_3", value: "du l’eau", label: "du l’eau" }
      ]
    },
    {
      question: "11. Tu __________ les courses au supermarché.",
      options: [
        { id: "elementaire11_1", value: "va faire", label: "va faire" },
        { id: "elementaire11_2", value: "vas faire", label: "vas faire" },
        { id: "elementaire11_3", value: "vais faire", label: "vais faire" }
      ]
    }
  ]


const questions3 = [
  {
    question: "1. Il a appris à parler français __________ six mois.",
    options: [
      { id: "intermediaire1_1", value: "dans", label: "dans" },
      { id: "intermediaire1_2", value: "en", label: "en" },
      { id: "intermediaire1_3", value: "à", label: "à" }
    ]
  },
  {
    question: "2. __________ ce soir avec mes amis.",
    options: [
      { id: "intermediaire2_1", value: "Je rendez-vous", label: "Je rendez-vous" },
      { id: "intermediaire2_2", value: "J’ai rendez-vous", label: "J’ai rendez-vous" },
      { id: "intermediaire2_3", value: "Jái en rendez-vous", label: "Jái en rendez-vous" }
    ]
  },
  {
    question: "3. Il __________ beau __________ juillet.",
    options: [
      { id: "intermediaire3_1", value: "fait – au", label: "fait – au" },
      { id: "intermediaire3_2", value: "fait – à la", label: "fait – à la" },
      { id: "intermediaire3_3", value: "fait – en", label: "fait – en" }
    ]
  },
  {
    question: "4. Ce film est ennyant, je vais __________.",
    options: [
      { id: "intermediaire4_1", value: "endormir", label: "endormir" },
      { id: "intermediaire4_2", value: "endors", label: "endors" },
      { id: "intermediaire4_3", value: "m’endormir", label: "m’endormir" }
    ]
  },
  {
    question: "5. Vous voulez couper les légumes? Oui, je __________ coupe.",
    options: [
      { id: "intermediaire5_1", value: "lui", label: "lui" },
      { id: "intermediaire5_2", value: "les", label: "les" },
      { id: "intermediaire5_3", value: "le", label: "le" }
    ]
  },
  {
    question: "6. Tu offres une cravate à ton père? Oui, je __________ offre un cravate.",
    options: [
      { id: "intermediaire6_1", value: "lui", label: "lui" },
      { id: "intermediaire6_2", value: "leur", label: "leur" },
      { id: "intermediaire6_3", value: "le", label: "le" }
    ]
  },
  {
    question: "7. Vous allez à Limoges cet été? Oui je/j__________ vais.",
    options: [
      { id: "intermediaire7_1", value: "en", label: "en" },
      { id: "intermediaire7_2", value: "y", label: "y" },
      { id: "intermediaire7_3", value: "lui", label: "lui" }
    ]
  },
  {
    question: "8. Si tu __________ mieux, tu réussirais tes examens.",
    options: [
      { id: "intermediaire8_1", value: "travaillais", label: "travaillais" },
      { id: "intermediaire8_2", value: "travailles", label: "travailles" },
      { id: "intermediaire8_3", value: "travaillé", label: "travaillé" }
    ]
  },
  {
    question: "9. Si vous avez faim, __________.",
    options: [
      { id: "intermediaire9_1", value: "serviez-vous !", label: "serviez-vous !" },
      { id: "intermediaire9_2", value: "servez-vous !", label: "servez-vous !" },
      { id: "intermediaire9_3", value: "servi-vous !", label: "servi-vous !" }
    ]
  },
  {
    question: "10. Si je gagnais au loto, je __________ le tour du monde.",
    options: [
      { id: "intermediaire10_1", value: "ferai", label: "ferai" },
      { id: "intermediaire10_2", value: "ferais", label: "ferais" },
      { id: "intermediaire10_3", value: "fais", label: "fais" }
    ]
  },
  {
    question: "11. Si vous étiez venus plus tôt, vous __________ vu Marie.",
    options: [
      { id: "intermediaire11_1", value: "auriez", label: "auriez" },
      { id: "intermediaire11_2", value: "aviez", label: "aviez" },
      { id: "intermediaire11_3", value: "aurez", label: "aurez" }
    ]
  }
];


const questions4 = [
  {
    question: "1. __________ la montre de Sophie.",
    options: [
      { id: "avance1_1", value: "Cette", label: "Cette" },
      { id: "avance1_2", value: "C’est", label: "C’est" },
      { id: "avance1_3", value: "Elle est", label: "Elle est" }
    ]
  },
  {
    question: "2. __________ sur internet, on peut découvrir dês choses étonnantes.",
    options: [
      { id: "avance2_1", value: "En travaillant", label: "En travaillant" },
      { id: "avance2_2", value: "En lisant", label: "En lisant" },
      { id: "avance2_3", value: "En surfant", label: "En surfant" }
    ]
  },
  {
    question: "3. J’aimerais que tu viennes avec __________ à la campagne.",
    options: [
      { id: "avance3_1", value: "je", label: "je" },
      { id: "avance3_2", value: "moi", label: "moi" },
      { id: "avance3_3", value: "j’ai", label: "j’ai" }
    ]
  },
  {
    question: "4. Bien que nous __________ beaucoup d’argent, nous devrions acheter un ordinateur pour nos études.",
    options: [
      { id: "avance4_1", value: "n’ayons pas", label: "n’ayons pas" },
      { id: "avance4_2", value: "avons", label: "avons" },
      { id: "avance4_3", value: "eussions", label: "eussions" }
    ]
  },
  {
    question: "5. Elle __________ encore arrivée quand sa mère a téléphoné.",
    options: [
      { id: "avance5_1", value: "etait pas", label: "etait pas" },
      { id: "avance5_2", value: "avais pas", label: "avais pas" },
      { id: "avance5_3", value: "n’etais pas", label: "n’etais pas" }
    ]
  },
  {
    question: "6. Faisons notre devoir, __________ il puisse arriver.",
    options: [
      { id: "avance6_1", value: "quoique", label: "quoique" },
      { id: "avance6_2", value: "quoi qu’", label: "quoi qu’" },
      { id: "avance6_3", value: "quoiqu’", label: "quoiqu’" }
    ]
  },
  {
    question: "7. Sylvie aime l’architecture classique alors que __________.",
    options: [
      { id: "avance7_1", value: "son mari fait les cartons.", label: "son mari fait les cartons." },
      { id: "avance7_2", value: "d’autres sont administratifs.", label: "d’autres sont administratifs." },
      { id: "avance7_3", value: "moi je préfère les immeubles contemporains.", label: "moi je préfère les immeubles contemporains." }
    ]
  },
  {
    question: "8. Même __________, j’irai faire mon jogging.",
    options: [
      { id: "avance8_1", value: "s’il pleuve", label: "s’il pleuve" },
      { id: "avance8_2", value: "s’il pleut", label: "s’il pleut" },
      { id: "avance8_3", value: "s’il plut", label: "s’il plut" }
    ]
  },
  {
    question: "9. Ils son partis sans que personne ne les __________.",
    options: [
      { id: "avance9_1", value: "voit", label: "voit" },
      { id: "avance9_2", value: "voie", label: "voie" },
      { id: "avance9_3", value: "vu", label: "vu" }
    ]
  },
  {
    question: "10. __________ soit la situation, Pierre exagère toujours.",
    options: [
      { id: "avance10_1", value: "Qui que", label: "Qui que" },
      { id: "avance10_2", value: "Encore que", label: "Encore que" },
      { id: "avance10_3", value: "Quelle que", label: "Quelle que" }
    ]
  }
];

const partes = [
    {
        title:'DÉBUTANT',
        questions:questions1
    },
    {
        title:'ÉLÉMENTAIRE',
        questions: questions2 
    },
    {
        title:'INTERMÉDIAIRE',
        questions:questions3
    },
    {
        title:'AVANCÉ',
        questions:questions4
    }
]


export default partes